import React from "react";
import IconMoneyOnHold from "../../../common/icons/money-on-hold.svg";
import IconSettled from "../../../common/icons/money-settled.svg";
import IconCencelled from "../../../common/icons/purchase-cencelled.svg";
import IconCashout from "../../../common/icons/money-cashout.svg";
import tw from "twin.macro";

type walletHistoryCardResponse = {
  date: string;
  amount: string;
  title: string;
  subtitle: string;
  status: string;
};

export function WalletHistoryCard(props: walletHistoryCardResponse) {
  const { date, amount, title, subtitle, status } = props;

  let colorAmount;
  let colorCircles;
  if (status === "Settled") {
    colorAmount = "#FF00CE";
    colorCircles = "#FFF3FD";
  } else if (status === "On Hold") {
    colorAmount = "#F39200";
    colorCircles = "#FFFCF1";
  } else if (status === "Cancled") {
    colorAmount = "#F14100";
    colorCircles = "#FFF2ED";
  } else if (status === "Successful") {
    colorAmount = "#000";
    colorCircles = "#E7FFF2";
  } else if (status === "Unsuccessful") {
    colorAmount = "#999";
    colorCircles = "#FFFFFF";
  }

  return (
    <div tw="w-full bg-white ">
      <div tw="px-6 py-4 gap-2 flex flex-col">
        <div tw="text-xs font-normal  text-[#999]">{date}</div>
        <div tw="flex flex-row gap-2">
          <div
            tw="rounded-full w-9 h-9 items-center flex justify-center align-middle"
            style={{ color: colorAmount }}>
            {status === "Settled" ? (
              <IconSettled />
            ) : status == "Unsuccessful" ? (
              <IconCashout />
            ) : status == "Successful" ? (
              <IconCashout />
            ) : status == "On Hold" ? (
              <IconMoneyOnHold />
            ) : status === "Settled" ? (
              <IconSettled />
            ) : status === "Cancled" ? (
              <IconCencelled />
            ) : (
              ""
            )}
          </div>
          <div tw="flex flex-col flex-grow ">
            <div tw="font-normal text-xs flex flex-row flex-nowrap justify-between">
              <div tw="shrink">{title}</div>
              <div
                style={{ color: colorAmount }}
                tw="text-sm font-bold grow whitespace-nowrap text-end ">
                {amount}
              </div>
            </div>
            <div tw="flex flex-row justify-between">
              <div tw="text-[#999] font-normal text-xs">{subtitle}</div>
              <div
                tw="text-sm font-normal text-[#999] text-end text-[0.625rem]"
                className="text-[0.625rem]">
                {status}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
