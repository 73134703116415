import React, { useEffect, useState } from "react";
import Page from "../../../common/components/page/page";
import AddVariantField from "./components/add-variant-field";
import Header from "../../../common/components/header/header";
import { useNavigate, useParams } from "react-router-dom";
import FloatingFooter from "../../../common/components/floating-footer/floating-footer";
import Button from "../../../common/components/button/button";
import { getLocalStorageObject, setLocalStorageObject } from "../../../framework/utils";
import { EditProductLocalStorageAddress } from "../constants";

function AddVariantPage() {
    const navigate = useNavigate()
    const { id } = useParams()
    const productFormData = getLocalStorageObject(EditProductLocalStorageAddress, id)

    // This should be dynamic
    const variantTitles = ["Color", "Size", 
        // "Type"
    ]

    const [product, setProduct] = useState({
        variants: {},
        variantSummary: "",
        ...productFormData
    })

    const [variants, setVariants] = useState(product.variants)

    useEffect(() => {
        let variantsTemplate = {}
        for (const title of variantTitles) {
            variantsTemplate[title] = []
        }
        setVariants({ ...variantsTemplate, ...variants })
    }, [])

    useEffect(() => {
        //console.log("new variants", variants)
    }, [variants])

    function handleSave() {
        let variantsCombined: any[] = []
        for (const [key, value] of Object.entries(variants)) {
            //@ts-ignore
            if (value.length > 0) {
                variantsCombined = variantsCombined.concat(value)
            }
        }
        //console.log("variantsCombined", variantsCombined)
        let newProduct = { ...product, variants: variants, variantSummary: variantsCombined.join(", ").replace(/_/g, " ") }
        setProduct(newProduct)
        //console.log(newProduct)
        setLocalStorageObject(EditProductLocalStorageAddress, newProduct, id)
        navigate(-1)
    }

    return (
        <Page tw="pt-[3.75rem] pb-20 bg-[#F2F2F2]">
            <Header title="Variant" onGoBack={() => navigate(-1)} />

            {Object.entries(variants).map(([key, value]) => (
                <>
                    <AddVariantField
                        key={key}
                        title={key}
                        //@ts-ignore
                        values={value}
                        onChange={(val) => {
                            let newVariants = { ...variants }
                            //console.log("before", newVariants)
                            newVariants[key] = val
                            //console.log("after", newVariants)
                            setVariants(newVariants)
                        }}
                    />
                    <div tw="h-3"></div>
                </>
            ))}

            <FloatingFooter>
                <Button onClick={handleSave}>Save</Button>
            </FloatingFooter>
        </Page>
    )
}

export default AddVariantPage