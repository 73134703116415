import React, { useEffect } from "react"
import Page from "../../../common/components/page/page"
import IconMediumCelebration from "../../../common/icons/medium_celebration.svg"
import { getRuntimeConfig } from "../../../framework/config"
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../../framework/utils"
import { RegisterLocalStorageAddress } from "../../product/constants"

function RegisterSuccessPage() {

    useEffect(()=>{
        let registerData = getLocalStorageObject(RegisterLocalStorageAddress)
        setLocalStorageObject(RegisterLocalStorageAddress, {email: registerData.email})
    },[])

    return (
        <Page tw="px-6 py-16 pb-20">
            <div tw="px-2 flex flex-col gap-3 text-center text-sm items-center content-center">
                <IconMediumCelebration />
                <p>Pendaftaran berhasil dikirimkan. Mohon menunggu 2x24 jam untuk pengecekan dan proses aktivasi. Konfirmasi aktivasi akun akan dikirimkan ke nomor whatsapp terdaftar.</p>
                <div tw="h-7"></div>
                <p tw="text-xs">Jika ada pertanyaan atau butuh bantuan, hubungi: {getRuntimeConfig().CS_PHONE_NUMBER_FORMATTED} (whatsapp)</p>
            </div>
        </Page>
    )
}

export default RegisterSuccessPage