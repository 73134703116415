export function isServer() {
  return typeof window === 'undefined';
}

export function ValidateEmailTelp(val: string) {
  let re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(val) || (!isNaN(+val) && val.length > 9)) {
    return true;
  } else {
    return false;
  }
}

export function getLocalStorageObject(name: string, id?: string) {
  try {
    if (id) {
      name = `${name}_${id}`
    }
    return JSON.parse(localStorage.getItem(name) || "")
  }
  catch {
    return {}
  }
}

export function setLocalStorageObject(name: string, object: object, id?: string) {
  if (id) {
    name = `${name}_${id}`
  }
  localStorage.setItem(name, JSON.stringify(object))
}

export function removeLocalStorageObject(name: string, id?: string) {
  if (id) {
    name = `${name}_${id}`
  }
  localStorage.removeItem(name)
}

export function validatePassword(password: string) {
  if (password.length < 8) {
    return 'At least 8 characters'
  }

  return "OK"
}

export function validatePhoneNumber(phoneNumber: string) {
  return /^\+?[0-9]\d{7,20}$/.test(phoneNumber);
}