import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Page from "../../../common/components/page/page"
import LoadingWidget from "../../../common/components/LoadingWidget"

function LogoutPage() {

    const navigate = useNavigate()

    useEffect(() => {
        localStorage.removeItem("fpAuth")
        navigate("/login")
    }, [])

    return (
        <Page tw="flex items-center">
            <LoadingWidget />
        </Page>
    )
}

export default LogoutPage