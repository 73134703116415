import {Variant} from '@testing-library/react';
import axios from '../../common/axios';
import {CommissionType} from '../../common/constants';
import {Product} from '../../common/entities/product';
import {CommonNetworkResponse} from '../../common/types';
import {delay} from '../../common/utils';
import {Stock} from '../../common/entities/stock';

export type FetchProductDetailsOptions = {
  productId?: string;
  fpAuth?: string;
};

export type FetchProductDetailsResponse = {
  imageUrls: string[];
  name: string;
  brand: {
    id: string;
    name: string;
  };
  price: number;
  discount?: number;
  discountedPrice?: number;
  singleCommission: number;
  weight: number;
  category: string;
  productId: string;
  selectedCategoryId: string;
  selectedSubCategoryIds: string[];
  selectedGroupId?: string;
  bulkCommission: number;
  description: string;
  measurements: {
    label: string;
    items: string[];
  }[];
  isHalal?: boolean;
  is21Plus?: boolean;
  fabricMaterial?: {
    name: string;
    details: {
      label: string;
      items: {
        label: string;
        isSelected: boolean;
      }[];
    }[];
  };
  tags: {
    isOptional: boolean;
    maxSelection: number;
    label: string;
    items: {
      id: string;
      label: string;
      isSelected: boolean;
    }[];
  }[];
};

export async function fetchProductDetails(
  options?: FetchProductDetailsOptions,
): Promise<FetchProductDetailsResponse | undefined> {
  const {productId, fpAuth} = options || {};

  // const {data} = await axios.post<CommonNetworkResponse<FetchProductDetailsResponse>>('merchant/Product/Detail', {
  //   productId,
  // });

  // return data.data;

  return delay(
    {
      productId: '23123',
      weight: 10000,
      discount: 10,
      discountedPrice: 9000,
      is21Plus: true,
      isHalal: false,
      selectedGroupId: undefined,
      brand: {id: '1', name: 'MLB'},
      selectedCategoryId: '',
      selectedSubCategoryIds: [],
      description: 'Ini Baju',
      imageUrls: ['https://i1.sndcdn.com/avatars-wtBAX6qgXtY0yPad-R1QPuA-t500x500.jpg'],
      measurements: [
        {
          label: 'Size',
          items: ['small', 'medium', 'large'],
        },
        {
          label: 'Size2',
          items: ['small', 'medium', 'large'],
        },
        {
          label: 'Siz213e',
          items: ['small', 'medium', 'large'],
        },
      ],
      name: 'Product #1',
      price: 123123,
      singleCommission: 10,
      bulkCommission: 99,
      fabricMaterial: {
        name: 'Cotton',
        details: [
          {
            label: 'Material 1',
            items: [
              {
                isSelected: true,
                label: 'small',
              },
              {
                isSelected: true,
                label: 'medium',
              },
            ],
          },
        ],
      },
      tags: [
        {
          isOptional: false,
          maxSelection: 2,
          label: 'Gender',
          items: [
            {
              id: '1',
              label: 'Male',
              isSelected: false,
            },
            {
              id: '2',
              label: 'Female',
              isSelected: true,
            },
          ],
        },
        {
          isOptional: true,
          maxSelection: 1,
          label: '1+1',
          items: [
            {
              id: '3',
              label: '2',
              isSelected: false,
            },

            {
              id: '5',
              label: '22',
              isSelected: true,
            },
            {
              id: '4',
              label: '3',
              isSelected: false,
            },
          ],
        },
      ],
      category: 'AAA / BBB / CCC',
    } as FetchProductDetailsResponse,
    2000,
  );
}

export type FetchProductAttributesOptions = {
  selectedCategoryId?: string;
  selectedSubcategoryIds?: string[];
  selectedGroupId?: string;
};

export type FetchProductAttributesResponse = {
  tags: Array<{
    isOptional: boolean;
    maxSelection: number;
    label: string;
    items?: Array<{
      id: string;
      label: string;
    }>;
  }>;
  fabricMaterial?: {
    name: string;
    details: Array<{
      label: string;
      values: string[];
    }>;
  };
  isHalal?: boolean;
  is21Plus?: boolean;
};

let xx = 1;

export async function fetchProductAttributes(
  options: FetchProductAttributesOptions = {
    selectedCategoryId: '',
    selectedGroupId: '',
    selectedSubcategoryIds: [],
  },
): Promise<FetchProductAttributesResponse | undefined> {
  return {
    tags: [
      {
        isOptional: true,
        maxSelection: 5,
        label: 'Test tags 1',
        items: [
          {
            id: '21',
            label: 'x' + xx++,
          },
        ],
      },
    ],
    fabricMaterial: {
      name: 'asdasdsad',
      details: [
        {
          label: 'fabric1',
          values: ['option 1', 'option 2'],
        },
      ],
    },
    isHalal: true,
  };
  // if (Object.values(options).filter(x => x || x.length === 0).length > 0) {
  //   return;
  // }

  // const {data} = await axios.post<CommonNetworkResponse<FetchProductAttributesResponse>>(
  //   '/merchant/Product/Detail/Attributes',
  //   {
  //     ...options,
  //   },
  // );

  // return data.data;
}

export type FetchCategoryOptions = {
  categoryId?: string;
  groupId?: string;
};

export type FetchCategoryResponse = {
  groupItems: {label?: string; id: string}[];
  categoryItems: {label?: string; id: string}[];
  subCategoryItems: {label?: string; id: string}[];
};

export async function fetchCategory(options: FetchCategoryOptions): Promise<FetchCategoryResponse | undefined> {
  const {data} = await axios.post<CommonNetworkResponse<FetchCategoryResponse>>('/merchant/Product/Category/List', {
    ...options,
  });

  return {
    categoryItems: data.data?.categoryItems || [],
    subCategoryItems: data.data?.subCategoryItems || [],
    groupItems: data.data?.groupItems || [],
  };
}

export type CreateProductResponse = {
  productId: string;
};

export type CreateProductOptions = {type: 'DRAFT' | 'SUBMIT'};

export async function createProduct(product: Product, options: CreateProductOptions) {
  console.log({product});
  const _product = {
    type: options.type,
    imageUrls: product.imageUrls,
    name: product.name,
    brandId: product.brand?.id,
    price: product.price,
    discount: product.discount,
    commision: {
      type: product.commisionType,
      value: product.commisionType === 'BULK' ? product.bulkCommissionValue : product.singleCommissionValue,
    },
    weight: product.weight,
    categoryIds: product.categories?.map(category => category.id),
    subCategoryIds: product.subCategories?.map(subCategory => subCategory.id),
    groupId: product.groups?.[0].id,
    measurements: product.measurements,
    fabricMaterial: product.fabric && {
      name: product.fabric.name,
      selectedDetails: product.fabric.details?.map(detail => ({
        label: detail.label,
        selectedValue: detail.items?.find(item => item.isSelected)?.label,
      })),
    },
    // selectedTags: product.tags?.map(tag => ({
    //   label: tag.label,
    //   selectedIds: tag.items?.map()
    // })),
  };

  const {data} = await axios.post<CommonNetworkResponse<CreateProductResponse>>('/merchant/Product/Add', {
    ..._product,
  });
  if (!data.data?.productId) throw new Error('failed to create product');

  return data.data?.productId;
}

export type FetchProductListOptions = {
  categoryId?: string;
  sortId?: string;
  startIndex: number;
  limit: number;
};

export type FetchProductListResponse = {
  totalItems: number;
  bulkCommision: number;
  categories: {
    id: string;
    label: string;
    count: number;
  }[];
  sorts: {
    id: string;
    label: string;
  }[];
  products: {
    productId: string;
    imageUrl: string;
    name: string;
    brand: string;
    badgeUrl: string;
    view: number;
    like: number;
    purchase: number;
    commision: number;
  }[];
};

export async function fetchProductList(
  options: FetchProductListOptions = {
    limit: 20,
    startIndex: 0,
  },
) {
  const {data} = await axios.post<CommonNetworkResponse<FetchProductListResponse>>('/merchant/Product/List', {
    ...options,
  });

  // console.log(data);
  return data;
}

export type FetchProductStockListOptions = {
  productId: number | string | undefined;
  startIndex: number;
  limit: number;
};

export type FetchProductStockListResponse = {
  totalItems: number;
  variants: Stock[];
};

export async function fetchProductStockList(
  options: FetchProductStockListOptions,
  //  = {
  //   limit: 100,
  //   startIndex: 0,
  //   ...options,
  // },
) {
  const {data} = await axios.post<CommonNetworkResponse<FetchProductStockListResponse>>(
    '/merchant/Product/Stock/List',
    {
      ...options,
    },
  );

  return data;
}

export type UpdatedVariant = {
  id: string;
  stock: number;
};

export type UpdateProductStocksOptions = {
  productId: string;
  updatedVariants: UpdatedVariant[];
};

export type UpdateProductStocksResponse = {};

export async function updateProductStocks(options: UpdateProductStocksOptions) {
  const {data} = await axios.post<CommonNetworkResponse<UpdateProductStocksResponse>>(
    '/merchant/Product/Stock/Update',
    {
      ...options,
    },
  );

  return data;
}

export type DelistProductOptions = {
  productId: string;
};

export type DelistProductResponse = {};

export async function delistProduct(options: DelistProductOptions) {
  const {data} = await axios.post<CommonNetworkResponse<DelistProductResponse>>('/merchant/Product/Delist', {
    ...options,
  });
}

export type DeleteProductOptions = {
  productId: string;
};

export type DeleteProductResponse = {};

export async function deleteProduct(options: DeleteProductOptions) {
  const {data} = await axios.post<CommonNetworkResponse<DeleteProductResponse>>('/merchant/Product/Delete', {
    ...options,
  });
}
