import React from 'react';

import * as Label from '@radix-ui/react-label';
import tw from 'twin.macro';

export type FormSectionProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  label?: string;
  required?: boolean;
  isDisabled?: boolean;
  optional?: boolean;
  labelSize?: "normal"
  labelFontWeight?: "bold"
};

function FormSection(props: FormSectionProps) {
  const { label, isDisabled, required, children, ...rest } = props;

  return (
    <div tw="w-full max-w-full" {...rest}>
      {label && (
        <Label.Root htmlFor={rest.id}
          css={[
            rest.labelSize === "normal" ? "" : tw`text-sm`,
            rest.labelFontWeight === "bold" ? tw`font-bold` : tw`font-semibold`
          ]}>
          {label}
          {required && '*'}
          {rest.optional && <span tw='text-xs text-[rgba(192, 192, 192, 1)] font-normal'> (optional)</span>}
        </Label.Root>
      )}
      <div tw="mt-2">{children}</div>
    </div>
  );
}

export default FormSection;
