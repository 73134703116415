import React, { useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import FloatingFooter from "../../../common/components/floating-footer/floating-footer"
import Button from "../../../common/components/button/button"
import TextInput from "../../../common/components/text-input/text-input"
import { useNavigate } from "react-router-dom"
import { getLocalStorageObject, setLocalStorageObject, validatePassword } from "../../../framework/utils"
import $ from "jquery"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { fepiAxios } from "../../../common/axios"
import registrationConstants from "./constants"
import { error } from "console"
import { Snackbar } from "../../../common/entities/snackbar"

function RegisterSetPasswordPage() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const formData = getLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER)

    const [password, setPassword] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [passwordConfirmationError, setPasswordConfirmationError] = useState("")
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (validatePassword(password) === "OK" || !password) {
            setPasswordError("")
        } else {
            setPasswordError(validatePassword(password))
        }
    }, [password])

    useEffect(() => {
        if (passwordConfirmation !== password) {
            setPasswordConfirmationError("The password does not match the password above")
        } else {
            setPasswordConfirmationError("")
        }
    }, [passwordConfirmation])

    useEffect(() => {
        setIsSubmittable(!!password && !!passwordConfirmation && !passwordError && !passwordConfirmationError)
    }, [password, passwordConfirmation, passwordError, passwordConfirmationError])

    function onSubmit() {
        if (password !== passwordConfirmation) {
            setPasswordConfirmationError("The password does not match the password above")
            $("input[name=passwordConfirmation]").trigger("focus")
            return
        }
        if (!isSubmittable)
            return

        setIsSubmitting(true)
        fepiAxios.post("mobile/Account/RegisterPassword", {
            input_email_telp: formData.email,
            password: password,
            svc_id: '121'
        }).then(response => {
            formData.password = password
            formData.registrationToken = response.data.registrationToken
            setLocalStorageObject("merchantCenterRegistration", formData)
            navigate("/register/merchantInfo")
        }).catch(error => {
            setIsSubmitting(false)
            snackbar.create(new Snackbar({
                message: error.response.data.message,
                type: "danger"
            }))
        })
    }

    return (
        <Page tw="px-6 py-16 pb-20">
            <Header onGoBack={() => navigate("/register/verifyEmail")} title="Set a Password"></Header>

            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput
                    label="Password"
                    required
                    placeholder="Ketik di sini"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(val) => setPassword(val || "")}
                    error={passwordError}
                    tw="mb-3"
                    asterisk="red"
                />

                <TextInput
                    label="Confirm Password"
                    required
                    placeholder="Ketik di sini"
                    name="passwordConfirmation"
                    type="password"
                    value={passwordConfirmation}
                    onChange={(val) => setPasswordConfirmation(val || "")}
                    error={passwordConfirmationError}
                    asterisk="red"
                />

                <FloatingFooter>
                    <Button isLoading={isSubmitting} onClick={onSubmit} isDisabled={!isSubmittable}>Next</Button>
                </FloatingFooter>
            </form>
        </Page>
    )
}

export default withSnackbar(RegisterSetPasswordPage)