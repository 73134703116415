import IconCashout from "../../../common/icons/cashout.svg";
import React from "react";

function WalletCashoutButton() {
  return (
    <button
      tw="w-full h-[3.125rem]  border rounded-xl border-black py-3 px-6 flex flex-row align-middle content-center items-center"
      className="py-3 px-6 flex flex-row align-middle content-center ">
      <div tw="flex flex-row align-middle content-center flex-grow justify-center text-center items-center font-normal text-sm gap-[0.6875rem] ">
        <IconCashout />
        Cashout
      </div>
    </button>
  );
}

export default WalletCashoutButton;
