import mcAxios from "../../../../common/axios";

export type ProductCategory = {
    id: string;
    label: string;
}

export function fetchProductCategoriesAll(
    data?: {
        groupId?: string,
        categoryId?: string,
    },
    callback?: (val: {
        groupItems: ProductCategory[],
        categoryItems: ProductCategory[],
        subCategoryItems: ProductCategory[]
    }) => void
) {
    mcAxios.post("/merchant/Product/Category/List", {
        groupId: data?.groupId,
        categoryId: data?.categoryId
    }).then(res => {
        callback?.(res.data.data)
    })
}

export function fetchProductCategories(
    data?: {
        groupId?: string,
        categoryId?: string,
        key?: "groupItems" | "categoryItems" | "subCategoryItems"
    },
    callback?: (val: ProductCategory[]) => void,
    onError?: (e: any) => void
    ) {
    mcAxios.post("/merchant/Product/Category/List", {
        // fpAuth: localStorage.getItem("fpAuth"),
        groupId: data?.groupId,
        categoryId: data?.categoryId
    }).then((res) => {
        // console.log(res.data.data)
        // const oldCategories = JSON.parse(localStorage.getItem("mcProductCategories") || "")
        // localStorage.setItem("mcProductCategories", JSON.stringify({
        //     ...oldCategories,
        // }))
        callback?.(res.data.data[data?.key || "groupItems"])
    }).catch(error => {
        // console.log(error)
        // throw error
        onError?.(error)
    })
}