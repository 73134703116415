import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import ProgressBar from "./components/progress-bar"
import { AddProductLocalStorageAddress } from "./constants"
import FormSection from "./components/product-form/components/form-section"
import CategoryRadioGroup from "./components/category-radio-group"
import SubcategoryPicker from "./components/subcategory-picker"
import { ProductCategory, fetchProductCategories, fetchProductCategoriesAll } from "./components/product-upload/product-upload-api"
import mcAxios from "../../common/axios"
import { normalizeAddProductParameters } from "./components/product-upload/api"

//const productGroups = ["Fashion", "Beauty", "Food", "Luxury"]
//const productCategories = ["Acc", "Athleisure", "Bag", "Best", "Bottom", "Dress", "Lingerie", "New", "One_Set", "Outer", "Shoes", "Swimwear", "Top"]
//const productSubcategories = ["T-Shirt", "Shirt", "Blouse", "Sweatshirt", "Hoodie", "Knit", "Polo", "Crop", "Sleeveless"]
const productFormData = getLocalStorageObject(AddProductLocalStorageAddress)

function UploadProductPage2() {

    const navigate = useNavigate()

    const [product, setProduct] = useState({
        ...productFormData,
        group: undefined,
        category: undefined,
        subCategories: [],
        groupId: undefined,
        categoryId: undefined,
        subCategoryIds: []
    })
    const [errors, setErrors] = useState({

    })
    const [loadings, setLoadings] = useState({
        groupPick: false,
        categoryPick: false,
        subcategoryPick: false
    })
    const [productGroups, setProductGroups] = useState<ProductCategory[]>([])
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([])
    const [productSubcategories, setProductSubcategories] = useState<ProductCategory[]>([])
    const [pageLoaded, setPageLoaded] = useState(false)
    const [isSubmittable, setIsSubmittable] = useState(false)

    useEffect(() => {
        setLoadings({ ...loadings, groupPick: true })
        setProduct({ ...product, ...getLocalStorageObject(AddProductLocalStorageAddress) })
        //fetchProductCategories({ key: "groupItems" }, (val) => { setProductGroups(val); setLoadings({ ...loadings, groupPick: false }) })
    }, [])

    useEffect(() => {
        setIsSubmittable(product.subCategoryIds.length>0)
    }, [product.subCategories, product.subCategoryIds])

    useEffect(() => {
        if (productGroups.length == 0)
            fetchProductCategoriesAll({ groupId: product.groupId, categoryId: product.categoryId }, (val) => {
                setProductGroups(val.groupItems)
                setProductCategories(val.categoryItems)
                setProductSubcategories(val.subCategoryItems)
                setLoadings({ ...loadings, groupPick: false })
            })
    }, [product])

    function handleSaveAsDraft() {
        mcAxios.post("/merchant/Product/Add", {
            type: "DRAFT",
            ...normalizeAddProductParameters(product)
        }).then((res)=>{
            removeLocalStorageObject(AddProductLocalStorageAddress)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', '\"DRAFT\"')
            navigate(`/products`)
        })
    }

    function handleChangeGroup(group: string) {
        setLoadings({ ...loadings, categoryPick: true })
        fetchProductCategories({ groupId: productGroups?.filter(pg => pg.label === group)?.[0]?.id, key: "categoryItems" }, (val) => { setProductCategories(val); setLoadings({ ...loadings, categoryPick: false }) })
        setProductSubcategories([])
    }

    function handleChangeCategory(category: string) {
        setLoadings({ ...loadings, subcategoryPick: true })
        fetchProductCategories({
            groupId: productGroups?.filter(pg => pg.label === product.group)?.[0]?.id,
            categoryId: productCategories?.filter(pc => pc.label === category)?.[0]?.id,
            key: "subCategoryItems"
        },
            (val) => { setProductSubcategories(val); setLoadings({ ...loadings, subcategoryPick: false }) },
            // please comment, this is just temporary solution on server error
            // e => {
            //     console.log("error happened")
            //     setProductSubcategories([
            //         {
            //             "id": "644",
            //             "label": "T-SHIRT"
            //         },
            //         {
            //             "id": "645",
            //             "label": "SHIRT"
            //         },
            //         {
            //             "id": "646",
            //             "label": "BLOUSE"
            //         },
            //         {
            //             "id": "647",
            //             "label": "SWEATSHIRT"
            //         },
            //         {
            //             "id": "648",
            //             "label": "HOODIE"
            //         },
            //         {
            //             "id": "649",
            //             "label": "KNIT"
            //         },
            //         {
            //             "id": "650",
            //             "label": "POLO"
            //         },
            //         {
            //             "id": "651",
            //             "label": "CROP"
            //         },
            //         {
            //             "id": "652",
            //             "label": "SLEVELESS"
            //         }
            //     ]); 
            //     setLoadings({ ...loadings, subcategoryPick: false }) 
            // }
            // until here
        )
    }

    function handleSubmit() {
        setLocalStorageObject(AddProductLocalStorageAddress, { ...productFormData, ...product, useExisting: false })
        navigate("/product/upload/3")
    }

    return (
        <Page tw="px-6 py-14 pb-20">
            <Header onGoBack={() => {
                setLocalStorageObject(AddProductLocalStorageAddress, { ...product, useExisting: true})
                navigate("/product/upload")
            }} title="Product Detail"></Header>
            <ProgressBar tw="mb-6" title="Category" current={2} total={6} />
            <div tw="h-5"></div>

            <FormSection label="Group" labelSize="normal" tw="mb-4">
                <CategoryRadioGroup
                    identifier="productGroup"
                    value={product.group}
                    options={productGroups?.map(pc => pc.label)}
                    onChange={(val) => { setProduct({ ...product, group: val, groupId: productGroups.filter(pg => pg.label === val)[0].id, category: "", categoryId: "" }); handleChangeGroup(val) }}
                    isLoading={loadings.groupPick}
                />
            </FormSection>

            <FormSection label="Category" labelSize="normal" tw="mb-4">
                <CategoryRadioGroup
                    identifier="productCategory"
                    value={product.category}
                    options={productCategories?.map(pc => pc.label)}
                    onChange={(val) => { setProduct({ ...product, category: val, categoryId: productCategories.filter(pc => pc.label === val)[0].id, subCategories: [], subCategoryIds: [] }); handleChangeCategory(val) }}
                    expand={!!product.group}
                    isLoading={loadings.categoryPick}
                />
            </FormSection>

            <FormSection label="Subcategories" labelSize="normal" tw="mb-3">
                <SubcategoryPicker
                    values={product.subCategories}
                    options={productSubcategories?.map(pc => pc.label)}
                    onChange={(val) => setProduct({ ...product, subCategories: val, subCategoryIds: productSubcategories.filter(ps => val.includes(ps.label)).map(ps => ps.id) })}
                    expand={!!product.category}
                    isLoading={loadings.subcategoryPick}
                />
            </FormSection>

            <FloatingFooter tw="flex gap-3">
                <Button onClick={handleSaveAsDraft} variant="outline">Save as draft</Button>
                <Button isDisabled={!isSubmittable} onClick={handleSubmit}>Next</Button>
            </FloatingFooter>
        </Page >
    )
}

export default UploadProductPage2