import React, {useCallback} from 'react';

const KEY = '__USE_REMEMBER__';

const useRemember = (key: string): [(v: any) => void, () => string | null, () => void] => {
  const memoize = (value: any) => {
    window?.localStorage?.setItem(`${KEY}--${key}`, JSON.stringify(value || ''));
  };
  const remember = (): string | null => {
    const v = window?.localStorage?.getItem(`${KEY}--${key}`);
    return v ? JSON.parse(v) : null;
  };
  const forget = () => {
    window?.localStorage?.removeItem(`${KEY}--${key}`);
  };

  return [memoize, remember, forget];
};

export default useRemember;
