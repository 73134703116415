import React from "react"
import tw from "twin.macro";

import * as Label from '@radix-ui/react-label';

type MultiplePickerProps = {
    label?: string;
    required?: boolean;
    optional?: boolean;
    values?: string[];
    options: string[];
    onChange?: (value: any) => void;
    maxSelected?: number;
}

function MultiplePicker(props: MultiplePickerProps) {
    const { values, onChange = () => { }, options, maxSelected = Infinity, ...rest } = props

    function handleChange(option: string) {
        let newValues = values || []

        // If already selected
        if (values?.includes(option)) {
            newValues = values.filter(value => value !== option)
            onChange(newValues)
            return
        }

        // If not already selected
        if ((values?.length || 0) < maxSelected) {
            newValues.push(option)
            onChange(newValues)
        }

        // If not already selected but maxSelected is 1
        if(!values?.includes(option) && maxSelected == 1){
            newValues = [option]
            onChange(newValues)
        }
    }

    return (
        <div tw="w-full max-w-full" {...rest}>
            {rest.label && (
                <Label.Root tw="font-semibold text-sm flex justify-between">
                    <div>
                        {rest.label}
                        {rest.required && '*'}
                        {rest.optional && <span tw='text-xs text-[rgba(192, 192, 192, 1)] font-normal'> (optional)</span>}
                    </div>
                    <div tw="text-xs text-end text-[rgba(192, 192, 192, 1)] font-normal">{values?.length}/{maxSelected}</div>
                </Label.Root>
            )}
            <div tw="mt-2">
                <div tw="flex gap-2 flex-wrap">
                    {options.map((option) => (
                        <div
                            key={option}
                            onClick={() => handleChange(option)}
                            tw="text-neutral-400 border border-neutral-400 py-[6px] px-4 rounded-[20px] whitespace-nowrap"
                            css={values?.includes(option) && tw`bg-[rgba(98, 98, 98, 1)] text-white border-[rgba(98, 98, 98, 1)]`}
                        >{option}</div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MultiplePicker