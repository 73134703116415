import React, {forwardRef, useEffect, useRef, useState} from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import {useDraggable} from 'react-use-draggable-scroll';
import {css} from '@emotion/react';
import tw from 'twin.macro';

export type TabsProps = {
  items?: Array<{
    label?: string;
    id: string;
  }>;
} & RadioGroup.RadioGroupProps;

export type TabItemProps = {
  isSelected?: boolean;
  label?: string;
  id?: string;
} & React.HTMLAttributes<HTMLButtonElement>;

function TabItem(props: TabItemProps) {
  const isActive = props['data-state'] === 'checked';
  const {label, id, isSelected} = props;

  return (
    <button tw="flex-shrink-0 text-sm text-gray-400" css={[isActive && tw`font-bold text-black`]} {...props}>
      <div tw="mt-2" />
      {label}
      <div tw="mb-2" />
    </button>
  );
}

function Tabs(props: TabsProps, ref: any) {
  const {items = [], ...rest} = props;

  const defaultRenderIndicator = (args: TabItemProps) => {
    return <TabItem {...args} />;
  };

  const [activeTabStyle, setActiveTabStyle] = useState({
    width: 0,
    transform: 'translateX(0)',
  });

  const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const {events} = useDraggable(containerRef); // Now we pass the reference to the useDraggable hook:

  useEffect(() => {
    const activeTab = containerRef.current.querySelector('[role="radio"][data-state="checked"]');
    activeTab?.scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
      block: 'center',
    });
    setActiveTabStyle({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      width: activeTab?.offsetWidth || 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      transform: `translateX(${activeTab?.offsetLeft - 24}px)`,
    });
    // setActiveTabStyle({
    //   width: activeTab?.offsetWidth,
    //   transform: `translateX(${activeTab?.offsetLeft}px)`,
    // });
  }, [props.value]);

  return (
    <RadioGroup.Root tw="relative overflow-hidden" {...rest} ref={ref}>
      <div
        ref={containerRef}
        {...events}
        data-disable-scrollbar-ui="true"
        tw="px-6 relative flex gap-4 overflow-x-scroll">
        <div tw="absolute bottom-0 left-0" />
        <div tw="h-0.5 w-full bg-black rounded-full absolute transition-all bottom-0" style={activeTabStyle} />
        {items.map(item => (
          <React.Fragment key={item.id}>
            <RadioGroup.Item value={item.id} id={item.id} asChild>
              <RadioGroup.Indicator asChild forceMount>
                {defaultRenderIndicator(item)}
              </RadioGroup.Indicator>
            </RadioGroup.Item>
          </React.Fragment>
        ))}
      </div>
    </RadioGroup.Root>
  );
}

export default forwardRef(Tabs);
