import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils';

export type PageProps = {
  shouldAuth?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function Page(props: PageProps) {
  const { shouldAuth = false, ...rest } = props;

  const navigate = useNavigate()

  return <div {...rest} tw="h-full max-w-[500px] mx-auto relative min-h-screen"></div>;
}

export default Page;
