import React from 'react';
import tw from 'twin.macro';
import {OrderType, OrderTypeLabel} from '../../../common/entities/order';

export type OrderTypeTagProps = {
  type?: OrderType;
};

function OrderTypeTag(props: OrderTypeTagProps) {
  const {type} = props;

  if (!type) return null;

  return (
    <span
      tw="rounded-lg border"
      css={[
        ([OrderType.PAID, OrderType.TO_SHIP, OrderType.UNPAID, OrderType.IN_DELIVERY] as OrderType[]).includes(type) &&
          tw`bg-yellow-100 text-yellow-700 border-yellow-700 py-1 px-2 text-[10px]`,
        ([OrderType.COMPLETED] as OrderType[]).includes(type) &&
          tw`bg-purple-100 text-purple-700 border-purple-700 py-1 px-2 text-[10px]`,
        ([OrderType.CANCELLED] as OrderType[]).includes(type) &&
          tw`bg-red-100 text-red-700 border-red-700 py-1 px-2 text-[10px]`,
      ]}>
      {OrderTypeLabel[type]}
    </span>
  );
}

export default OrderTypeTag;
