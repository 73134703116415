import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { findAllByTestId } from '@testing-library/react';
import Page from '../../common/components/page/page';
import Header from '../../common/components/header/header';
import Tabs from '../../common/components/tabs/tabs';
import NotFound from '../../common/components/not-found/not-found';
import useRemember from '../../common/hooks/use-remember';
import PulseLoader from '../../common/components/loaders/pulse';

import IconSort from '../../common/icons/sort.svg';
import IconCheckbox from '../../common/icons/checkbox.svg';
import BulkComission from '../bulk/bulk-comission';
import ProductListInfoButton from './product-list-info-button';
import { ProductV2 } from '../../common/entities/productv2';
import useProductList from './hooks/use-product-list';
import ProductCard from './components/product-list/product-card';
import ProductStockSheet from './components/product-list/product-stock-sheet';
import ProductMoreOptionsSheet from './components/product-list/product-more-options-sheet';
import { deleteProduct, delistProduct } from './api';
import Button from '../../common/components/button/button';
import PlusIcon from '../../common/icons/plus-white.svg'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../common/components/sidebar/sidebar';
import mcAxios from '../../common/axios';

function ProducListPage() {
  const navigate = useNavigate()

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [memoizeCategoryKey, rememberCategoryKey] = useRemember('product-list-category-key');
  const [selectedSort, setSelectedSort] = useState<string>('');
  const [memoizeSortKey, rememberSortKey] = useRemember('product-list-sort-key');
  const [showSortModal, setShowSortModal] = useState<boolean>(false);
  const [stockSheet, setStockSheet] = useState<{ isShown: boolean; product?: ProductV2 }>({ isShown: false });
  const [moreOptionsSheet, setMoreOptionsSheet] = useState<{ isShown: boolean; productId?: string }>({ isShown: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  useEffect(() => {
    const _key = rememberCategoryKey();
    const _keySort = rememberSortKey();
    if (_key) {
      setSelectedCategory(_key);
    }
    if (_keySort) {
      setSelectedSort(_keySort);
    }
    mcAxios.post("/merchant/Home").then((res) => {
      let data = res.data.data
      setProfile({
        imageUrl: data.profile.imageUrl,
        name: data.profile.name
      })
    }).finally(()=>{
      mcAxios.post("/merchant/Setting/BulkCommission").then((res)=>{
        let data = res.data.data
        setBulkCommission(data.bulkCommission)
      })
    })
  }, []);

  const {
    data: productCollection,
    isFetching: isFetchingProductData,
    isLoading: isLoadingProductData,
    fetchNextPage: fetchNextProducts,
    isFetchingNextPage: isFetchingNextProducts,
  } = useProductList({
    limit: 5,
    startIndex: 0,
    categoryId: selectedCategory,
    sortId: selectedSort,
  });

  const { ref } = useInView({
    threshold: [0],
    onChange: inView => {
      if (inView) {
        fetchNextProducts();
      }
    },
  });

  const products = useMemo(() => productCollection?.pages.flatMap(v => v.products) || [], [productCollection]);
  const categories = useMemo(() => productCollection?.pages[0]?.categories || [], [productCollection]);
  const sorts = useMemo(() => productCollection?.pages[0]?.sorts || [], [productCollection]);
  const [profile, setProfile] = useState<any>({})
  const [bulkCommission, setBulkCommission] = useState(0)
  const [showBulkCommissionSheet, setShowBulkCommissionSheet] = useState(false)
  const [productIdToUpdate, setProductIdToUpdate] = useState("")

  const handleCategoryChange = useCallback(
    (v: string) => {
      memoizeCategoryKey(v);
      setSelectedCategory(v);
    },
    [memoizeCategoryKey, setSelectedCategory],
  );

  // const handleSortChange = (v: string) => {
  //   memoizeSortKey(v);
  //   setSelectedSort(v);
  // };

  const handleShowStockSheet = (product: ProductV2) => {
    setStockSheet({ isShown: true, product: product });
  };

  const handleCloseStockSheet = () => {
    setStockSheet({ ...stockSheet, isShown: false });
  };

  useEffect(() => {
    setSelectedSort(selectedSort || sorts[0]?.id);
  }, [sorts]);

  const getSortLabelById = (id: string) => {
    for (const sort of sorts) {
      if (sort.id === id) {
        return sort.label;
      }
    }
    return sorts[0].label;
  };

  const handleShowMoreOptionsSheet = (productId: string) => {
    setMoreOptionsSheet({ isShown: true, productId: productId });
  };

  const handleCloseMoreOptionsSheet = () => {
    setMoreOptionsSheet({ ...moreOptionsSheet, isShown: false });
  };

  const handleDelistProduct = (productId: string) => {
    setMoreOptionsSheet({ ...moreOptionsSheet, isShown: false });
    setIsLoading(true);
    delistProduct({ productId: productId })
      .then(() => {
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        console.log('delist product error');
      });
  };

  const handleDeleteProduct = (productId: string) => {
    setMoreOptionsSheet({ ...moreOptionsSheet, isShown: false });
    setIsLoading(true);
    deleteProduct({ productId: productId })
      .then(() => {
        setIsLoading(false);
      })
      .catch(e => {
        setIsLoading(false);
        console.log('delete product error');
      });
  };

  return (
    <Page shouldAuth={true} tw="pb-36 pt-24 bg-gray-100">
      {products.length === 0 && !isFetchingProductData && (
        <div tw="absolute flex items-center justify-center inset-0">
          <NotFound title="No products" />
        </div>
      )}

      <Header hasBurger onBurgerClicked={()=>setIsSidebarExpanded(true)} title="Products" />
      <div style={{ top: '2.9rem' }} tw="left-1/2 -translate-x-1/2 fixed max-w-mvw w-full bg-white z-10">
        <Tabs
          value={selectedCategory || categories[0]?.label}
          onValueChange={handleCategoryChange}
          items={categories?.map(category => category.toJSON())}
        />
      </div>

      {sorts?.length > 0 && (
        <div style={{ top: '4.9rem' }} tw="absolute container z-0">
          <div
            style={{ boxShadow: '0 1px 3px -1px rgb(0 0 0 / 0.1)' }}
            tw="h-6 text-xs flex flex-row-reverse p-4 pt-5 pr-5 items-center bg-white">
            <button tw="flex items-center" onClick={() => setShowSortModal(true)}>
              {getSortLabelById(selectedSort) || sorts[0]?.label}
              <div style={{ width: '6px' }} />
              <IconSort />
            </button>
          </div>
        </div>
      )}

      {isLoadingProductData || isLoading ? (
        <div tw="absolute flex items-center justify-center inset-0">
          <PulseLoader theme="light" />
        </div>
      ) : (
        <div tw="flex flex-col gap-3 mt-7">
          <ProductListInfoButton 
            bulkCommission={bulkCommission}
            onClick={()=>{
              setShowBulkCommissionSheet(true)
            }}
          />
          {products?.map(product => (
            <ProductCard
              key={product.id}
              productId={product.id}
              imageUrl={product.imageUrl}
              name={product.name}
              brand={product.brand}
              badgeUrl={product.badgeUrl}
              view={product.view}
              like={product.like}
              purchase={product.purchase}
              commision={product.commision}
              onEditStock={() => handleShowStockSheet(product)}
              onEditDetail={() => navigate(`/product/${product.id}/edit`)}
              onMoreOptions={() => handleShowMoreOptionsSheet(product.id)}
              productIdToUpdate={productIdToUpdate}
            />
          ))}
        </div>
      )}

      <div ref={ref}></div>

      {isFetchingNextProducts && (
        <div tw="flex items-center justify-center py-3">
          <PulseLoader theme="light" />
        </div>
      )}

      <div
        id="BottomModalBackground"
        className={showSortModal ? 'BottomModalBackgroundShown' : 'BottomModalBackgroundHidden'}
        onClick={() => setShowSortModal(false)}
        tw="top-0 bottom-0 left-0 right-0 absolute bg-[#0000004d]"
        style={
          showSortModal
            ? {
              zIndex: 100,
            }
            : {
              display: 'none',
            }
        }
      />
      <div
        id={showSortModal ? 'BottomModalContainerShown' : 'BottomModalContainerHidden'}
        tw="fixed bg-white rounded-t-xl max-w-[500px] w-full"
        style={
          showSortModal
            ? {
              zIndex: 100,
              bottom: 0,
              transition: 'bottom 0.5s',
            }
            : {
              bottom: '-100vh',
              transition: 'bottom 0.5s',
            }
        }>
        <div id="BottomModalSortTitleContainer" tw="h-14 pl-6 pr-6 flex items-center">
          <p tw="text-lg font-bold text-[#383838]">SORT BY</p>
          <div tw="flex-grow" />
          <button onClick={() => setShowSortModal(false)} tw="text-sm text-purple-700">
            Done
          </button>
        </div>
        {sorts?.map(sort => {
          return (
            <>
              <div id="BottomModalSortSeparator" tw="bg-gray-200 h-[1px]" />
              <button
                onClick={() => setSelectedSort(sort.id)}
                id="BottomModalSortItemContainer"
                tw="flex py-4 px-6 items-center w-full text-left">
                <p
                  style={selectedSort === sort.id ? { fontWeight: 'bold' } : { color: 'c0c0c0' }}
                  tw="flex-grow text-sm mr-4">
                  {sort.label}
                </p>
                {selectedSort === sort.id && <IconCheckbox />}
              </button>
            </>
          );
        })}
      </div>
      {stockSheet.isShown && (
        <ProductStockSheet
          isShown={stockSheet.isShown}
          product={stockSheet.product || products[0]}
          onBackgroundClick={() => {
            handleCloseStockSheet();
          }}
          onCancelClick={() => {
            handleCloseStockSheet();
          }}
          onBackClick={() => {
            handleCloseStockSheet();
          }}
          onSuccess={() => {
            setProductIdToUpdate("")
            setProductIdToUpdate(stockSheet.product?.id || "")
          }}
        />
      )}
      {moreOptionsSheet.isShown && (
        <ProductMoreOptionsSheet
          isShown={moreOptionsSheet.isShown}
          productId={moreOptionsSheet.productId || products[0].id}
          onClosed={() => handleCloseMoreOptionsSheet()}
          onDelistProduct={() => handleDelistProduct(moreOptionsSheet.productId || products[0].id)}
          onDeleteProduct={() => handleDeleteProduct(moreOptionsSheet.productId || products[0].id)}
        />
      )}
      <div tw='fixed bottom-6 flex justify-center w-full max-w-[500px]'>
        <Button onClick={() => navigate("/product/upload")} tw='bg-[#894CF5] border-0 shadow' shrink radius="999px">
          <PlusIcon />
          &nbsp;
          Upload Product
        </Button>
      </div>
      <Sidebar
        isExpanded={isSidebarExpanded}
        onClosed={() => setIsSidebarExpanded(false)}
        selectedMenu="products"
        profileImageUrl={profile.imageUrl}
        profileName={profile.name}
      />
      {showBulkCommissionSheet &&
        <BulkComission
          bulkCommission={bulkCommission}
          isShown={showBulkCommissionSheet}
          onClosed={()=>setShowBulkCommissionSheet(false)}
          onChange={(val)=>{
            setBulkCommission(val)
            setShowBulkCommissionSheet(false)
          }}
        />
      }
    </Page>
  );
}

export default ProducListPage;
