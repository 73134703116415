import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import MultiplePicker from "../../common/components/multiple-picker/multiple-picker"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import TextInput from "../../common/components/text-input/text-input"
import TextInputGrouped from "../../common/components/text-input/text-input-grouped"
import { Brand } from "../../common/entities"
import FormSection from "./components/product-form/components/form-section"
import ProgressBar from "./components/progress-bar"
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import { AddProductLocalStorageAddress } from "./constants"
import mcAxios from "../../common/axios"
import { FabricMaterial, ProductTag, ProductTagItems, firstOrNothing } from "./utils"
import CategoryRadioGroup from "./components/category-radio-group"
import { normalizeAddProductParameters } from "./components/product-upload/api"

const genders = ["Men", "Women"]
const productStyles = ["Street", "Minimal", "Romantic", "Business Casual", "Easy Casual", "Modern", "Sporty", "Retro", "Luxury", "Unique", "One-mile Wear", "Glam"]
const productFormData = getLocalStorageObject(AddProductLocalStorageAddress)

function UploadProductPage3() {

    const navigate = useNavigate()
    const [brand, setBrand] = useState<Brand>()
    const [product, setProduct] = useState({
        ...productFormData,
        description: "",
        weight: "",
        dimension: {
            p: "",
            l: "",
            t: ""
        },
        genders: [],
        styles: []
    })
    const [tags, setTags] = useState<ProductTag[]>([])
    const [fabricMaterial, setFabricMaterial] = useState<FabricMaterial>()
    const [errors, setErrors] = useState({

    })
    const [isSubmittable, setIsSubmittable] = useState(false)

    // useEffect(()=>{
    //     console.log("fabricMaterial",fabricMaterial)
    // },[fabricMaterial])

    useEffect(() => {
        let newProduct = { ...product, ...getLocalStorageObject(AddProductLocalStorageAddress)}
        setProduct(newProduct)
        mcAxios.post("/merchant/Product/Detail/Attributes", {
            selectedCategoryId: newProduct.categoryId,
            selectedSubCategoryIds: newProduct.subCategoryIds,
            selectedGroupId: newProduct.groupId
        }).then((res)=>{
            // process tags
            let designatedTags = res.data.data.tags
            for(let designatedTag of designatedTags){
                // check if found
                let existingTag = undefined
                for(let tag of (newProduct.tags || [])){
                    if(designatedTag.label === tag.label){
                        existingTag = tag
                        break
                    }
                }
                // populate designated tags
                for(let designatedTagItem of designatedTag.items){
                    // check if found
                    let existingTagItem = undefined
                    for(let tagItem of (existingTag?.items || [])){
                        if(designatedTagItem.label === tagItem.label){
                            existingTagItem = tagItem
                            break
                        }
                    }
                    designatedTagItem.isSelected = existingTagItem ? existingTagItem.isSelected : false
                }
            } 
            // console.log("designatedTags",designatedTags)
            setTags(designatedTags)
            // process fabric materials
            let designatedFabricMaterials = res.data.data.fabricMaterial
            designatedFabricMaterials.name = newProduct.fabricMaterial?.name || ""
            for(let designatedFabricMaterialDetail of designatedFabricMaterials.details){
                // check if found
                let existingFabricMaterialDetail = undefined
                for(let fabricMaterialDetail of (newProduct.fabricMaterial?.details || [])){
                    if(designatedFabricMaterialDetail.label === fabricMaterialDetail.label){
                        existingFabricMaterialDetail = fabricMaterialDetail
                        break
                    }
                }
                // console.log("existingFabricMaterialDetail",existingFabricMaterialDetail)
                // populate fabric material detail
                let newFabricMaterialDetailItems = []
                for(let designatedFabricMaterialDetailValue of designatedFabricMaterialDetail.values){
                    // check if found
                    let existingFabricMaterialDetailItem = undefined
                    for(let fabricMaterialDetailItem of (existingFabricMaterialDetail?.items || [])){
                        if(designatedFabricMaterialDetailValue === fabricMaterialDetailItem.label){
                            existingFabricMaterialDetailItem = fabricMaterialDetailItem
                            break
                        }
                    }
                    // populate fabric material detail item
                    newFabricMaterialDetailItems.push({
                        label: designatedFabricMaterialDetailValue,
                        isSelected: existingFabricMaterialDetailItem ? existingFabricMaterialDetailItem.isSelected : false
                    })
                }
                designatedFabricMaterialDetail.items = newFabricMaterialDetailItems
            }
            setFabricMaterial(designatedFabricMaterials)
        }).catch((e)=>{
            console.error(e)
        })
    }, [])

    useEffect(() => {
        if (!product.weight) {
            setIsSubmittable(false)
            return
        }
        for (let tag of tags) {
            let chosen = false
            if (!tag.isOptional) {
                for(let option of tag.items) {
                    if(option.isSelected) {
                        chosen = true
                        break
                    }
                }
            } else {
                chosen = true
            }
            if (!chosen) {
                setIsSubmittable(false)
                return
            }
        }
        setIsSubmittable(true)
    }, [product.weight, tags])

    function handleSaveAsDraft() {
        mcAxios.post("/merchant/Product/Add", {
            type: "DRAFT",
            ...normalizeAddProductParameters(product)
        }).then((res)=>{
            removeLocalStorageObject(AddProductLocalStorageAddress)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', '\"DRAFT\"')
            navigate(`/products`)
        })
    }

    function handleSubmit() {
        let newProduct = { ...productFormData, ...product, fabricMaterial: fabricMaterial, tags: tags }
        setLocalStorageObject(AddProductLocalStorageAddress, newProduct)
        navigate("/product/upload/4")
    }

    return (
        <Page tw="px-6 py-14 pb-20">
            <Header onGoBack={() => navigate("/product/upload/2")} title="Product Detail"></Header>
            <ProgressBar tw="mb-6" title="Basic Information" current={3} total={6} />
            <div tw="h-5"></div>

            <TextInput
                label="Product Description"
                placeholder="Describe your product here..."
                multiline
                value={product.description}
                onChange={(val) => setProduct({ ...product, description: val })}
                tw="mb-3"
            />
            <TextInput
                label="Weight (gr)" required
                type="number"
                value={product.weight}
                onChange={(val) => setProduct({ ...product, weight: val })}
                tw="mb-3"
            />
            {/* <FormSection label="Dimension (cm)" required tw="mb-4">
                <div tw="flex items-center gap-2">
                    <TextInputGrouped
                        leftSibling="P"
                        type="number"
                        value={product.dimension.p}
                        onChange={(val) => setProduct({ ...product, dimension: { ...product.dimension, p: val } })}
                    />
                    <span tw="text-xl">&times;</span>
                    <TextInputGrouped
                        leftSibling="L"
                        type="number"
                        value={product.dimension.l}
                        onChange={(val) => setProduct({ ...product, dimension: { ...product.dimension, l: val } })}
                    />
                    <span tw="text-xl">&times;</span>
                    <TextInputGrouped
                        leftSibling="T"
                        type="number"
                        value={product.dimension.t}
                        onChange={(val) => setProduct({ ...product, dimension: { ...product.dimension, t: val } })}
                    />
                </div>
            </FormSection> */}

            {/* Product Tags */}
            {tags.map((tag: ProductTag) => (
                <MultiplePicker
                    label={tag.label}
                    required={!tag.isOptional}
                    options={tag.items.map(item => item.label)}
                    values={tag.items.filter(item => item.isSelected).map(item => item.label)}
                    onChange={(val) => {
                        let newItems = [...tag.items]
                        for (let item of newItems) {
                            item.isSelected = val.includes(item.label)
                        }
                        tag.items = newItems
                        // console.log("newTags",tags)
                        setTags([...tags])
                    }}
                    maxSelected={tag.maxSelection}
                    tw="mb-5"
                />
            ))}

            {/* Fabric Material */}
            {fabricMaterial &&
                <>
                    <TextInput
                        label="Fabric Material"
                        value={fabricMaterial.name}
                        onChange={(val) => setFabricMaterial({ ...fabricMaterial, name: val || "" })}
                        tw="mb-4"
                    />
                    {fabricMaterial.details.map((detail) => (
                        <>
                            <FormSection label={detail.label}>
                                <CategoryRadioGroup
                                    identifier={detail.label}
                                    value={firstOrNothing(detail.items?.filter(item => item.isSelected).map(item => item.label))}
                                    options={detail.items?.map(item => item.label)}
                                    onChange={(val) => {
                                        for (let item of detail.items) {
                                            item.isSelected = item.label === val
                                        }
                                        setFabricMaterial({ ...fabricMaterial })
                                    }}
                                    withDividerLine={false}
                                    columns={3}
                                    fontSize="small"
                                />
                            </FormSection>
                            <div tw="mb-4"></div>
                        </>
                    ))}
                </>
            }

            <FloatingFooter tw="flex gap-3">
                <Button onClick={handleSaveAsDraft} variant="outline">Save as draft</Button>
                <Button isDisabled={!isSubmittable} onClick={handleSubmit}>Next</Button>
            </FloatingFooter>
        </Page >
    )
}

export default UploadProductPage3