import {BaseEntity} from './base';

export type SnackbarArgs = {
  id?: string;
  message?: string;
  type?: 'danger' | 'warning' | 'normal';
};

export class Snackbar extends BaseEntity {
  id?: string;
  message?: string;
  type?: 'danger' | 'warning' | 'normal';

  constructor(args: SnackbarArgs) {
    super();
    Object.assign(this, args);
  }
}
