import React from "react";
import IconMoneyOnHold from "../../../common/icons/money-on-hold.svg";
import IconMoneySettled from "../../../common/icons/money-settled.svg";
import IconChevronRight from "../../../common/icons/chevron-right.svg";
import { useNavigate } from "react-router-dom";

export type WalletCardProps = {
  balance?: string;
  onHold?: string;
  settled?: string;
  hasChevron?: boolean;
};

function WalletCard(props: WalletCardProps) {
  const { balance, onHold, settled } = props;
  const navigate = useNavigate()

  return (
    <div
      className="WalletCard flex flex-col gap-1"
      tw="rounded-xl bg-white py-3 px-4 flex flex-col gap-1 shadow">
      <div tw="flex items-center">
        <div className="WalletCardTitle text-xl px-1" tw="text-xl flex-grow">
          <b>Wallet</b>
          <div className="WalletCardBalance" tw="text-lg font-semibold">
            {balance}
          </div>
        </div>
        {props.hasChevron &&
          <div onClick={() => navigate("/wallet/summary")}>
            <IconChevronRight tw="h-6" />
          </div>
        }
      </div>

      <div className="WalletCardDetails py-2" tw="flex flex-row py-2">
        <div className="WalletCardOnHold" tw="flex-grow">
          <div
            className="WalletCardOnHoldTitle text-sm text-[#626262]"
            tw="flex flex-row gap-1 text-sm font-semibold text-[#626262]">
            <IconMoneyOnHold />
            On Hold
          </div>
          <div
            className=" text-[#626262] text-sm font-normal"
            tw=" text-[#626262] text-sm font-normal">
            {onHold}
          </div>
        </div>

        <div className="WalletCardSettled" tw="flex-grow">
          <div
            className="WalletCardSettledTitle"
            tw="flex flex-row gap-1 text-sm font-semibold text-[#626262]">
            <IconMoneySettled />
            Settled
          </div>
          <div tw=" text-[#626262] text-sm font-normal">{settled}</div>
        </div>
      </div>
    </div>
  );
}

export default WalletCard;
