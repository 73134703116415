import {useInfiniteQuery} from 'react-query';
import {Label} from '../../../common/entities/label';
import {ProductV2} from '../../../common/entities/productv2';
import {FetchProductListOptions, fetchProductList} from '../api';

const useProductList = (
  options: FetchProductListOptions & {
    onSuccess?: (args: {categories: Label[]; products: ProductV2[]; sorts: Label[]}) => void;
  },
) => {
  const key = ['product-list', options.categoryId, options.sortId];

  return useInfiniteQuery({
    keepPreviousData: true,
    queryKey: key,
    queryFn: async ({pageParam = 0}) => {
      const result = await fetchProductList({...options, startIndex: pageParam * options.limit});
      // console.log(result.data);
      return {
        categories: result.data?.categories.map(Label.cast) || [],
        products: result.data?.products?.map(ProductV2.cast) || [],
        sorts: result.data?.sorts?.map(Label.cast) || [],
        total: result.data?.totalItems,
        __page: pageParam++,
      };
    },
    getNextPageParam: lastPage => {
      if (lastPage.__page * options.limit < (lastPage.total || 0)) {
        return lastPage.__page + 1;
      }
    },
  });
};

export default useProductList;
