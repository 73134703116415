import {BaseEntity} from './base';

export type LabelArgs = {
  label?: string;
  id?: string;
  stock?: number;
};

export class Stock extends BaseEntity {
  label?: string;
  id = '';
  stock = 0;

  constructor(args?: LabelArgs) {
    super();
    Object.assign(this, args);
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
      stock: this.stock,
    };
  }

  static cast(args: any) {
    return new Stock({
      id: args.id,
      label: `${args.label} ${args.totalItem ? ` (${args.totalItem})` : ''}${args.count ? `(${args.count})` : ''}`,
      stock: args.stock,
    });
  }
}
