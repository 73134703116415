import React, { useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import { useNavigate } from "react-router-dom"
import TextInput from "../../../common/components/text-input/text-input"
import FloatingFooter from "../../../common/components/floating-footer/floating-footer"
import Button from "../../../common/components/button/button"
import $ from "jquery"
import { getLocalStorageObject, setLocalStorageObject, validatePhoneNumber } from "../../../framework/utils"
import registrationConstants from "./constants"
import mcAxios from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"

function RegisterMerchantInfo() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()
    let formData = getLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER)

    const [error, setError] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberError, setPhoneNumberError] = useState("")
    const [fullName, setFullName] = useState("")
    const [fullNameError, setFullNameError] = useState("")
    const [dob, setDob] = useState<any>()
    const [dobError, setDobError] = useState("")
    const [merchantName, setMerchantName] = useState("")
    const [merchantNameError, setMerchantNameError] = useState("")
    const [socialMediaError, setSocialMediaError] = useState("")
    const [instagram, setInstagram] = useState("")
    const [instagramError, setInstagramError] = useState("")
    const [tokopedia, setTokopedia] = useState("")
    const [tokopediaError, setTokopediaError] = useState("")
    const [shopee, setShopee] = useState("")
    const [shopeeError, setShopeeError] = useState("")
    const [tiktok, setTiktok] = useState("")
    const [tiktokError, setTiktokError] = useState("")
    const [other, setOther] = useState("")
    const [otherError, setOtherError] = useState("")
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setPhoneNumberError(validatePhoneNumber(phoneNumber) || !phoneNumber ? "" : "No Handphone tidak valid")
    }, [phoneNumber])

    useEffect(() => {
        setInstagramError(instagram.includes(" ") ? "Tidak boleh mengandung spasi" : "")
    }, [instagram])

    useEffect(() => {
        setTokopediaError(tokopedia.includes(" ") ? "Tidak boleh mengandung spasi" : "")
    }, [tokopedia])

    useEffect(() => {
        setShopeeError(shopee.includes(" ") ? "Tidak boleh mengandung spasi" : "")
    }, [shopee])

    useEffect(() => {
        setTiktokError(tiktok.includes(" ") ? "Tidak boleh mengandung spasi" : "")
    }, [tiktok])

    useEffect(() => {
        setOtherError(other.includes(" ") ? "Tidak boleh mengandung spasi" : "")
    }, [other])

    useEffect(() => {
        let socialMediaAccounts = `${instagram},${tokopedia},${shopee},${tiktok},${other}`
        let socialMediaAccountsCount = socialMediaAccounts.split(",").filter(thing => thing).length
        setIsSubmittable(!!phoneNumber && !!fullName && !!dob && !!merchantName && (socialMediaAccountsCount >= 2))
    }, [phoneNumber, fullName, dob, merchantName, instagram, tokopedia, shopee, tiktok, other])

    function onSubmit() {
        if (!isSubmittable)
            return

        if (!phoneNumber) {
            setPhoneNumberError("No Handphone harus diisi")
            $("input[name=phoneNumber]").trigger("focus")
            return
        }
        if (!fullName) {
            setFullNameError("Nama harus diisi")
            $("input[name=fullName]").trigger("focus")
            return
        }
        if (!dob) {
            setDobError("Tanggal Lahir harus diisi")
            $("input[name=dob]").trigger("focus")
            return
        }
        if (!merchantName) {
            setMerchantNameError("Nama Merchant harus diisi")
            $("input[name=merchantName").trigger("focus")
            return
        }

        let socialMediaAccounts = `${instagram},${tokopedia},${shopee},${tiktok},${other}`
        let socialMediaAccountsCount = socialMediaAccounts.split(",").filter(thing => thing).length

        if (socialMediaAccountsCount < 2) {
            setSocialMediaError("Isi minimal dua link media sosial")
            $('#socialMediaError').trigger("focus")
            return
        } else {
            setSocialMediaError("")
        }

        formData = {
            ...formData,
            merchantInfo: {
                phoneNumber: phoneNumber,
                name: fullName,
                dob: dob,
                merchantName: merchantName,
                socialMediaUsernames: {
                    instagram: instagram,
                    tokopedia: tokopedia,
                    shopee: shopee,
                    tiktok: tiktok,
                    other: other
                }
            }
        }

        setIsSubmitting(true)
        mcAxios.post("merchant/Register/MerchantInfo/", {
            // registrationToken: formData.registrationToken,
            registrationToken: formData.email,
            merchantInfo: formData.merchantInfo
        }).then(response => {
            setLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER, formData)
            navigate("/register/success")
        }).catch(error => {
            setIsSubmitting(false)
            const errorMessage = !!error.response?.data?.message ? error.response.data.message : "Something went wrong"
            snackbar.create(new Snackbar({
                message: errorMessage,
                type: "danger"
            }))
        })
    }

    return (
        <Page error={error} tw="px-6 py-16 pb-24">
            <Header onGoBack={() => { navigate("/register/setPassword") }} title="Merchant Info"></Header>

            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput
                    label="No Handphone (WA aktif)"
                    required
                    asterisk="red"
                    placeholder="Ketik di sini"
                    name="phoneNumber"
                    type="tel"
                    autocomplete="tel"
                    value={phoneNumber}
                    onChange={(val) => { setPhoneNumber(val || ""); setPhoneNumberError("") }}
                    error={phoneNumberError}
                    tw="mb-4"
                />

                <TextInput
                    label="Nama"
                    required
                    asterisk="red"
                    placeholder="Ketik di sini"
                    name="fullName"
                    autocomplete="name"
                    value={fullName}
                    onChange={(val) => { setFullName(val || ""); setFullNameError("") }}
                    error={fullNameError}
                    tw="mb-4"
                />

                <TextInput
                    label="Tanggal Lahir"
                    required
                    asterisk="red"
                    placeholder="Pilih Tanggal"
                    name="dob"
                    type="date"
                    value={dob}
                    error={dobError}
                    onChange={(val) => { setDob(val); setDobError("") }}
                    tw="mb-4"
                />

                <TextInput
                    label="Nama Merchant"
                    required
                    asterisk="red"
                    placeholder="Ketik di sini"
                    name="merchantName"
                    value={merchantName}
                    error={merchantNameError}
                    onChange={val => { setMerchantName(val || ""); setMerchantNameError("") }}
                    tw="mb-5"
                />

                <p tw="font-semibold">Link tokomu di tempat lain (min.2)</p>
                <p id="socialMediaError" tabIndex={0} tw="text-red-700 text-xs mt-1">{socialMediaError}</p>
                <div tw="mb-2"></div>

                <TextInput
                    label={`www.instagram.com/${instagram}`}
                    placeholder="Ketik di sini"
                    name="instagramUsername"
                    value={instagram}
                    onChange={val => setInstagram(val || "")}
                    error={instagramError}
                    tw="mb-2"
                />

                <TextInput
                    label={`www.tokopedia.com/${tokopedia}`}
                    placeholder="Ketik di sini"
                    name="tokopediaUsername"
                    value={tokopedia}
                    onChange={val => setTokopedia(val || "")}
                    error={tokopediaError}
                    tw="mb-2"
                />

                <TextInput
                    label={`www.shopee.com/${shopee}`}
                    placeholder="Ketik di sini"
                    name="shopeeUsername"
                    value={shopee}
                    onChange={val => setShopee(val || "")}
                    error={shopeeError}
                    tw="mb-2"
                />

                <TextInput
                    label={`www.tiktok.com/${tiktok && `@${tiktok}`}`}
                    placeholder="Ketik di sini"
                    name="tiktokUsername"
                    value={tiktok}
                    onChange={val => setTiktok(val || "")}
                    error={tiktokError}
                    tw="mb-2"
                />

                <TextInput
                    label="lainnya"
                    placeholder="Ketik di sini"
                    name="otherUsername"
                    value={other}
                    onChange={val => setOther(val || "")}
                    error={otherError}
                    tw="mb-2"
                />

                <FloatingFooter>
                    <Button isLoading={isSubmitting} isDisabled={!isSubmittable} onClick={onSubmit}>Next</Button>
                </FloatingFooter>
            </form>
        </Page>
    )
}

export default withSnackbar(RegisterMerchantInfo)