import React from 'react';
import ChevronRight from '../../common/icons/chevron-right.svg';

export type ProductListInfoButtonProps = {
  onClick?: () => void;
  bulkCommission?: string | number;
};

function ProductListInfoButton(props: ProductListInfoButtonProps) {
  const {} = props;

  return (
    <div tw="w-full px-4">
      <button
        onClick={() => props.onClick?.()}
        tw="p-4 bg-white rounded-xl shadow flex flex-row justify-between w-full items-center">
        <div tw="flex-1 flex items-start">
          <span tw="font-bold">Bulk commission:</span>&nbsp;{props.bulkCommission}%
        </div>
        <ChevronRight tw="h-5 w-5 flex-shrink-0" />
      </button>
    </div>
  );
}

export default ProductListInfoButton;
