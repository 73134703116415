import React, { useEffect, useState } from "react";
import { formatPrice } from "../../../common/utils";
import CashoutBankAccount from "./cashout-card-bank-account";

type userBankAccountInfoModel = {
  bankId: string;
  accountNumber: string;
  fullName: string;
  npwpUrl: string;
  bankBranchName: string;
};

type cashoutSumarryResponse = {
  totalSettled: number;
  userBankAccountInfo: userBankAccountInfoModel;
  onSubmittableStatusChange: (status: boolean) => void;
};

function CashoutCasrdInfo(response: cashoutSumarryResponse) {
  const { totalSettled, userBankAccountInfo, onSubmittableStatusChange } =
    response;
  const [remainingAmount, setRemainingAmount] = useState<number>(totalSettled);
  const [amount, setAmount] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Remove non-numeric characters from the input value
    const numericValue = amount?.replace(/[^\d]/g, "");

    // Convert the numeric value to a number and format it with periods
    const formattedValue = parseInt(numericValue ?? "")
      .toLocaleString("en-US", {
        useGrouping: true,
      })
      .replace(/,/g, ".");

    setRemainingAmount(
      totalSettled - (parseInt(formattedValue.replace(/[.,]/g, "")) ?? 0)
    );
  }, [totalSettled]);

  function handleClickMax() {
    // Convert the numeric value to a number and format it with periods
    const formattedValue = totalSettled
      .toLocaleString("en-US", {
        useGrouping: true,
      })
      .replace(/,/g, ".");
    document.getElementById("inputAmount")?.focus();

    if (userBankAccountInfo != null && formattedValue != "0") {
      onSubmittableStatusChange(true);
    } else {
      onSubmittableStatusChange(false);
    }
    setAmount(formattedValue);
  }

  function onInputChange(newValue: string) {
    let newNumber = parseInt(newValue != "" ? newValue.replace(/[.,]/g, "") : "0");
    if (
      (newNumber <= totalSettled && totalSettled - newNumber >= 0) ||
      !newNumber
    ) {
      setRemainingAmount(
        totalSettled - (Number.isInteger(newNumber) ? newNumber : 0)
      );

      // Remove non-numeric characters from the input value
      const numericValue = newValue != "" ? newValue.replace(/[^\d]/g, "") : "0";

      // Convert the numeric value to a number and format it with periods
      const formattedValue = parseInt(numericValue)
        .toLocaleString("en-US", {
          useGrouping: true,
        })
        .replace(/,/g, ".");
      setAmount(formattedValue);
      if (userBankAccountInfo != null && formattedValue != "0") {
        onSubmittableStatusChange(true);
      } else {
        onSubmittableStatusChange(false);
      }
        
    }

  }

  return (
    <div
      tw="w-full bg-white rounded-xl px-4 py-4 flex flex-col gap-4 drop-shadow z-[100]"
      style={{ boxShadow: "0px 2px 16px 0px rgb(0, 0, 0, 0.07)" }}>
      <div>Cashout Info</div>
      <div tw="flex flex-col gap-2">
        <div tw="flex flex-col gap-2">
          <div tw="flex flex-row gap-2">
            <div tw="flex items-center text-sm">Rp</div>
            <input
              tw="border border-[#E4E4E4] rounded-xl h-10 flex-grow mr-1 py-[0.5625rem] px-4 text-sm font-normal focus:outline-none  focus:border-[#894CF5]"
              type="number"
              placeholder="Amount"
              value={parseInt(amount ?? "0") > 0 ? amount : undefined}
              onChange={(e) => onInputChange(e.target.value)}
              id="inputAmount"
              // onInput={ handleInputChange}
            />
            <button
              tw="bg-[#35373B] rounded-xl py-[0.53125rem] px-2 text-white font-normal text-sm"
              onClick={() => handleClickMax()}>
              Max
            </button>
          </div>

          <div tw="gap-2 flex flex-row">
            <div tw="w-[1.1875rem]"></div>
            <div tw="text-[#999] text-xs font-normal">
              Remaining{" "}
              {formatPrice(
                !Number.isNaN(remainingAmount) ? remainingAmount : totalSettled
              )}
            </div>
          </div>
        </div>

        {}

        {userBankAccountInfo ? (
          <CashoutBankAccount />
        ) : (
          <button
            tw="bg-[#894CF5] rounded-xl px-[4.9375rem] py-[#0.9375rem] h-[3.125rem] text-white text-xs font-normal"
            onClick={() => (window.location.href = "/form/bankAccount")}>
            + Bank account info
          </button>
        )}
      </div>
    </div>
  );
}

export default CashoutCasrdInfo;
