import React from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import Chip, {ChipProps} from './chip';

export type ChipCheckboxProps = {onCheckedChange?: () => void; value?: string} & ChipProps & Checkbox.CheckboxProps;

function ChipCheckbox(props: ChipCheckboxProps) {
  const {children, isChecked, isDisabled, onCheckedChange, value, ...rest} = props;

  return (
    <Checkbox.Root checked={isChecked} asChild onCheckedChange={onCheckedChange} {...rest}>
      <Chip tw="cursor-pointer" isChecked={isChecked}>
        {children}
      </Chip>
    </Checkbox.Root>
  );
}

export default ChipCheckbox;
