import React, { useEffect, useState } from "react";
import Button from "../../../../common/components/button/button";
import IconPlus from "../../../../common/icons/plus-white.svg"
import IconClose from "../../../../common/icons/close-black-8.svg"
import BottomSheet from "../../../../common/components/bottom-sheet/bottom-sheet";
import TextInput from "../../../../common/components/text-input/text-input";

type AddVariantFieldProps = {
    title: string;
    onChange?: (val: string[]) => void;
    values?: string[];
}

function AddVariantField(props: AddVariantFieldProps) {
    const { title, onChange = () => { } } = props

    const [sheetOpen, setSheetOpen] = useState(false)

    const [values, setValues] = useState(props.values)
    const [newValue, setNewValue] = useState("")

    // useEffect(() => {

    // }, [])

    // useEffect(() => {
    //     console.log("sheet", shouldShowSheet)
    // }, [shouldShowSheet])

    function handleSave() {
        let newValues = values?.concat(newValue) || []
        setValues(newValues)
        setNewValue("")
        setSheetOpen(false)
        onChange(newValues)
    }

    return (
        <div tw="bg-white p-4">
            <div tw="mb-3">
                <b>{title}</b>
            </div>

            {values?.length == 0 && <Button onClick={() => setSheetOpen(true)} shrink size="small">
                <IconPlus />&nbsp;
                <span>Add {title}</span>
            </Button>}

            {values?.length != 0 &&
                <div tw="flex flex-wrap overflow-hidden gap-2 mt-2">
                    {values?.map(value => (
                        <div tw="flex items-center rounded-[20px] bg-white border border-black">
                            <span tw="py-2 pl-3 text-sm">{value.replace(/_/g, " ")}</span>
                            <span
                                onClick={() => {
                                    let newValues = values.filter(val => val !== value)
                                    setValues(newValues)
                                    onChange(newValues)
                                }}
                                tw="py-2 pl-2 pr-3"><IconClose />
                            </span>
                        </div>
                    ))}
                    <Button onClick={() => setSheetOpen(true)} shrink size="small"><IconPlus /></Button>
                </div>
            }

            <BottomSheet
                isOpen={sheetOpen}
                onOpenChange={(open) => setSheetOpen(open)}
                onBackClick={() => setSheetOpen(false)}
                withHeader={true}
                title={`Add ${title.toLowerCase()}`}
                roundness={12}
                rightActions={[{
                    label: "Save",
                    type: "primary",
                    onClick: () => {
                        handleSave()
                    }
                }]}
            >
                <div tw="px-3 pb-5">
                    <form onSubmit={(event) => { event.preventDefault(); handleSave() }}>
                        <TextInput autoFocus placeholder={`Enter ${title.toLowerCase()} name`} value={newValue.replace(/_/g, " ")} onChange={(val) => { setNewValue(val?.replace(/ /g, "_") || "") }} />
                    </form>
                </div>
            </BottomSheet >
        </div >
    )
}

export default AddVariantField