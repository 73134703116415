import React, { useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import TextInput from "../../../common/components/text-input/text-input"
import { useNavigate } from "react-router-dom"
import { ValidateEmailTelp } from "../../../framework/utils"
import Button from "../../../common/components/button/button"
import { fepiAxios } from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"

function ForgotPasswordPage() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const [email, setEmail] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (email != "")
            setIsEmailValid(ValidateEmailTelp(email))
    }, [email])

    useEffect(() => {
        setIsSubmittable(isEmailValid && email !== "")
    }, [isEmailValid, email])

    function onSubmit() {
        if (!isSubmittable) {
            return
        }
        setIsSubmitting(true)
        fepiAxios.post("mobile/Account/ForgotPassword", {
            email: email,
            svc_id: '121'
        }).then(response => {
            setIsSubmitting(false)
            navigate("/loginSso")
        }).catch(error => {
            setIsSubmitting(false)
            snackbar.create(
                new Snackbar({
                    message: error.response.data.message,
                    type: 'danger',
                }),
            )
        })
    }

    return (
        <Page tw="p-6 pt-16">
            <Header title="Forgot Password" onGoBack={() => navigate("/loginSso")} />
            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput autocomplete="email" tw="mb-4" placeholder="Enter Email or Phone number" label="Email / Phone Number" value={email} onChange={(value: any) => setEmail(value)} error={!isEmailValid ? "Plese input valid email or phone number" : ""} type="email" name="email" required asterisk="red"/>
            </form>
            <Button onClick={() => onSubmit()} isLoading={isSubmitting} isDisabled={!isSubmittable}>Reset Password</Button>
        </Page>
    )
}

export default withSnackbar(ForgotPasswordPage)
