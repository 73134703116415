import React, { useEffect, useState } from "react";
import Page from "../../common/components/page/page";
import Header from "../../common/components/header/header";
import CashoutCard from "./components/cashout-card";
import CashoutCasrdInfo from "./components/cashout-card-info";
import { fetchCashoutSummary } from "./api";
import CashoutApplyButton from "./components/cashout-apply-butto";

function login() {
  window.localStorage.setItem(
    "fpAuth",
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZWFyZXIiLCJjbiI6MzkzNDAsImlhdCI6MTY5Mzg4MTk5MSwiZXhwIjoxNjk0NDg2NzkxfQ.sJzCdNMkRIc9GIVyjT7E1__D3ZmZr-ypGcfLmtcFK7c"
  );
}

function CashoutSummary() {
  const [cashoutSummary, setCashoutSummary] = useState({
    totalSettled: 0,
    minimumWithdraw: 0,
    userBankAccountInfo: {
      bankId: "",
      accountNumber: "",
      fullName: "",
      npwpUrl: "",
      bankBranchName: "",
    },
    cashoutWarning: "",
    cashoutInfo: "",
    listOfBanks: {
      label: "",
      id: "",
    },
  });

  const [isSubmite, setIssubmite] = useState(false);
  const [fpAuth, setFpaAuth] = useState("");

  useEffect(() => {
    fetchCashoutSummary().then((value) => setCashoutSummary(value));
    login();

    setFpaAuth(
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJiZWFyZXIiLCJjbiI6MzkzNDAsImlhdCI6MTY5Mzg4MTk5MSwiZXhwIjoxNjk0NDg2NzkxfQ.sJzCdNMkRIc9GIVyjT7E1__D3ZmZr-ypGcfLmtcFK7c"
    );
  }, []);

  function onSubmittableStatusChange(status: boolean): void {
    setIssubmite(status);
  }

  return (
    <Page tw="bg-[#F2F2F2]">
      <Header title="Cashout" bgTransparent={true} />
      <div tw="h-20" />
      <div tw="px-6 gap-6 flex flex-col">
        <CashoutCard totalSettled={cashoutSummary.totalSettled} />
        <CashoutCasrdInfo
          totalSettled={cashoutSummary.totalSettled}
          userBankAccountInfo={cashoutSummary.userBankAccountInfo}
          onSubmittableStatusChange={(status) =>
            onSubmittableStatusChange(status)
          }
        />
      </div>
      <div
        tw="bottom-0 fixed flex bg-white p-4 max-w-[500px] w-full"
        style={{ boxShadow: "0px 0px 8px rgba(153, 157, 169, 0.25)" }}>
        <CashoutApplyButton isSubmite={isSubmite} />
      </div>

      <div tw="h-20"></div>
      <div>{fpAuth}</div>
    </Page>
  );
}

export default CashoutSummary;
