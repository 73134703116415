import React, { useState } from "react";
import Page from "../../../common/components/page/page";
import Header from "../../../common/components/header/header";
import Sidebar from "../../../common/components/sidebar/sidebar";
import mcAxios from "../../../common/axios";
import IconAccount from "../../../common/icons/account.svg";
import { useNavigate } from "react-router-dom";

function AccountPage() {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
    const [profile, setProfile] = useState<any>({});
    const navigate = useNavigate()

    mcAxios.post("/merchant/Home").then((res) => {
        let data = res.data.data
        setProfile({
            imageUrl: data.profile.imageUrl,
            name: data.profile.name
        })
    })

    return (
        <Page shouldAuth={true} tw="pb-36 pt-12">
            <Header hasBurger onBurgerClicked={() => setIsSidebarExpanded(true)} title="Account">
            </Header>

            <div tw="flex flex-col gap-3 px-2 mt-2">
                <button onClick={() => navigate("/logout")} tw="flex px-3 py-2 gap-2 text-start">
                    <IconAccount tw="w-6" />
                    <span tw="flex-grow">Logout</span>
                </button>
            </div>

            <Sidebar
                isExpanded={isSidebarExpanded}
                onClosed={() => setIsSidebarExpanded(false)}
                selectedMenu="account"
                profileImageUrl={profile.imageUrl}
                profileName={profile.name}
            />
        </Page>
    )
}

export default AccountPage