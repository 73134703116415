import {BaseEntity} from './base';

export type BillingArgs = {
  items?: {
    label?: string;
    value?: number;
  }[];
  total?: number;
};

export class Billing extends BaseEntity {
  items?: {
    label?: string;
    value?: number;
  }[] = [];
  total?: number = 0;

  constructor(args: BillingArgs) {
    super();
    Object.assign(this, args);
  }

  static cast(args: any) {
    return new Billing({
      items: args.billingItems,
      total: args.total,
    });
  }
}
