import {isServer} from './utils';

export const StagingConfig = {
  MODE: 'staging',
  COMMON_UPLOAD_IMAGE_URL: 'https://manage.file.thefepi.com/app/UploadImage/common.php',
  API_ROOT_URL: 'https://staging.thefepi.com/appmc',
  FEPI_API_ROOT_URL: 'https://staging.thefepi.com/',
  CS_PHONE_NUMBER: '6282288886784',
  CS_PHONE_NUMBER_FORMATTED: '+62-822-8888-6784'
};

export const ProductionConfig = {
  MODE: 'production',
  COMMON_UPLOAD_IMAGE_URL: 'https://manage.file.thefepi.com/app/UploadImage/common.php',
  API_ROOT_URL: 'https://app.thefepi.com/appmc',
  FEPI_API_ROOT_URL: 'https://app.thefepi.com/',
  CS_PHONE_NUMBER: '6282288886784',
  CS_PHONE_NUMBER_FORMATTED: '+62-822-8888-6784'
};

function buildTypeForConfig(type: 'production' | 'staging') {
  switch (type) {
    case 'production':
      return ProductionConfig;
    case 'staging':
      return StagingConfig;
    default:
      return ProductionConfig;
  }
}

export function getRuntimeConfig() {
  return isServer() ? buildTypeForConfig(process.env.RAZZLE_BUILD_TYPE) : window.__APP_CONFIG__;
}
