import React from "react";
import IconChevrontRight from "../../../common/icons/chevron-right.svg";

type cashoutAppliedResponse = {
  date: string;
  totalAmount: string;
  name: string;
  bankAccount: string;
};

function WalletCashoutAppied(props: cashoutAppliedResponse) {
  const { name, bankAccount, date, totalAmount } = props;
  return (
    <div tw="w-full bg-[#F4F5FF] p-4 border border-[#894CF5] rounded-xl min-w-[18.375rem] max-w-[18.375rem] min-h-[8rem] max-h-[8rem] ">
      <div tw="flex flex-col gap-[0.375rem]">
        <div tw="flex flex-row justify-between">
          <div tw="grow whitespace-nowrap text-xs font-medium">
            Cashout applied
          </div>
          <div tw="text-end text-[0.625rem] font-normal text-[#999]">
            {date}
          </div>
        </div>
        <div tw="font-bold text-xl">{totalAmount}</div>
        <div tw="flex flex-row justify-between">
          <div tw="flex flex-col">
            <div tw="text-xs font-medium">{name}</div>
            <div tw="text-xs font-normal">{bankAccount}</div>
          </div>

          <div tw="flex flex-row items-center justify-center align-middle gap-1">
            <div tw="text-[0.625rem] font-light">View details</div>
            <IconChevrontRight tw="w-1 h-2" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletCashoutAppied;
