import React from 'react';
import tw from 'twin.macro';
import {useParams} from 'react-router-dom';
import Page from '../../common/components/page/page';
import Header from '../../common/components/header/header';
import {Order, OrderType} from '../../common/entities/order';
import CopyIcon from '../../common/icons/copy.svg';
import {useSnackbar, withSnackbar} from '../../common/components/snackbar/snackbar';
import {Snackbar} from '../../common/entities/snackbar';
import {Product} from '../../common/entities/product';
import {Billing} from '../../common/entities/billing';
import {formatPrice} from '../../common/utils';
import PulseLoader from '../../common/components/loaders/pulse';
import OrderTypeTag from './components/order-type-tag';
import useOrderDetails from './hooks/use-order-details';

function OrderDetailsPage() {
  const snackbar = useSnackbar();

  const {id} = useParams();

  const {data: order, isLoading: isLoadingOrder} = useOrderDetails(
    new Order({
      id: id!,
    }),
    {
      enabled: Boolean(id),
    },
  );

  return (
    <Page tw="pt-14 bg-gray-100">
      <Header title="Order Detail" />
      {!order || isLoadingOrder ? (
        <div tw="absolute flex items-center justify-center inset-0">
          <PulseLoader theme="light" />
        </div>
      ) : (
        <>
          {' '}
          <div tw="bg-white py-4 px-6 flex">
            <p tw="flex-1 mr-2 font-medium">Order #{order.id}</p>
            <button
              onClick={() => {
                snackbar.create(
                  new Snackbar({
                    message: `Order id copied!`,
                    type: 'normal',
                  }),
                );
              }}
              tw="flex-shrink-0">
              <CopyIcon tw="h-6 w-6" />
            </button>
          </div>
          <div tw="py-4 px-6 bg-white mt-2">
            <div tw="flex mb-3">
              <p tw="flex-1 text-xs text-gray-400">Order date</p>
              <div tw="flex-shrink-0 text-xs">{order.date}</div>
            </div>
            <div tw="flex mb-3">
              <p tw="flex-1 text-xs text-gray-400">Buyer{`'`}s name</p>
              <div tw="flex-shrink-0 text-xs flex items-center">
                <div tw="h-5 w-5 rounded-full object-cover overflow-hidden mr-1">
                  <img tw="w-full h-full" src={order.buyer?.profilePictureUrl} />
                </div>
                <p>{order.buyer?.name || '-'}</p>
              </div>
            </div>
            <div tw="flex">
              <p tw="flex-1 text-xs text-gray-400">Ship before</p>
              <div tw="flex-shrink-0 text-xs text-yellow-500 font-semibold">{order.shippingDeadline}</div>
            </div>
          </div>
          <div tw="mt-2 px-6 bg-white">
            {order.details?.map(detail => (
              <div key={detail.title}>
                <div tw="text-sm font-semibold py-4 border-b border-gray-150">{detail.title}</div>
                <div>
                  {detail.sections?.map(section => (
                    <div key={section.type}>
                      <div tw="pt-4 pb-3">
                        <OrderTypeTag type={section.type} />
                      </div>
                      <div tw="border-b border-gray-150 mb-3">
                        {section.products?.map(product => (
                          <div key={product.id} tw="flex pb-4">
                            <div tw="flex-shrink-0">
                              <div tw="w-[72px] h-24 rounded-xl overflow-hidden">
                                <img tw="h-full w-full  object-cover" src={product.imageUrls?.[0]} alt={product.name} />
                              </div>
                            </div>
                            <div tw="flex-1 ml-4">
                              <p tw="text-xs font-semibold leading-[18px]">{product.brand?.name}</p>
                              <p tw="text-xs mt-0.5 leading-[18px] h-9">{product.name}</p>
                              <p tw="text-xs mt-0.5 leading-[18px] text-gray-700">{product.variant}</p>
                              <p tw="text-xs mt-0.5 leading-[18px] text-gray-700">{product.weight}</p>
                              <p tw="text-xs mt-0.5 leading-[18px]">{formatPrice(product.price || 0)}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div tw="mt-2 px-6 bg-white pb-4">
            <div tw="text-sm font-semibold py-4 ">Delivery Details</div>
            <div tw="flex">
              <div tw="w-1/4 flex-shrink-0">
                <p tw="text-gray-750 text-xs">Courier</p>
                <div tw="flex text-gray-750 text-xs mt-3">
                  Address
                  <button
                    onClick={() => {
                      snackbar.create(
                        new Snackbar({
                          message: 'Address copied',
                          type: 'normal',
                        }),
                      );
                    }}>
                    <CopyIcon tw="w-4 h-4 ml-1" />
                  </button>
                </div>
              </div>
              <div tw="flex-1">
                <p tw="text-xs">{order.shippingAddress?.courierName}</p>
                <div tw="mt-3 text-xs">
                  <p tw="font-semibold text-xs leading-5">{order.shippingAddress?.name}</p>
                  <p tw="text-xs leading-5">{order.shippingAddress?.phoneNumber}</p>
                  <p tw="text-xs leading-5">{order.shippingAddress?.address}</p>
                </div>
              </div>
            </div>
          </div>
          <div tw="mt-2 px-6 bg-white pb-4">
            <div tw="text-sm font-semibold py-4 ">Total Price</div>
            <div tw=" border border-gray-200 rounded-xl px-4 py-6">
              <div tw="flex flex-col gap-3">
                {order.billing?.items?.map(item => (
                  <div key={item.label} tw="flex">
                    <div tw="flex-shrink-0 text-gray-750 text-sm">{item.label}</div>
                    <div tw="flex-1 px-4 flex items-center">
                      <div tw="flex-1 border-b border-gray-200 border-dashed"></div>
                    </div>
                    <div tw="flex-shrink-0 text-sm font-medium" css={[(item.value || 0) < 0 && tw`text-red-700`]}>
                      {formatPrice(item.value)}
                    </div>
                  </div>
                ))}
              </div>
              <div tw="border-b border-gray-150 mt-4 mb-5" />
              <div tw="flex">
                <div tw="flex-shrink-0 text-gray-750 text-sm">Total</div>
                <div tw="flex-1 px-4 flex items-center"></div>
                <div tw="flex-shrink-0 text-sm font-medium" css={[(order.billing?.total || 0) < 0 && tw`text-red-700`]}>
                  {formatPrice(order.billing?.total)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
}

export default withSnackbar(OrderDetailsPage);
