import {BaseEntity} from './base';
import {Billing} from './billing';
import {Product} from './product';

export const OrderType = Object.freeze({
  UNPAID: 'UNPAID',
  PAID: 'PAID',
  TO_SHIP: 'TO_SHIP',
  IN_DELIVERY: 'IN_DELIVERY',
  COMPLETED: 'COMPLETED',
  TO_PAY: 'TO_PAY',
  PACKING: 'PACKING',
  CANCELLED: 'CANCELLED',
});

export const OrderTypeLabel = Object.freeze({
  [OrderType.UNPAID]: 'Unpaid',
  [OrderType.PAID]: 'Paid',
  [OrderType.TO_SHIP]: 'To Ship',
  [OrderType.IN_DELIVERY]: 'In Delivery',
  [OrderType.COMPLETED]: 'Completed',
  [OrderType.CANCELLED]: 'Cancelled',
  [OrderType.PACKING]: 'Packing',
});

export type OrderStatus = keyof typeof OrderTypeLabel;

export type OrderType = keyof typeof OrderType;

export type OrderArgs = {
  id: string;
  buyer?: {
    name?: string;
    profilePictureUrl?: string;
  };
  type?: OrderType;
  date?: string;
  status?: OrderStatus;
  note?: string;
  badgeUrls?: string[];
  info?: string;
  details?: {
    title?: string;
    sections?: {
      type?: OrderType;
      products?: Product[];
      info?: string;
    }[];
  }[];
  bag?: {
    id: string;
  }[];
  subTotal?: number;
  products?: Product[];
  responseDeadline?: string;
  shippingDeadline?: string;
  misc?: {
    moreItemsLabel?: string;
    formattedOrderId?: string;
  };
  billing?: Billing;
  shippingAddress?: {
    name?: string;
    phoneNumber?: string;
    address?: string;
    courierName?: string;
  };
};

export class Order extends BaseEntity {
  id: string;
  buyer?: {
    name?: string;
    profilePictureUrl?: string;
  };
  billing?: Billing;
  shippingAddress?: {
    name?: string;
    phoneNumber?: string;
    address?: string;
    courierName?: string;
  };
  type: OrderType = OrderType.UNPAID;
  status?: OrderStatus;
  note?: string;
  badgeUrls?: string[];
  info?: string;
  subTotal?: number;
  products?: Product[];
  responseDeadline?: string;
  date?: string;
  details?: {
    title?: string;
    sections?: {
      type?: OrderType;
      products?: Product[];
      info?: string;
    }[];
  }[];
  shippingDeadline?: string;
  misc?: {
    formattedOrderId?: string;
    moreItemsLabel?: string;
  };
  bag?: {
    id: string;
  }[];

  constructor(args: OrderArgs) {
    super();
    this.badgeUrls = [];
    this.misc = {};
    this.bag = [];
    Object.assign(this, args);
  }

  static cast(args: any = {}) {
    const order = new Order({
      shippingAddress: {
        address: args.deliveryDetails?.address,
        name: args.deliveryDetails?.name,
        phoneNumber: args.deliveryDetails?.phoneNumber,
        courierName: args.deliveryDetails?.courier,
      },
      id: args.orderId,
      products: [],
      info: args.secondStatus,
      date: args.dateLabel,
      misc: {
        formattedOrderId: `#${args.orderId}`,
        moreItemsLabel: args.moreInfo,
      },
      subTotal: args.subTotal,
      details: args.orders?.map((order: any) => ({
        title: order.title,
        sections: order.items.map((item: any) => ({
          type: item.type,
          info: item.statusDetail,
          products: item.products.map(Product.cast),
        })),
      })),
      badgeUrls: args.badgeUrls,
      type: args.type,
      buyer: {
        name: args.buyerName,
        profilePictureUrl: args.buyerImageProfileUrl,
      },
      responseDeadline: args.respondDeadline,
      shippingDeadline: args.shipDeadline,
    });

    if (args.product) {
      order.products?.push(Product.cast(args.product));
    }

    return order;
  }
}
