import React from "react"
import Page from "../../../common/components/page/page"
import IconClock from "../../../common/icons/clock.svg"
import { getRuntimeConfig } from "../../../framework/config"

function RegisterWaitingPage() {

    return (
        <Page tw="px-6 py-16 pb-20">
            <div tw="px-2 flex flex-col gap-3 text-center text-sm items-center content-center">
                <IconClock />
                <div tw="bg-[rgba(87, 151, 255, 1)] text-white py-1 px-3 rounded-full">proses pengecekan</div>
                <p>Pendaftaranmu masih sedang dalam proses pengecekan.</p>
                <div tw="h-7"></div>
                <p tw="text-xs">Jika ada pertanyaan atau butuh bantuan, hubungi: {getRuntimeConfig().CS_PHONE_NUMBER_FORMATTED} (whatsapp)</p>
            </div>
        </Page>
    )
}

export default RegisterWaitingPage