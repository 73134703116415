import {useInfiniteQuery} from 'react-query';
import {Label} from '../../../common/entities';
import {FetchProductStockListOptions, fetchProductStockList} from '../api';
import {Stock} from '../../../common/entities/stock';

const useProductStockList = (
  options: FetchProductStockListOptions & {
    onSuccess?: (args: {variants: Stock[]}) => void;
  },
) => {
  const key = ['product-stock-list-' + options.productId];

  return useInfiniteQuery({
    keepPreviousData: true,
    queryKey: key,
    queryFn: async ({pageParam = 0}) => {
      const result = await fetchProductStockList({
        ...options,
        startIndex: pageParam * options.limit,
        productId: options.productId,
      });

      return {
        variants: result.data?.variants.map(Stock.cast) || [],
        total: result.data?.totalItems,
        __page: pageParam++,
      };
    },
    getNextPageParam: lastPage => {
      if (lastPage.__page * options.limit < (lastPage.total || 0)) {
        return lastPage.__page + 1;
      }
    },
  });
};

export default useProductStockList;
