import React from 'react';

import * as Label from '@radix-ui/react-label';
import tw from 'twin.macro';

export type FormSectionProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  label?: string;
  required?: boolean;
  isDisabled?: boolean;
};

function FormSection(props: FormSectionProps) {
  const {label, isDisabled, required, children, ...rest} = props;

  return (
    <div tw="w-full max-w-full" {...rest}>
      {label && (
        <Label.Root htmlFor={rest.id} tw="font-semibold text-sm ">
          {label}
          {required && '*'}
        </Label.Root>
      )}
      <div tw="mt-2">{children}</div>
    </div>
  );
}

export default FormSection;
