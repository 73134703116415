import $ from "jquery"
import React, { useEffect, useState } from "react"
import DraggableBottomSheet from "../../../common/components/dragableBottomsheet/DraggableBottomSheet"
import ArrowLeftIcon from "../../../common/icons/arrow-left.svg"
import SearchIcon from "../../../common/icons/search.svg"

type VariantSheetProps = {
    identifier: string;
    title?: string;
    searchPlaceholder?: string;
    options?: string[];
    values?: string[];
    onChange?: (val: string[]) => void;
    isShown: boolean;
    onDismiss?: (val: string[]) => void;
}

function VariantSheet(props: VariantSheetProps) {
    const { title, searchPlaceholder, options = [], values = [], onChange = () => { }, identifier, onDismiss = () => { } } = props

    // const [isShown, setIsShown] = useState(true)
    const [chosen, setChosen] = useState<string[]>([])
    const [keyword, setKeyword] = useState("")
    const [filteredOptions, setFilteredOptions] = useState<String[]>([])
    const [excludedOptionsCount, setExcludedOptionsCount] = useState(0)

    useEffect(() => {
        const newOptions = options.filter(option =>
            !values.includes(option) &&
            (option.toLowerCase().includes(keyword.toLowerCase()) || keyword === "")
        )
        setFilteredOptions(newOptions)
        let newExcludedOptionsCount = 1 + options.length - newOptions.length - values.length - (keyword.length > 0 ? 1 : 0)
        if(newExcludedOptionsCount<0){
            newExcludedOptionsCount = 0
        }
        setExcludedOptionsCount(newExcludedOptionsCount)
    }, [keyword, values])

    function handleChange(value: string) {
        let newValues = values
        if (newValues.includes(value)) {
            newValues = newValues.filter(newValue => newValue !== value)
        } else {
            newValues.push(value)
        }
        onChange(newValues)
    }

    return (
        <DraggableBottomSheet
            tw="max-w-[500px] mx-auto border"
            isShown={props.isShown}
            // blocking={false}
            onClosed={() => {
                console.log("closed")
                onChange([...values, ...chosen])
                onDismiss([...values, ...chosen])
            }}

        >
            <div tw="flex gap-3 px-6 pb-3 items-center">
                <div onClick={() => { onDismiss([...values, ...chosen]) }}><ArrowLeftIcon /></div>
                <b>{title}</b>
            </div>

            <div tw="flex px-4 py-3 items-center justify-center">
                <div tw="flex rounded-lg border border-neutral-200 overflow-hidden py-2 px-3 gap-2 items-center w-full">
                    <SearchIcon />
                    <input onChange={(e) => setKeyword(e.target.value)} tw="border-0 outline-none w-full" placeholder={searchPlaceholder} />
                </div>
            </div>

            <div tw="pb-2 px-4">
                {values.length > 0 &&
                    <>
                        <div tw="flex flex-col">
                            {values.map(value => (
                                <div onClick={() => handleChange(value)} tw="flex justify-between items-center py-2">
                                    <span onClick={() => $(`#${identifier}${value}`).trigger("click")} tw="flex-grow">{value.replace(/_/g, " ")}</span>
                                    <input id={`${identifier}${value}`} type="checkbox" checked />
                                </div>
                            ))}
                        </div>
                        <div tw="h-[1px] bg-neutral-200 my-2"></div>
                    </>
                }
                <div tw="flex flex-col">
                    {filteredOptions.map((option: string) => (
                        <div onClick={() => setChosen([...chosen, option])} tw="flex justify-between items-center py-2">
                            <span
                                onClick={() => $(`#${identifier}${option}`).prop("checked", true)
                                }
                                tw="flex-grow"
                            >{option.replace(/_/g, " ")}</span>
                            <input id={`${identifier}${option}`} type="checkbox" checked={chosen.includes(option)} />
                        </div>
                    ))}
                    {keyword.length > 0 && <div tw="flex justify-between items-center py-2"
                        onClick={() => {
                            handleChange(keyword.replace(" ", "_"))
                        }}
                    >
                        <b>Add "{keyword}"</b>
                    </div>}
                    {[...Array(excludedOptionsCount)].map((dummy: any) => (
                        <div tw="flex justify-between items-center py-2 invisible">
                            <span tw="flex-grow">Dummy</span>
                            <input type="checkbox" />
                        </div>
                    ))}
                </div>
            </div>
        </DraggableBottomSheet>
    )
}

export default VariantSheet