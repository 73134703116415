import {BrowserRouter} from 'react-router-dom';
import React, {useState} from 'react';
import {loadableReady} from '@loadable/component';
import {Hydrate, QueryClient, QueryClientProvider} from 'react-query';

import {hydrate} from 'react-dom';
import App from '../app';

const dehydratedState = window.__REACT_QUERY_STATE__;

const queryClient = new QueryClient();

loadableReady(() => {
  const rootNode = document.getElementById('app');

  if (!rootNode) return;

  hydrate(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <App />
        </Hydrate>
      </QueryClientProvider>
    </BrowserRouter>,
    rootNode,
  );
});

if (module.hot) {
  console.log('Hot reloading ...');
  module.hot.accept();
}
