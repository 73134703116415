import React from "react";
import tw from "twin.macro";

type ProgressBarProps = {
    total: number;
    current: number;
    title: string;
}

function ProgressBar(props: ProgressBarProps) {
    const { total, current, title } = props

    let bars = []

    for (let i = 0; i < total; i++) {
        bars.push(
            <div key={i} tw="h-[2px] flex-grow" css={i < current ? tw`bg-[rgba(252, 191, 7, 1)]` : tw`bg-neutral-200`}></div>
        )
    }

    return (
        <div>
            <p tw="mb-2"><b>{current} of {total}</b> {title}</p>
            <div tw="flex rounded gap-1">{bars}</div>
        </div>
    )
}

export default ProgressBar