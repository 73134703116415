import React, { useEffect, useState } from "react";
import Page from "../../common/components/page/page";
import Header from "../../common/components/header/header";
import { useNavigate } from "react-router-dom";
import BellIcon from "../../common/icons/bell-black.svg";
import GearIcon from "../../common/icons/gear.svg"
import ChevronRightIcon from "../../common/icons/chevron-right.svg"
import WalletCard, { WalletCardProps } from "../wallet/components/wallet-card";
import Button from "../../common/components/button/button";
import PlusIcon from '../../common/icons/plus-white.svg'
import Sidebar from "../../common/components/sidebar/sidebar";
import mcAxios from "../../common/axios";

function HomePage() {
    const navigate = useNavigate()

    const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
    const [wallet, setWallet] = useState<WalletCardProps>({});
    const [profile, setProfile] = useState<any>({});
    const [products, setProducts] = useState<any>({});
    const [orders, setOrders] = useState<any>({});

    useEffect(() => {
        mcAxios.post("/merchant/Home").then((res) => {
            let data = res.data.data
            setWallet({
                balance: data.wallet.balance,
                onHold: data.wallet.onHold,
                settled: data.wallet.cashOutAble
            })
            setProfile({
                imageUrl: data.profile.imageUrl,
                name: data.profile.name
            })
            setProducts({
                live: data.products.live,
                lowStock: data.products.lowStock,
                draft: data.products.draft,
                delisted: data.products.delisted
            })
            setOrders({
                paid: data.orders.paid,
                toShip: data.orders.toShip,
                inDelivery: data.orders.inDelivery
            })
        })
    }, [])

    return (
        <Page shouldAuth={true} tw="pb-36 pt-12">
            <Header hasBurger onBurgerClicked={() => setIsSidebarExpanded(true)} title="Merchant Center">
                {/* <div tw="flex-grow flex items-center justify-end">
                    <button tw="mr-3">
                        <BellIcon />
                    </button>
                    <button>
                        <GearIcon />
                    </button>
                </div> */}
            </Header>
            <div tw="w-full p-4 flex flex-col gap-4 bg-neutral-100 mb-[-0.375rem]">
                <div tw="flex flex-col gap-2 items-center">
                    <div tw="h-12 w-12 rounded-full overflow-hidden">
                        <img
                            tw="h-full w-full object-cover"
                            src={profile.imageUrl}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://images.app.thefepi.com/app/contents/945a520396bd5d48aac1c2608ad3b98b.jpeg.webp";
                            }}
                        />
                    </div>
                    <b>{profile.name}</b>
                </div>
                <div tw="w-full">
                    <WalletCard
                        balance={wallet.balance || "Rp0"}
                        onHold={wallet.onHold || "Rp0"}
                        settled={wallet.settled || "Rp0"}
                        hasChevron
                    />
                </div>
                <div></div>
            </div>
            <div tw="bg-white w-full p-6 rounded-t-xl flex flex-col gap-8"
            // css={{boxShadow: "0 -1px 2px 0 rgb(0 0 0 / 0.1)"}}
            >
                <div tw="flex flex-col gap-2 pb-3">
                    <div tw="flex justify-between items-center">
                        <b tw="text-xl">Products</b>
                        <button onClick={() => navigate("/products")}>
                            <ChevronRightIcon tw="h-6 mr-2" />
                        </button>
                    </div>
                    <div tw="grid grid-cols-4">
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{products.live || 0}</b>
                            <span tw="text-sm">Live</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{products.lowStock || 0}</b>
                            <span tw="text-sm">Low Stock</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{products.draft || 0}</b>
                            <span tw="text-sm">Draft</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{products.delisted || 0}</b>
                            <span tw="text-sm">Delisted</span>
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => navigate("/product/upload")} tw='bg-[#894CF5] border-0 shadow' shrink radius="999px">
                            <PlusIcon />
                            &nbsp;
                            Upload Product
                        </Button>
                    </div>
                </div>
                <div tw="flex flex-col gap-2 pb-3">
                    <div tw="flex justify-between items-center">
                        <b tw="text-xl">Orders</b>
                        <button onClick={() => navigate("/orders")}>
                            <ChevronRightIcon tw="h-6 mr-2" />
                        </button>
                    </div>
                    <div tw="grid grid-cols-4">
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{orders.paid || 0}</b>
                            <span tw="text-sm">Paid</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{orders.toShip || 0}</b>
                            <span tw="text-sm">To Ship</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{orders.inDelivery || 0}</b>
                            <span tw="text-sm">In Delivery</span>
                        </div>
                        <div tw="flex flex-col gap-1 py-2 items-center justify-center flex-grow">
                            <b tw="text-2xl">{orders.completed || 0}</b>
                            <span tw="text-sm">Completed</span>
                        </div>
                    </div>
                </div>
            </div>
            <Sidebar
                isExpanded={isSidebarExpanded}
                onClosed={() => setIsSidebarExpanded(false)}
                selectedMenu="home"
                profileImageUrl={profile.imageUrl}
                profileName={profile.name}
            />
        </Page>
    )
}

export default HomePage