export const loginPageStyle = `
.btnFacebook {
    width: 100%;
    height: 60px;
    background: white;
    color: black;
    font-weight: bold;
    border: 1px solid #35373B;
    text-align: center;
    display: inline-block;
    border-radius: 12px;
}

.fa-facebook {
    float: left;
    margin-left: 25px;
    font-size: 20px !important;
}

.btnFacebook i {
    color: #3b5998;
}
`