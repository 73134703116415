import {BaseEntity} from './base';
import {Label} from './label';

export type ProductArgsV2 = {
  imageUrls?: string[];
  imageUrl: string;
  id: string;
  name?: string;
  // brand?: {
  //   id?: string;
  //   name?: string;
  // };
  brand?: string;
  price?: number;
  discount?: number;
  variant?: string;
  singleCommissionValue?: number;
  bulkCommissionValue?: number;
  commisionType?: 'BULK' | 'SINGLE';
  categoryLabel?: string;
  categories?: Label[];
  subCategories?: Label[];
  groups?: Label[];
  weight?: number;
  description?: string;
  measurements?: {
    label?: string;
    items?: string[];
  }[];
  fabric?: {
    name?: string;
    details?: {
      label?: string;
      items?: {
        label?: string;
        isSelected?: boolean;
      }[];
    }[];
  };
  tags?: {
    isOptional?: boolean;
    maxSelection?: number;
    label?: string;
    items?: Label[];
  }[];
  badgeUrl?: string;
  view?: number;
  like?: number;
  purchase?: number;
  commision?: number;
};

export class ProductV2 extends BaseEntity {
  imageUrls?: string[];
  imageUrl: string;
  name?: string;
  // brand?: {
  //   id?: string;
  //   name?: string;
  // };
  brand?: string;
  id: string;
  price?: number;
  discount?: number;
  singleCommissionValue?: number;
  bulkCommissionValue?: number;
  commisionType?: 'BULK' | 'SINGLE';
  categoryLabel?: string;
  categories?: Label[];
  subCategories?: Label[];
  groups?: Label[];
  weight?: number;
  variant?: string;
  description?: string;
  measurements?: {
    label?: string;
    items?: string[];
  }[];
  fabric?: {
    name?: string;
    details?: {
      label?: string;
      items?: {
        label?: string;
        isSelected?: boolean;
      }[];
    }[];
  };
  tags?: {
    isOptional?: boolean;
    maxSelection?: number;
    label?: string;
    items?: {
      id?: string;
      label?: string;
      isSelected?: boolean;
    }[];
  }[];
  badgeUrl?: string;
  view?: number;
  like?: number;
  purchase?: number;
  commision?: number;

  constructor(args: ProductArgsV2) {
    super();
    Object.assign(this, args);
  }

  static cast(args: any) {
    const product = new ProductV2({
      id: args.productId,
      groups: [],
      // brand: {
      //   name: args.brand.name,
      //   id: args.brand.id,
      // },
      brand: args.brand,
      subCategories: [],
      categories: [],
      weight: args.weight,
      price: args.price,
      tags: args.tags?.map((tag: any) => ({
        ...tag,
        items: tag.items?.map(Label.cast),
      })),
      imageUrl: args,
      name: args.name,
      variant: args.attribute,
      badgeUrl: args.badgeUrl,
      view: args.view,
      like: args.like,
      purchase: args.purchase,
      commision: args.commision,
    });

    if (args.imageUrl) {
      // product.imageUrls = [args.imageUrl];
      product.imageUrl = args.imageUrl;
    } else if (args.imageUrls) {
      // product.imageUrls = args.imageUrls;
      product.imageUrl = args.imageUrls;
    }

    return product;
  }
}
