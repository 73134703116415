import React, { useState } from 'react';
import ChevronLeftIcon from "../../icons/chevron-left.svg"
import HomeWhite from "../../icons/home-white.svg"
import HomeBlack from "../../icons/home-black.svg"
import ProductsWhite from "../../icons/products-white.svg"
import ProductsBlack from "../../icons/products-black.svg"
import OrdersWhite from "../../icons/orders-white.svg"
import OrdersBlack from "../../icons/orders-black.svg"
import AccountWhite from "../../icons/account-white.svg"
import AccountBlack from "../../icons/account-black.svg"
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
    isExpanded: boolean;
    onClosed: () => void;
    children?: React.ReactNode;
    selectedMenu: 'home' | 'products' | 'orders' | 'account'
    profileImageUrl: string;
    profileName: string;
}

function Sidebar(props: SidebarProps) {
    const { isExpanded, onClosed, selectedMenu } = props
    const navigate = useNavigate()

    return (
        <div
            tw='absolute top-0 left-0 h-screen overflow-y-auto w-full z-10 flex'
            css={!isExpanded && { width: 0 }}
        >
            <div tw='transition-all bg-white ease-in-out px-4 z-50 max-w-[60%]'>
                <div tw='h-12 flex items-center'>
                    <button
                        type="button"
                        className="absolute top-0 right-0 p-4"
                        onClick={onClosed}
                    >
                        <ChevronLeftIcon tw="mr-4 w-2" />
                    </button>
                </div>
                {/* <div className="pt-4 px-4">{props.children}</div> */}
                <div tw='flex flex-col gap-4'>
                    <div tw="h-12 w-12 rounded-full overflow-hidden">
                        <img
                            tw="h-full w-full object-cover"
                            src={props.profileImageUrl}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = "https://images.app.thefepi.com/app/contents/945a520396bd5d48aac1c2608ad3b98b.jpeg.webp";
                            }}
                        />
                    </div>
                    <b>{props.profileName}</b>
                    <div tw='flex flex-col gap-4'>
                        <div>
                            <button
                                onClick={() => navigate("/")}
                                tw='rounded-full flex py-1 px-3 box-content gap-1'
                                css={selectedMenu === "home" && { color: "white", fontWeight: "bold", backgroundColor: "rgba(137, 76, 245, 1)" }}
                            >
                                {selectedMenu === "home" ? <HomeWhite /> : <HomeBlack />}
                                <span>Home</span>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => navigate("/products")}
                                tw='rounded-full flex py-1 px-3 box-content gap-1'
                                css={selectedMenu === "products" && { color: "white", fontWeight: "bold", backgroundColor: "rgba(137, 76, 245, 1)" }}
                            >
                                {selectedMenu === "products" ? <ProductsWhite /> : <ProductsBlack />}
                                <span>Products</span>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => navigate("/orders")}
                                tw='rounded-full flex py-1 px-3 box-content gap-1'
                                css={selectedMenu === "orders" && { color: "white", fontWeight: "bold", backgroundColor: "rgba(137, 76, 245, 1)" }}
                            >
                                {selectedMenu === "orders" ? <OrdersWhite /> : <OrdersBlack />}
                                <span>Orders</span>
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => navigate("/account")}
                                tw='rounded-full flex py-1 px-3 box-content gap-1'
                                css={selectedMenu === "account" && { color: "white", fontWeight: "bold", backgroundColor: "rgba(137, 76, 245, 1)" }}
                            >
                                {selectedMenu === "account" ? <AccountWhite /> : <AccountBlack />}
                                <span>Account</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                tw='bg-black opacity-50 z-10 flex-grow'
                onClick={onClosed}
            >
            </div>
        </div>
    );
};

export default Sidebar;
