import React, { useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import { Link, useNavigate } from "react-router-dom"
import TextInput from "../../../common/components/text-input/text-input"
import FloatingFooter from "../../../common/components/floating-footer/floating-footer"
import Button from "../../../common/components/button/button"
import { getLocalStorageObject, setLocalStorageObject } from "../../../framework/utils"
import tw from "twin.macro"
import registrationConstants from "./constants"
import { fepiAxios } from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"

function RegisterVerifyEmailPage() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const formData = getLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER)

    const [code, setCode] = useState("")
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [codeError, setCodeError] = useState("")
    const [timeLeftToResend, setTimeLeftToResend] = useState(0)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (!formData?.email) {
            navigate("/register")
        }
    }, [])

    useEffect(() => {
        setIsSubmittable(!!code)
        setCodeError("")
    }, [code])

    useEffect(() => {
        if (timeLeftToResend > 0) {
            setTimeout(() => setTimeLeftToResend(timeLeftToResend - 1), 1000)
        }
    }, [timeLeftToResend])

    function onSubmit() {
        if (!isSubmittable)
            return
        // Debug
        // setCodeError("Invalid code")
        // return

        // Do api call here
        setIsSubmitting(true)
        fepiAxios.post("mobile/Account/Verification", {
            input_email_telp: formData.email,
            verification_code: code,
            svc_id: '121'
        }).then(response => {
            formData.otp = code
            setLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER, formData)
            navigate("/register/setPassword")
        }).catch((error) => {
            setIsSubmitting(false)
            snackbar.create(new Snackbar({
                message: error.response.data.message,
                type: "danger"
            }))
        })
    }

    function onResendCode() {
        setTimeLeftToResend(60)
        fepiAxios.post("mobile/Account/Resend", {
            input_email_telp: formData.email,
            svc_id: '121'
        }).then(response => {

        }).catch(error => {
            setTimeLeftToResend(0)
            snackbar.create(new Snackbar({
                message: error.response.data.message,
                type: "danger"
            }))
        })
    }

    return (
        <Page tw="px-6 py-16 pb-20">
            <Header onGoBack={() => navigate("/register")} title="Sign Up"></Header>

            <h1 tw="font-bold mb-5">Please enter the code sent to {formData.email}</h1>

            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput
                    label="Code"
                    type="number"
                    placeholder="Ketik di sini"
                    value={code}
                    onChange={(val) => setCode(val || "")}
                    required
                    tw="mb-5"
                    error={codeError}
                    asterisk="red"
                />
            </form>

            {
                timeLeftToResend > 0 ?
                    <p tw="text-sm">Wait {timeLeftToResend} second(s) before resend code</p>
                    : <p tw="text-sm">Didn't receive code?</p>
            }
            <b onClick={() => onResendCode()} tw="underline text-sm" css={timeLeftToResend > 0 ? tw`text-[#C0C0C0]` : ''}>Resend Code</b>

            <FloatingFooter>
                <Button isLoading={isSubmitting} onClick={onSubmit} isDisabled={!isSubmittable}>Verify</Button>
            </FloatingFooter>
        </Page>
    )
}

export default withSnackbar(RegisterVerifyEmailPage)