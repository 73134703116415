import React, { useEffect } from "react"
import $ from "jquery"
import LoadingWidget from "../../../common/components/LoadingWidget";

type CategoryRadioGroupProps = {
    identifier: string;
    value?: string;
    options?: string[];
    onChange?: (val: string) => void;
    expand?: boolean;
    isLoading?: boolean;
    columns?: number;
    withDividerLine?: boolean;
    fontSize?: "normal" | "small"
}

function CategoryRadioGroup(props: CategoryRadioGroupProps) {
    const { identifier, value, options = [], onChange = (val: string) => { }, expand = true, isLoading = false, columns = 2, withDividerLine = true, fontSize = "normal" } = props

    useEffect(() => {
        // if (!!value) {
        //     $(`#${identifier}${value}Radio`).trigger("click")
        // }
    }, [])

    function handleChange(value: string) {
        onChange(value)
    }

    return (
        <>
            {withDividerLine && <div tw="w-full bg-neutral-200 h-[1px] mt-3 mb-3"></div>}
            {isLoading ? <LoadingWidget /> :
                <fieldset id={`${identifier}RadioGroup`} tw="grid gap-1" css={[!expand && { display: "none" }, {gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`}]}>
                    {options.map((option => (
                        <div
                            tw="flex gap-2 items-center"
                            onClick={() => handleChange(option || "")}
                            css={fontSize === "small" && { fontSize: "14px" }}
                        >
                            <input
                                name={`${identifier}RadioGroup`}
                                type="radio"
                                id={`${identifier}${option}Radio`}
                                checked={option === value}
                            />
                            <span
                                tw="flex-grow"
                                onClick={() => $(`#${identifier}${option}Radio`).trigger("click")}
                            >{option.replace(/_/g, " ")}</span>
                        </div>
                    )))}
                </fieldset>
            }
        </>
    )
}

export default CategoryRadioGroup