import React, { useEffect, useState } from "react";
import Page from "../../../common/components/page/page";
import Header from "../../../common/components/header/header";
import IconArrowDown from "../../../common/icons/bt-arrow-down.svg";
import IconPlus from "../../../common/icons/small-plus.svg";
import DraggableBottomSheet from "../../../common/components/dragableBottomsheet/DraggableBottomSheet";
import $ from "jquery";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styled from "styled-components";

function FormBankAccount() {
  const [showSheet, setShowSheet] = useState(false);
  const [animateOutSheet, setAnimateOutSheet] = useState(false);
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [file, setFile] = useState<{
    exists?: boolean;
    name?: string;
    file?: File;
  }>();
  const [urlFile, setUrlFile] = useState("");
  const [crop, setCrop] = useState<Crop>();
  const [src, setSrc] = useState<string | null | ArrayBuffer>(null);
  const [log, setLog] = useState("");

  useEffect(() => {
    setIsSubmittable(file?.exists || false);
  }, [file]);

  function openBottomSheet() {
    setLog("button clicked");
    setShowSheet(true);
  }

  const DraggableBottomSheetItem = styled.button`
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  `;

  const handleCloseBottomSheet = () => {
    setAnimateOutSheet(true);
    setTimeout(() => {
      setShowSheet(false);
      setAnimateOutSheet(false);
    }, 500);
  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setFile({ exists: true, name: selectedFile.name, file: selectedFile });
    setUrlFile(URL.createObjectURL(event.target.files[0]));
    setShowSheet(false);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target?.result === "string") {
          setSrc(e.target.result);
        } else {
          // Handle other types of results, such as ArrayBuffer
          console.error("Unsupported result type:", typeof e.target?.result);
        }
      };
      reader.onerror = (e) => {
        console.error("Error reading the file:", e.target?.error);
      };
      reader.readAsDataURL(selectedFile);

      console.log(reader.readAsDataURL(selectedFile));
    }
  };

  const handleUnselectFile = () => {
    setFile({ exists: false, name: "" });
    $("#fileInput").val("");
    $("#captureInput").val("");
  };

  return (
    <Page>
      <Header tw="text-base font-medium" title="Bank account info" />
      <div tw="h-12" />
      <div tw=" w-full p-6">
        <div tw="flex flex-col gap-4">
          <p tw="text-sm font-normal">
            Please enter your bank account information to transfer your balance.
          </p>
          <div tw="relative">
            <select
              name=""
              id=""
              tw="w-full px-3 py-[0.5625rem] pr-8 rounded-lg border border-[#E4E4E4] focus:border-[#000] focus:outline-none text-sm font-normal appearance-none">
              <option value="">bank1</option>
              <option value="">bank2</option>
              <option value="">bank1</option>
              <option value="">bank2</option>
            </select>
            <IconArrowDown tw="absolute right-2 top-1/2 transform -translate-y-1/2" />
          </div>
          <input
            type="text"
            tw="w-full px-3 py-[0.5625rem] pr-8 rounded-lg border border-[#E4E4E4] focus:border-[#000] focus:outline-none text-sm font-normal"
            placeholder="Bank Account Number"
          />
          <input
            type="text"
            tw="w-full px-3 py-[0.5625rem] pr-8 rounded-lg border border-[#E4E4E4] focus:border-[#000] focus:outline-none text-sm font-normal"
            placeholder="Bank Branch"
          />

          <input
            type="text"
            tw="w-full px-3 py-[0.5625rem] pr-8 rounded-lg border border-[#E4E4E4] focus:border-[#000] focus:outline-none text-sm font-normal"
            placeholder="Full Name"
          />

          {urlFile ? (
            <img
              tw="object-cover h-[8.875rem] items-center  flex flex-col  gap-2  justify-center text-center align-middle rounded-xl border-dashed border border-gray-300 focus:border-blue-500 focus:outline-none"
              src={urlFile}
            />
          ) : (
            <div
              tw="h-[8.875rem] items-center  flex flex-col  gap-2  justify-center text-center align-middle rounded-xl border-dashed border border-gray-300 focus:border-blue-500 focus:outline-none"
              onClick={() => openBottomSheet()}>
              <IconPlus />
              <div
                tw="w-full font-normal text-[0.75rem] text-[#999999]"
                onClick={openBottomSheet}>
                Tap here to upload your NPWP card photo
              </div>
            </div>
          )}
          <div>{log}</div>
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="fileInput"
            type="file"
            onChange={handleFileChange}
          />
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            capture="environment"
            id="captureInput"
            onChange={handleFileChange}></input>
          {showSheet && (
            <DraggableBottomSheet
              isShown={showSheet}
              onClosed={() => handleCloseBottomSheet()}
              animateOut={animateOutSheet}>
              <div className="DraggableBottomSheetContents">
                <DraggableBottomSheetItem
                  className="DraggableBottomSheetItem"
                  onClick={() => $("#captureInput").trigger("click")}>
                  <p>Take photo</p>
                </DraggableBottomSheetItem>
                <DraggableBottomSheetItem
                  className="DraggableBottomSheetItem"
                  onClick={() => $("#fileInput").trigger("click")}>
                  <p>Library</p>
                </DraggableBottomSheetItem>
                <DraggableBottomSheetItem
                  className="DraggableBottomSheetItem"
                  onClick={() => setShowSheet(false)}>
                  <p>Cancel</p>
                </DraggableBottomSheetItem>
              </div>
            </DraggableBottomSheet>
          )}
        </div>
      </div>
    </Page>
  );
}

export default FormBankAccount;
