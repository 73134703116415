import React, {useState, useEffect} from 'react';
import tw from 'twin.macro';
import BottomSheet from '../../../../common/components/draggable-bottom-sheet/draggable-bottom-sheet';
import IconBlock from '../../../../common/icons/block.svg';
import IconRubbishBin from '../../../../common/icons/rubbish-bin.svg';
import IconClose from '../../../../common/icons/close.svg';

type ProductMoreOptionsSheetProps = {
  productId: string;
  isShown?: boolean;
  // onBackgroundClick?: () => void;
  onClosed?: () => void;
  onDelistProduct?: () => void;
  onDeleteProduct?: () => void;
  onCancel?: () => void;
};

function ProductMoreOptionsSheet(props: ProductMoreOptionsSheetProps) {
  const [isOpen, setIsOpen] = useState(props.isShown || false);

  const close = () => setIsOpen(false);

  useEffect(() => {
    if (!isOpen) {
      props.onClosed?.();
    }
  }, [isOpen]);

  return (
    <>
      <div
        id="MoreOptionsSheetBackground"
        tw="top-0 bottom-0 left-0 right-0 absolute bg-[#0000004d]"
        css={props.isShown ? tw`z-10` : tw`hidden`}
      />
      <BottomSheet
        styles={{
          bottomSheet: {width: 'inherit', zIndex: '20'},
          backdrop: {opacity: '0'},
          dragIndicator: {
            wrap: {paddingTop: '10px', paddingBottom: '12px'},
            indicator: {height: '4px', width: '60px', borderRadius: '2px', backgroundColor: '#E4E4E4'},
          },
          window: {wrap: {borderRadius: '24px 24px 0 0', maxWidth: '500px'}, content: {paddingBottom: '6px'}},
        }}
        isOpen={isOpen}
        close={close}>
        <div id="ProductMoreOptionsSheetContents" tw="text-sm text-black">
          <button
            id="ProductMoreOptionsSheetOption"
            onClick={() => props.onDelistProduct?.()}
            tw="px-6 py-2 h-12 w-full flex flex-row items-center">
            <IconBlock />
            <div tw="w-2"></div>
            <p tw="pt-[1px]">Delist</p>
          </button>
          <button
            id="ProductMoreOptionsSheetOption"
            onClick={() => props.onDeleteProduct?.()}
            tw="px-6 py-2 h-12 w-full flex flex-row items-center">
            <IconRubbishBin />
            <div tw="w-2"></div>
            <p tw="pt-[1px]">Delete</p>
          </button>
          <button
            id="ProductMoreOptionsSheetOption"
            onClick={() => setIsOpen(false)}
            tw="px-6 py-2 h-12 w-full flex flex-row items-center">
            <IconClose />
            <div tw="w-2"></div>
            <p tw="pt-[1px]">Cancel</p>
          </button>
        </div>
      </BottomSheet>
    </>
  );
}

export default ProductMoreOptionsSheet;
