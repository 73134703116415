import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import ProgressBar from "./components/progress-bar"
import VariantSheet from "./components/variant-sheet"
import { AddProductLocalStorageAddress } from "./constants"
import VariantField from "./components/variant-field"
import mcAxios from "../../common/axios"
import { normalizeAddProductParameters } from "./components/product-upload/api"

const colorOptionsTemplate = ["Black", "Grey", "Blue", "Dark_Green", "Green", "Maroon", "Navy", "Pale_Blue", "Red", "Sage_Green", "White"]
const sizeOptionsTemplate = ["XS", "S", "M", "L", "XL"]
const typeOptionsTemplate = ["Short_sleeved", "Long_sleeved"]

function UploadProductPage4() {

    const navigate = useNavigate()

    const [product, setProduct] = useState({
        ...getLocalStorageObject(AddProductLocalStorageAddress),
        colors: []
        // colors: ["Black", "Maroon"],
        // sizes: ["S", "M", "L"],
        // types: ["Short_sleeved"],
    })
    const [errors, setErrors] = useState({

    })
    const [showSheet, setShowSheet] = useState(false)
    const [sheetContext, setSheetContext] = useState("color")
    const [colorOptions, setColorOptions] = useState<string[]>([])
    const [sizeOptions, setSizeOptions] = useState<string[]>([])
    const [typeOptions, setTypeOptions] = useState<string[]>([])

    // useEffect(() => {
    //     console.log("colorOptions",colorOptions)
    // },[colorOptions])

    useEffect(() => {
        let newProduct = { ...product, ...getLocalStorageObject(AddProductLocalStorageAddress) }
        setColorOptions([...new Set(colorOptionsTemplate.concat((newProduct?.colors || [])))])
        setSizeOptions([...new Set(sizeOptionsTemplate.concat((newProduct?.sizes || [])))])
        setTypeOptions([...new Set(typeOptionsTemplate.concat((newProduct?.types || [])))])
        setProduct(newProduct)
        // handleSizeChange(product.sizes)
    }, [])

    function handleSizeChange(sizes: string[]) {
        // Sort size
        let newSizes = []
        for (let sizeOption of sizeOptions) {
            if (sizes.includes(sizeOption)) {
                newSizes.push(sizeOption)
            }
        }
        for (let size of sizes) {
            if(!sizeOptions.includes(size)){
                sizeOptions.push(size)
                newSizes.push(size)
            }
        }
        // console.log("newSizes", newSizes)  
        setProduct({ ...product, sizes: newSizes })
    }

    function handleSaveAsDraft() {
        mcAxios.post("/merchant/Product/Add", {
            type: "DRAFT",
            ...normalizeAddProductParameters(product)
        }).then((res)=>{
            removeLocalStorageObject(AddProductLocalStorageAddress)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', '\"DRAFT\"')
            navigate(`/products`)
        })
    }

    function handleSubmit() {
        // console.log(productFormData)
        let newProduct = {...product}
        if(!newProduct.sizes){
            newProduct.sizes = ["All"]
        }
        // console.log("newProduct before submit", newProduct)
        setLocalStorageObject(AddProductLocalStorageAddress, newProduct)
        navigate("/product/upload/5")
    }

    return (
        <>
            <Page tw="px-6 py-14 pb-20">
                <Header onGoBack={() => navigate("/product/upload/3")} title="Product Detail"></Header>
                <ProgressBar tw="mb-6" title="Variant" current={4} total={6} />
                <div tw="h-5"></div>
                <VariantField
                    label="Color"
                    values={product.colors}
                    onChange={(val) => {
                        let newColors = [...colorOptions]
                        for(let color of val){
                            if(!colorOptions.includes(color)){
                                newColors.push(color)
                            }
                        }
                        setColorOptions(newColors)
                        setProduct({ ...product, colors: val })
                    }}
                    onPlusButtonClicked={() => { setSheetContext("color"); setShowSheet(true) }}
                    tw="mb-6"
                />
                <VariantField
                    label="Size"
                    values={product.sizes}
                    onChange={(val) => {
                        let newSizes = [...sizeOptions]
                        for(let size of val){
                            if(!sizeOptions.includes(size)){
                                newSizes.push(size)
                            }
                        }
                        setSizeOptions(newSizes)
                        handleSizeChange(val)}
                    }
                    onPlusButtonClicked={() => { setSheetContext("size"); setShowSheet(true) }}
                    tw="mb-6"
                />
                {/* <VariantField
                    label="Type"
                    values={product.types}
                    onChange={(val) => {
                        let newTypes = [...typeOptions]
                        for(let type of val){
                            if(!typeOptions.includes(type)){
                                newTypes.push(type)
                            }
                        }
                        setTypeOptions([...newTypes])
                        setProduct({ ...product, types: val })}
                    }
                    onPlusButtonClicked={() => { setSheetContext("type"); setShowSheet(true) }}
                    tw="mb-6"
                /> */}
                {/* <Button onClick={() => setShowSheet(true)}>Show sheet</Button> */}
                <FloatingFooter tw="flex gap-3">
                    <Button onClick={handleSaveAsDraft} variant="outline">Save as draft</Button>
                    <Button onClick={handleSubmit}>Next</Button>
                </FloatingFooter>
            </Page >
            {showSheet &&
                <VariantSheet
                    isShown={showSheet}
                    title={sheetContext === "color" ? "Add Color" : sheetContext === "size" ? "Add Size" : "Add Type"}
                    identifier={sheetContext === "color" ? "productColor" : sheetContext === "size" ? "productSize" : "productType"}
                    searchPlaceholder={sheetContext === "color" ? "Search Color" : sheetContext === "size" ? "Search Size" : "Search Type"}
                    values={sheetContext === "color" ? product.colors : sheetContext === "size" ? product.sizes : product.types}
                    options={sheetContext === "color" ? colorOptions : sheetContext === "size" ? sizeOptions : typeOptions}
                    onChange={(val) => {
                        if (sheetContext === "color") {
                            setProduct({ ...product, colors: val })
                        } else if (sheetContext === "size") {
                            handleSizeChange(val)
                        } else if (sheetContext === "type") {
                            setProduct({ ...product, types: val })
                        }
                    }}
                    onDismiss={(val) => {
                        if (sheetContext === "color") {
                            setProduct({ ...product, colors: val })
                        } else if (sheetContext === "size") {
                            handleSizeChange(val)
                        } else if (sheetContext === "type") {
                            setProduct({ ...product, types: val })
                        }
                        setTimeout(() => setShowSheet(false), 10)
                    }}
                />
            }
        </>
    )
}

export default UploadProductPage4