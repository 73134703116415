import * as Dialog from '@radix-ui/react-dialog';
import React, { forwardRef, memo, useCallback } from 'react';
import { animated, easings, useTransition } from '@react-spring/web';
import tw from 'twin.macro';
import { NOOP } from '../../constants';
import ChevronLeft from '../../../common/icons/chevron-left.svg';

export type BottomSheetHeaderProps = {
  title?: string;
  rightActions?: {
    label?: string;
    type?: 'primary' | 'secondary';
    onClick?: () => void;
  }[];
  renderRightActions?: () => React.ReactNode;
  mode?: 'fullpage' | 'normal';
  roundness?: number;
  onBackClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export type BottomSheetProps = {
  children?: React.ReactNode;
  isOpen?: boolean;
  withHeader?: boolean;
  renderTrigger?: () => React.ReactNode;
} & Dialog.DialogProps &
  BottomSheetHeaderProps;

function BottomSheet(props: BottomSheetProps, ref: any) {
  // const x = useSprint()
  const {
    children,
    defaultOpen = false,
    withHeader = true,
    title,
    onBackClick = NOOP,
    roundness = 8,
    isOpen = false,
    onOpenChange = NOOP,
    renderTrigger,
    rightActions,
    mode = 'normal',
    ...rest
  } = props;

  const renderHeader = useCallback(
    (headerProps?: BottomSheetHeaderProps) => {
      return (
        <header tw="bg-white absolute top-0 z-50 max-w-mvw w-full left-1/2 -translate-x-1/2 text-xl h-12 flex items-center font-bold px-6">
          <Dialog.Close asChild>
            <button
              tw="mr-4 h-6 flex items-center justify-center rounded-full"
              onClick={onBackClick}
              aria-label="Close">
              <ChevronLeft tw="w-2" />
            </button>
          </Dialog.Close>
          <p tw="text-lg font-bold flex-1">{headerProps?.title}</p>
          <div tw="flex-shrink-0">
            {headerProps?.rightActions?.map(action => (
              <button key={action.label} onClick={action.onClick} tw="font-medium text-sm">
                {action.label}
              </button>
            ))}
          </div>
        </header>
      );
    },
    [onBackClick],
  );

  const transitions = useTransition(isOpen, {
    from: { backdropOpacity: 0, ty: 'translateX(-50%) translateY(100%)' },
    enter: { backdropOpacity: 0.5, ty: `translateX(-50%) translateY(0%)` },
    leave: { backdropOpacity: 0, ty: 'translateX(-50%) translateY(100%)' },
    config: { easing: easings.easeInExpo },
  });

  return (
    <Dialog.Root defaultOpen={defaultOpen} open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>{renderTrigger?.()}</Dialog.Trigger>
      {transitions(
        (styles, item) =>
          item && (
            <Dialog.Portal forceMount>
              <Dialog.Overlay asChild forceMount>
                <animated.div
                  tw="fixed inset-0 z-40  bottom-0 bg-black"
                  style={{
                    opacity: styles.backdropOpacity,
                  }}
                />
              </Dialog.Overlay>
              <Dialog.Content asChild forceMount>
                <animated.div
                  ref={ref}
                  style={{
                    transform: styles.ty,
                    borderTopLeftRadius: mode === 'fullpage' ? 0 : `${roundness}px`,
                    borderTopRightRadius: mode === 'fullpage' ? 0 : `${roundness}px`,
                    overflow: 'hidden'
                  }}
                  tw="fixed z-40 bottom-0 flex flex-col max-h-full -translate-x-1/2 left-1/2 w-full max-w-[500px] bg-white"
                  css={withHeader && tw`pt-12`}>
                  {withHeader && renderHeader?.({
                    title,
                    rightActions,
                  })}
                  <div tw="overflow-y-auto" css={[mode === 'fullpage' ? tw`h-screen` : tw`flex-1`]}>
                    {children}
                  </div>
                </animated.div>
              </Dialog.Content>
            </Dialog.Portal>
          ),
      )}
    </Dialog.Root>
  );
}

export default memo(forwardRef(BottomSheet));
