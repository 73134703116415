export type ShippingLabelArgs = {
  pdfUrl?: string;
};

export class ShippingLabel {
  pdfUrl?: string;

  constructor(args: ShippingLabelArgs) {
    Object.assign(this, args);
  }
}
