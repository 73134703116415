import React from 'react';

export type FloatingFooterProps = {
  children?: React.ReactNode;
};

function FloatingFooter(props: FloatingFooterProps) {
  const {children, ...rest} = props;

  return (
    <div
      tw="fixed bottom-0 max-w-mvw w-full left-1/2 -translate-x-1/2 bg-white overflow-hidden px-4 py-3 shadow-3"
      {...rest}>
      {children}
    </div>
  );
}

export default FloatingFooter;
