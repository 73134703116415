import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import mcAxios from "../../common/axios"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import { useSnackbar, withSnackbar } from "../../common/components/snackbar/snackbar"
import TextInput from "../../common/components/text-input/text-input"
import { CommissionType } from "../../common/constants"
import { Snackbar } from "../../common/entities/snackbar"
import { getLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import FormSection from "./components/product-form/components/form-section"
import ProgressBar from "./components/progress-bar"
import { AddProductLocalStorageAddress } from "./constants"
import { FabricMaterial, ProductTag } from "./utils"
import { normalizeAddProductParameters } from "./components/product-upload/api"

const productFormData = getLocalStorageObject(AddProductLocalStorageAddress)

function UploadProductPage6() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const [product, setProduct] = useState({
        ...productFormData,
        stocks: {},
        allStock: undefined
    })
    const [errors, setErrors] = useState({

    })

    useEffect(() => {
        let newProduct = { ...product, ...getLocalStorageObject(AddProductLocalStorageAddress) }
        let stocks = {}
        // colors and sizes exist
        if (newProduct.colors?.length > 0 && newProduct.sizes?.length > 0) {
            for (let color of newProduct.colors) {
                for (let size of newProduct.sizes) {
                    stocks[`${color}, ${size}`] = newProduct.stocks[`${color}, ${size}`] || ""
                }
            }
        }
        // only colors exist
        if (newProduct.colors?.length > 0 && !(newProduct.sizes?.length > 0)) {
            for (let color of newProduct.colors) {
                stocks[`${color}`] = newProduct.stocks[`${color}`] || ""
            }
        }
        // only sizes exist
        if (!(newProduct.colors?.length > 0) && newProduct.sizes?.length > 0) {
            for (let size of newProduct.sizes) {
                stocks[`${size}`] = newProduct.stocks[`${size}`] || ""
            }
        }
        // console.log("stocks",stocks)
        newProduct = {...newProduct, stocks: stocks}
        setProduct(newProduct)
        // handleStockChange(newProduct)
    }, [])

    // function handleStockChange(upToDateProduct?: any) {
    //     let theProduct = upToDateProduct || product
    //     let totalStock = 0
    //     let min = Infinity
    //     let max = 0
    //     for (let [key, stock] of Object.entries(theProduct.stocks)) {
    //         // @ts-ignore
    //         let stockNumber = parseInt(stock)
    //         if(isNaN(stockNumber)) stockNumber = 0
    //         if(stockNumber < min) min = stockNumber
    //         if(stockNumber > max) max = stockNumber
    //     }
    //     if (!isNaN(totalStock))
    //         setProduct({ ...theProduct, totalStock: `${min}-${max}` })
    // }

    function handleSubmit(type: "DRAFT" | "SUBMIT" = "SUBMIT") {
        setLocalStorageObject(AddProductLocalStorageAddress, { ...productFormData, ...product })
        mcAxios.post("/merchant/Product/Add", {
            type: type,
            ...normalizeAddProductParameters(product)
        }).then((res) => {
            setLocalStorageObject(AddProductLocalStorageAddress, {})
            // navigate(`/product/${res.data.data.productId}/edit`)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', type === "DRAFT" ? "\"DRAFT\"" : "\"LIVE\"")
            snackbar.create(
                new Snackbar({
                    message: "Complete",
                    type: 'normal',
                }),
            );
            setTimeout(()=>{navigate(`/products`)},1000)
        }).catch((error) => {
            snackbar.create(
                new Snackbar({
                    message: error.response.data?.message || error.response.data,
                    type: 'danger',
                }),
            );
        })
    }

    return (
        <>
            <Page tw="px-6 py-14 pb-24">
                <Header onGoBack={() => navigate("/product/upload/5")} title="Product Detail"></Header>
                <ProgressBar tw="mb-6" title="Stock" current={6} total={6} />
                <div tw="h-5"></div>

                {(product.colors?.length > 0 || product.sizes?.length > 0) ? <div tw="grid grid-cols-2 items-center mb-6">
                    <b>Variant</b>
                    <b>Stock</b>
                    {Object.entries(product.stocks).map(([key, value])=>(
                        <>
                            <span tw="text-sm font-semibold">{key}</span>
                            <TextInput
                                type="number"
                                value={product.stocks[`${key}`]}
                                onChange={(val) => {
                                    if (parseInt(val || "") >= 0) {
                                        let newStocks = product.stocks
                                        newStocks[`${key}`] = val
                                        setProduct({ ...product, stocks: newStocks })
                                        // handleStockChange()
                                    }
                                }}
                            />
                        </>
                    ))}
                </div>

                    :

                    <FormSection label="Stock" labelSize="normal" labelFontWeight="bold">
                        <TextInput
                            // disabled
                            type="string"
                            value={product.allStock}
                            onChange={(val) => setProduct({ ...product, allStock: val || "" })}
                        />
                    </FormSection>
                }


                <FloatingFooter tw="flex gap-3">
                    <Button onClick={() => handleSubmit("DRAFT")} variant="outline">Save as draft</Button>
                    <Button onClick={() => handleSubmit("SUBMIT")}>Submit</Button>
                </FloatingFooter>
            </Page >
        </>
    )
}

export default withSnackbar(UploadProductPage6)