import {BaseEntity} from './base';

export type LabelArgs = {
  label?: string;
  id?: string;
  isSelected?: boolean;
};

export class Label extends BaseEntity {
  label?: string;
  id = '';
  isSelected = false;

  constructor(args?: LabelArgs) {
    super();
    Object.assign(this, args);
  }

  toJSON() {
    return {
      id: this.id,
      label: this.label,
    };
  }

  static cast(args: any) {
    return new Label({
      id: args.id,
      label: `${args.label} ${args.totalItem ? ` (${args.totalItem})` : ''}${args.count ? `(${args.count})` : ''}`,
    });
  }
}
