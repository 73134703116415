import axios from '../../common/axios';
import {Label} from '../../common/entities';
import {Order, OrderTypeLabel, OrderType} from '../../common/entities/order';
import {CommonNetworkResponse} from '../../common/types';

export type FetchOrderListOptions = {
  categoryId?: string;
  filterId?: string;
  startIndex: number;
  limit: number;
};

export type FetchOrderListResponse = {
  totalItems: number;
  categories: {
    id: string;
    label: string;
    totalItem: number;
  }[];
  filters: {
    id: string;
    label: string;
  }[];
  orders: Array<{
    orderId: string;
    buyerName: string;
    buyerProfileImageUrl: string;
    type: OrderType;
    status: OrderStatus;
    secondStatus: string;
    badgeUrls: string[];
    moreInfo?: string;
    subTotal: number;
    product: {
      productId: string;
      bagItemId: string;
      imageUrl: string;
      brand: string;
      name: string;
      attribute: string;
    };
    respondDeadline?: string;
    shipDeadline?: string;
  }>;
};

export async function fetchOrderList(
  options: FetchOrderListOptions = {
    limit: 20,
    startIndex: 0,
  },
) {
  const {data} = await axios.post<CommonNetworkResponse<FetchOrderListResponse>>('/merchant/Order/List', {
    ...options,
  });

  return data;
}

export async function acceptAllOrders() {
  await axios.post('/merchant/Order/AcceptAll');
}

export async function acceptOrder(order: Order) {
  await axios.post('/merchant/Order/Accept', {
    orderId: order.id,
  });
}

export async function declineOrder(order: Order) {
  await axios.post('/merchant/Order/Decline', {
    orderId: order.id,
  });
}

export type TrackOrderResponse = {
  status: string;
  orderTime: string;
  courierDetail: {
    courierImageUrl: string;
    courierLabel: string;
  };
  resiNumber: string;
  trackingDetailList: {
    timeLabel: string;
    description: string;
  }[];
};

export async function trackOrder(order: Order): Promise<TrackOrderResponse | undefined> {
  const {data} = await axios.post<CommonNetworkResponse<TrackOrderResponse>>('/merchant/Order/Track', {
    orderId: order.id,
    bagItemIds: order.bag?.map(item => item.id),
  });

  return data.data;
}

export type GetOrderDetailsResponse = {
  dateLabel: string;
  buyerName: string;
  buyerImageProfileUrl: string;
  shipDeadlines: {
    label: string;
    dateLabel: string;
  }[];
  billing: {
    billingItems: {
      label: string;
      value: number;
    }[];
    total: number;
  };
  deliveryDetails: {
    courier: string;
    name: string;
    phoneNumber: string;
    address: string;
  };
  orders: {
    title?: string;
    items: {
      status: string;
      type: string;
      statusDetail?: string;
      products?: {
        productId: string;
        bagItemId: string;
        imageUrl: string;
        badgeUrl: string;
        brand: string;
        name: string;
        attribute: string;
        weight: string;
        price: number;
      }[];
    }[];
  }[];
};

export async function getOrderDetails(order: Order): Promise<GetOrderDetailsResponse | undefined> {
  const {data} = await axios.post<CommonNetworkResponse<GetOrderDetailsResponse>>('/merchant/Order/Detail', {
    orderId: order.id,
  });

  return data.data;
}

export type RequestPrintShippingLabel = {
  downloadUrl: string;
};

export async function requestPrintShippingLabel(order: Order): Promise<RequestPrintShippingLabel | undefined> {
  const {data} = await axios.post<CommonNetworkResponse<RequestPrintShippingLabel>>(
    `/merchant/Order/PrintShippingLabel`,
    {
      orderId: order.id,
    },
  );

  return data.data;
}
