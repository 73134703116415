import React, { useState } from "react";

type payload = {
  isSubmite: boolean;
};

function CashoutApplyButton(payload: payload) {
  let color = "#E4E4E4";
  if (payload.isSubmite) {
    color = "#35373B";
  }

  return (
    <button
      tw="py-4 px-3 bg-[#E4E4E4] rounded-xl w-full text-white text-sm"
      disabled={!payload.isSubmite}
      style={{ backgroundColor: color }}>
      Apply Cashout
    </button>
  );
}

export default CashoutApplyButton;
