import React from "react"
import $ from "jquery"
import LoadingWidget from "../../../common/components/LoadingWidget";

type SubcategoryPickerProps = {
    values?: string[];
    options?: string[];
    onChange?: (values: string[]) => void;
    expand?: boolean;
    isLoading?: boolean;
}

function SubcategoryPicker(props: SubcategoryPickerProps) {
    const { values, options = [], onChange = () => { }, expand = true, isLoading = false } = props

    function handleChange(value: string) {
        let newValues = values || []
        if (newValues.includes(value)) {
            newValues = newValues.filter(val => val !== value)
        } else {
            newValues.push(value)
        }
        onChange(newValues)
    }

    return (
        <>
            <div tw="w-full bg-neutral-200 h-[1px] mt-3 mb-3"></div>
            {isLoading ? <LoadingWidget /> :
                <div tw="grid grid-cols-2 gap-1" css={!expand && { display: "none" }}>
                    {options.map(option => (
                        <div
                            tw="flex gap-2 items-center"
                            onClick={() => handleChange(option)}
                        >
                            <input
                                id={`${option}Subcategory`}
                                type="checkbox"
                                checked={values?.includes(option)}
                            />
                            <span
                                onClick={() => $(`#${option}Subcategory`).trigger("click")}
                            >{option}</span>
                        </div>
                    ))}
                </div>
            }
        </>
    )
}

export default SubcategoryPicker