import React, { useEffect, useState } from "react";
import Page from "../../common/components/page/page";
import Header from "../../common/components/header/header";
import WalletCard from "./components/wallet-card";
import WalletCashoutButton from "./components/wallet-cashout-button";
import {
  walletSummaryResponse,
  fetchWalletSummary,
  walletHistoryResponse,
  fetchWalletHistory,
} from "./api";
import Tabs from "../../common/components/tabs/tabs";
import { WalletHistoryCard } from "./components/wallet-history-card";
import WalletCashoutAppied from "./components/wallet-cashout-applied";
import ScrollContainer from "react-indiana-drag-scroll";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingWidget from "../../common/components/LoadingWidget";

function WalletSummary() {
  const [walletSummary, setWalletSummary] = useState<walletSummaryResponse>({
    walletTotal: "Rp 0",
    walletOnHold: "Rp 0",
    walletSettled: "Rp 0",
    cashoutApplied: [],
  });

  useEffect(() => {
    fetchWalletSummary().then((value) => {
      setWalletSummary(value);
    });
  }, []);

  function onCategoryChanged(v: string) {
    setSelectedCategory(v);
  }

  const [walletHistory, setWalletHistory] = useState<walletHistoryResponse>({
    totalOfItems: 0,
    items: [],
  });

  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(walletHistory.items.length);
    if (walletHistory.items.length < walletHistory.totalOfItems) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [walletHistory]);

  const dataTabs = [
    {
      label: "All",
      id: "all",
    },
    {
      label: "Cashout",
      id: "cashout",
    },
    {
      label: "On Hold",
      id: "onhold",
    },
    {
      label: "Settled",
      id: "settled",
    },
  ];

  useEffect(() => {
    if (selectedCategory === "all") {
      fetchWalletHistory(0, "all").then((value) => {
        setWalletHistory(value);
      });
    } else if (selectedCategory === "cashout") {
      fetchWalletHistory(0, "cashout").then((value) => {
        setWalletHistory(value);
      });
    } else if (selectedCategory === "onhold") {
      fetchWalletHistory(0, "onhold").then((value) => {
        setWalletHistory(value);
      });
    } else if (selectedCategory === "settled") {
      fetchWalletHistory(0, "settled").then((value) => {
        setWalletHistory(value);
      });
    } else {
      fetchWalletHistory(0, "all").then((value) => {
        setWalletHistory(value);
      });
    }
  }, [selectedCategory]);

  const loadMore = () => {
    // Load more data when scrolling
    fetchWalletHistory(offset, selectedCategory ?? "all").then((value) => {
      setWalletHistory({
        totalOfItems: walletHistory.totalOfItems,
        items: [...walletHistory.items, ...value.items],
      });
      setOffset(offset + value.items.length);
    }); // Use the imported fetchData function
  };

  return (
    <Page tw="bg-[#F2F2F2]" className="">
      <Header title="Wallet" bgTransparent={true} tw="bg-white" className="" />
      <div tw="h-20" className="h-20" />
      <div tw="px-6">
        {walletSummary ? (
          <WalletCard
            balance={walletSummary.walletTotal || '0'}
            onHold={walletSummary.walletOnHold || '0'}
            settled={walletSummary.walletSettled || '0'}
          />
        ) : (
          "loading"
        )}
        <div tw="h-6"></div>
        <WalletCashoutButton />
        <div tw="h-6"></div>
        <ScrollContainer
          horizontal
          hideScrollbars={true}
          tw="flex flex-row gap-5"
          className="scroll-container">
          {walletSummary.cashoutApplied.map((data) => {
            return (
              <WalletCashoutAppied
                date={data.time}
                totalAmount={data.amount}
                name={data.accountName}
                bankAccount={data.accountDetail}
              />
            );
          })}
        </ScrollContainer>
      </div>
      <div tw="h-6"></div>
      <div
        tw="max-w-mvw w-full bg-white sticky top-11"
        style={{ borderBottom: "1px solid #F2F2F2" }}>
        <Tabs
          value={selectedCategory || dataTabs[0].id}
          onValueChange={(v) => onCategoryChanged(v)}
          items={dataTabs}
        />
      </div>

      <InfiniteScroll
        dataLength={walletHistory.items.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<LoadingWidget />}>
        {walletHistory.items.map((data) => {
          return (
            <>
              <WalletHistoryCard
                date={data.time}
                amount={data.amount}
                title={data.title}
                subtitle={data.description}
                status={data.status}
              />
              <div tw="h-[1px] bg-[#F2F2F2] w-full"></div>
            </>
          );
        })}
      </InfiniteScroll>
    </Page>
  );
}

export default WalletSummary;
