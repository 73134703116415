import React from "react";
import FacebookLogin from "react-facebook-login";
import { useGoogleLogin } from "react-google-login";
import axios, { fepiAxios } from "../../../common/axios";
// import "./login-page.css"
import Page from "../../../common/components/page/page";
import { useNavigate } from "react-router-dom";

const googleClientId = "592286354232-dp3q0g6l7olp0esmbvthkgm7j497a2k1.apps.googleusercontent.com";

function LoginPage() {

    const navigate = useNavigate()

    const onGoogleSignInSuccess = (res: any) => {
        fepiAxios.post("mobile/Login/Google", {
            googleLogin: res.profileObj.googleId,
            email: res.profileObj.email,
            full_name: res.profileObj.name,
            last_name: res.profileObj.familyName,
            foto: res.profileObj.imageUrl
        }).then((response) => {
            localStorage.setItem("fpAuth", response.data.fpAuth || "")
            navigate("/")
        }).catch((error) => {
            navigate("/login")
        })
    }

    const responseFacebook = (response: any) => {
        if (response.email) {
            fepiAxios.post("mobile/Login/Facebook", {
                facebookLogin: response.id,
                emailOrPhone: response.email,
                full_name: response.name,
                last_name: response.name,
                foto: response.picture.data.url
            }).then((response) => {
                localStorage.setItem("fpAuth", response.data.fpAuth || "")
                navigate("/")
            }).catch((e) => {
                navigate("/login")
            })
        }
    }

    const { signIn } = useGoogleLogin({
        onSuccess: onGoogleSignInSuccess,
        clientId: googleClientId,
        isSignedIn: false,
    });

    return (
        <Page tw="px-8">
            <div tw="flex justify-center pt-20 pb-20">
                <img width="180" src="/images/thefepi-merchant.png" />
            </div>

            <FacebookLogin
                appId="1408696446192941"
                autoLoad={false}
                fields="email,name,picture"
                callback={responseFacebook}
                cssClass="btnFacebook"
                icon="fa-facebook"
            />

            <div tw="h-2"></div>

            <div
                onClick={signIn}
                style={{
                    border: "solid #35373B 1px",
                    borderRadius: "12px"
                }}
                tw="px-5 py-4"
            >
                <div >
                    <img tw="float-left" src="https://images.thefepi.com/file/shopable/img-google.png" />
                    <div tw="font-bold text-center ml-10">Login with Google</div>
                </div>
            </div>

            <div tw="mt-6" style={{ borderTop: "1px solid rgb(206, 206, 206)" }}>
                <span
                    tw="px-2 relative bg-white"
                    style={{
                        top: "-12px",
                        zIndex: "20",
                        color: "rgb(206, 206, 206)",
                        left: "46%",
                    }}
                >
                    or
                </span>
            </div>

            <div
                onClick={() => { navigate("/loginSso") }}
                tw="px-5 py-4 text-white text-center border rounded-xl bg-zinc-800"
            >
                Sign in with Email/Phone
            </div>

            <div tw="h-10"></div>

            <p tw="text-center text-sm">New here? <a href="/register" tw="underline">Create account</a></p>

        </Page>
    )
}

export default LoginPage