import {css, keyframes} from '@emotion/react';
import React from 'react';
import tw from 'twin.macro';

const kf = keyframes`
 0% {
        opacity: 0.2;
        transform: scale(0.3, 0.3);
    }
  40% {
      opacity: 1;
      transform: scale(1, 1);
  }

  80% {
      opacity: 0.2;
      transform: scale(0.3, 0.3);
  }

  100% {
      opacity: 0.2;
      transform: scale(0.3, 0.3);
  }`;

export type PulseLoaderProps = {
  theme?: 'dark' | 'light';
} & React.HTMLAttributes<HTMLDivElement>;

function PulseLoader(props: PulseLoaderProps) {
  const {theme = 'dark', ...rest} = props;

  return (
    <div tw="flex" {...rest}>
      {Array.from({length: 3}).map((_, index) => {
        return (
          <span
            key={index}
            tw="opacity-20 rounded-full mx-1 h-2 w-2 bg-white"
            css={[
              css`
                animation: ${kf} 1s infinite;
                animation-delay: ${0.15 * index}s;
              `,
              theme === 'light' && tw`bg-primary`,
            ]}></span>
        );
      })}
    </div>
  );
}

export default PulseLoader;
