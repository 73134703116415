import axios from '../../common/axios';


export type cashoutAppliedModel = {
  id: string;
  time: string;
  amount: string;
  accountName: string;
  accountDetail: string;
};

export type walletSummaryResponse = {
  walletTotal: string;
  walletOnHold: string;
  walletSettled: string;
  cashoutApplied: cashoutAppliedModel[];
};

export type walletHistoryResponse = {
  totalOfItems: number;
  items: any[];
};


export  async function fetchWalletSummary() {
  const response = await axios.post('/merchant/Wallet/Summary')
  const data = {
    walletTotal: response.data.data.walletTotal,
    walletOnHold: response.data.data.walletOnHold,
    walletSettled: response.data.data.walletSettled,
    cashoutApplied: response.data.data.cashoutApplied,
  };

  return data;
}

export async function fetchWalletHistory(offset: number, category: string) {
  let url = ""
  if (category === "all") {
    url = "/merchant/Wallet/History/All";
  }

  if (category === "cashout") {
     url = "/merchant/Wallet/History/Cashout";
  }

  if (category === "onhold") {
    url = "/merchant/Wallet/History/OnHold";
  }

   if (category === "settled") {
     url = "/merchant/Wallet/History/Settled";
   }
    const data = {
      totalOfItems: 0,
      items: [],
    };
  const req = {
    startIndex: offset,
    limit: 24,
  };
  const response = await(await axios.post(url, req)).data.data;
  data.totalOfItems = response.totalOfItems;
  data.items = response.items;

  return data;
}
