import React, { useState } from 'react';
import Button from '../../common/components/button/button';
import tw from 'twin.macro';
import DraggableBottomSheet from '../../common/components/dragableBottomsheet/DraggableBottomSheet';
import ArrowLeftIcon from "../../common/icons/arrow-left.svg"
import InformationIcon from "../../common/icons/information.svg"
import FloatingFooter from '../../common/components/floating-footer/floating-footer';
import TextInput from '../../common/components/text-input/text-input';
import mcAxios from '../../common/axios';
import LoadingWidget from '../../common/components/LoadingWidget';

export type BulkComissionProps = {
  bulkCommission: number;
  isShown?: boolean;
  onClosed?: () => void;
  onChange?: (val: number) => void;
};

function BulkComission(props: BulkComissionProps) {
  const {onClosed} = props;

  const [bulkCommission, setBulkCommission] = useState(props.bulkCommission)
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleCommissionChange(val: string | number){
    let commissionNumber = parseInt(val.toString())
    if(commissionNumber>=0){
      setBulkCommission(commissionNumber)
    }
  }

  function handleSubmit(){
    setIsSubmitting(true)
    mcAxios.post("/merchant/Setting/BulkCommission",{bulkCommission: bulkCommission.toString()}).then(()=>{
      // setIsSubmitting(false)
      props.onChange?.(bulkCommission)
    }).catch((e)=>{
      // setIsSubmitting(false)
    })
  }

  return (
    <DraggableBottomSheet
      tw="max-w-[500px] mx-auto border"
      isShown={props.isShown}
      onClosed={onClosed}
    >
      <div tw="flex gap-4 px-6 pb-3 items-center">
        <div onClick={onClosed}><ArrowLeftIcon /></div>
        <b tw='flex-grow'>Set Bulk Commission</b>
        <span tw='text-sm' onClick={onClosed}>Cancel</span>
      </div>
      <div tw='flex flex-col gap-4 p-4 pb-8 mb-14'>
        <div tw='flex gap-2 bg-[rgba(248, 248, 248, 1)] rounded-l py-1 px-2 text-[rgba(98, 98, 98, 1)]'>
          <div tw='w-5'><InformationIcon /></div>
          <p tw='text-xs'><span tw='font-bold'>Bulk commission</span>: Quickly set flat commission rate for all products.</p>
        </div>
        <div tw='flex gap-3 px-8 items-center'>
          <div>
            <Button variant='outline' size='small' onClick={()=>handleCommissionChange(bulkCommission-1)}>-</Button>
          </div>
            <TextInput tw='text-center' align='center' noMargin onChange={(val)=>handleCommissionChange(val || "0")} value={`${bulkCommission}%`} />
          <div>
            <Button variant='outline' size='small' onClick={()=>handleCommissionChange(bulkCommission+1)}>+</Button>
          </div>
        </div>
      </div>
      <FloatingFooter>
        <Button onClick={handleSubmit} isDisabled={isSubmitting}>Save</Button>
      </FloatingFooter>
    </DraggableBottomSheet>
  );
}

export default BulkComission;
