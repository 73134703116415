import React, {forwardRef} from 'react';
import tw from 'twin.macro';

export type ChipProps = {
  isChecked?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
};

function Chip(props: ChipProps, ref: any) {
  const {isChecked, onClick, isDisabled, children, ...rest} = props;

  return (
    <button
      onClick={onClick}
      ref={ref}
      tw="px-4 text-sm text-gray-400 border border-gray-400 py-1.5 rounded-full overflow-hidden flex-shrink-0"
      css={[isChecked && tw`text-white bg-gray-800 border-gray-800`]}
      {...rest}>
      {children}
    </button>
  );
}

export default forwardRef(Chip);
