import axios from 'axios';
import { getRuntimeConfig } from '../framework/config';

const createAxiosInstance = (baseUrl = "") => {
  const instance = axios.create({
    baseURL: baseUrl || getRuntimeConfig().API_ROOT_URL,
  });

  // Global error interceptor for handling 401 Unauthorized responses
  instance.interceptors.response.use(
    response => response, // Pass through successful responses
    error => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        // Handle 401 error (e.g., clear tokens, redirect to login)
        originalRequest._retry = true; // Mark request as retried

        const handleUnauthorizedError = async () => {
          // Clear access token (replace with your specific logic)
          window.localStorage.removeItem('fpAuth');

          // Redirect to login page (replace with your URL)
          window.location.href = '/login';

          return Promise.reject(error); // Reject the original error
        };

        // Optionally handle potential token refresh logic before retrying the request:
        // - Check if refresh token is available
        // - Attempt token refresh using a separate API call
        // - Update access token if refresh is successful
        // - Retry the original request with the updated access token

        // If token refresh is not applicable (or fails), proceed with error handling:
        return handleUnauthorizedError();
      }

      return Promise.reject(error); // Pass through other errors
    }
  );

  instance.interceptors.request.use(config => {
    const token = window?.localStorage?.getItem('fpAuth');
    config.data = { fpAuth: token, ...config.data };

    if (!config.baseURL?.includes("appmc")) {
      config.headers.Authorization = "Basic dGhlZmVwaTohQCNhcHBzMjAyMVRI"
      config.headers.set("X-AppKey", "eyJpdiI6Im9ySEUvR0owSjdVL2M1V3Y5emZzOUE9PSIsInZhbHVlIjoibGJ3Rk1qNWhTbGRtOFJqSmpVZU9xZz09IiwibWFjIjoiYTc1NjcwNjgyMDkzODNlOTg3NzY1NzI3MGVjMzlkNzk4M2FiMzU4NmFiMWI0Y2Q3NTEwNDRkZWEzNDFmYzMyMSJ9")
    }

    return config;
  });

  return instance;
};

const mcAxios = createAxiosInstance();

export const fepiAxios = createAxiosInstance(getRuntimeConfig().FEPI_API_ROOT_URL)

export default mcAxios
