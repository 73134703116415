import React from 'react';

export type NotFoundProps = {
  title?: string;
};

function NotFound(props: NotFoundProps) {
  const {title} = props;

  return (
    <div>
      <div tw="flex items-center justify-center">
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="25" cy="25" r="25" fill="#E7EDFF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27 18.75C26.59 18.75 26.25 18.41 26.25 18C26.25 17.59 26.59 17.25 27 17.25H33C33.41 17.25 33.75 17.59 33.75 18C33.75 18.41 33.41 18.75 33 18.75H27Z"
            fill="#6677FF"
            stroke="#6677FF"
            strokeWidth="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27 21.75C26.59 21.75 26.25 21.41 26.25 21C26.25 20.59 26.59 20.25 27 20.25H30C30.41 20.25 30.75 20.59 30.75 21C30.75 21.41 30.41 21.75 30 21.75H27Z"
            fill="#6677FF"
            stroke="#6677FF"
            strokeWidth="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.25 24.5C14.25 18.85 18.85 14.25 24.5 14.25C24.91 14.25 25.25 14.59 25.25 15C25.25 15.41 24.91 15.75 24.5 15.75C19.67 15.75 15.75 19.68 15.75 24.5C15.75 29.32 19.67 33.25 24.5 33.25C29.33 33.25 33.25 29.32 33.25 24.5C33.25 24.09 33.59 23.75 34 23.75C34.41 23.75 34.75 24.09 34.75 24.5C34.75 30.15 30.15 34.75 24.5 34.75C18.85 34.75 14.25 30.15 14.25 24.5Z"
            fill="#6677FF"
            stroke="#6677FF"
            strokeWidth="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.4704 35.5299L32.4704 33.5299C32.1804 33.2399 32.1804 32.7599 32.4704 32.4699C32.7604 32.1799 33.2404 32.1799 33.5304 32.4699L35.5304 34.4699C35.8204 34.7599 35.8204 35.2399 35.5304 35.5299C35.3804 35.6799 35.1904 35.7499 35.0004 35.7499C34.8104 35.7499 34.6204 35.6799 34.4704 35.5299Z"
            fill="#6677FF"
            stroke="#6677FF"
            strokeWidth="0.5"
          />
        </svg>
      </div>
      <div tw="flex justify-center text-lg mt-3">{title}</div>
    </div>
  );
}

export default NotFound;
