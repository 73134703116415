import React, {forwardRef} from 'react';

export type RadioIndicatorProps = React.DetailsHTMLAttributes<HTMLDivElement>;

function RadioIndicator(props: RadioIndicatorProps, ref: any) {
  const isChecked = props['data-state'] === 'checked';

  return (
    <div
      ref={ref}
      tw="h-5 w-5 border data-[state=checked]:border-gray-700 border-gray-400 rounded-full flex items-center justify-center"
      {...props}>
      {isChecked && <div tw="bg-secondary w-3 h-3 rounded-full"></div>}
    </div>
  );
}

export default forwardRef(RadioIndicator);
