import React from "react";
import { formatPrice } from "../../../common/utils";

type cashoutSumarryResponse = {
  totalSettled: number;
};

function CashoutCard(response: cashoutSumarryResponse) {
  const { totalSettled } = response;
  return (
    <div
      tw="w-full h-[5.3125rem] bg-white rounded-xl px-4 pt-4 pb-[0.625rem] gap-2 flex flex-col drop-shadow z-[100]"
      style={{ boxShadow: "0px 2px 16px 0px rgb(0, 0, 0, 0.07)" }}>
      <div tw="text-sm font-medium">Settled</div>
      <div tw="text-xl font-bold">{formatPrice(totalSettled)}</div>
    </div>
  );
}

export default CashoutCard;