import React, { useCallback, forwardRef } from 'react';
import tw from 'twin.macro';
import { NOOP } from '../../constants';
import PulseLoader from '../loaders/pulse';

export type ButtonProps = {
  isLoading?: boolean;
  size?: 'normal' | 'small';
  isDisabled?: boolean;
  variant?: 'filled' | 'outline';
  shrink?: boolean;
  radius?: string;
  as?: string | React.ElementType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement> | React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLAnchorElement | HTMLButtonElement
>;

function Button(props: ButtonProps, ref: React.LegacyRef<HTMLButtonElement>) {
  const {
    as = 'button',
    variant = 'filled',
    size = 'normal',
    isLoading = false,
    isDisabled,
    children,
    shrink = false,
    radius,
    onClick = NOOP,
    ...rest
  } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
    },
    [onClick],
  );

  const Comp = as;

  return (
    <Comp
      // ref={ref}
      disabled={isLoading || isDisabled}
      // type="button"
      onClick={handleClick}
      tw="px-6 text-white min-h-[52px] border border-primary flex items-center justify-center py-3 bg-zinc-800"
      css={[
        size === 'small' && tw`text-sm py-2 px-3 min-h-0`,
        variant === 'outline' && tw`bg-white text-primary`,
        isDisabled && variant === 'filled' && tw`bg-gray-200 border-gray-200`,
        isDisabled && variant === 'outline' && tw`bg-white text-gray-200 border-gray-200`,
        !shrink && tw`w-full`,
        !radius ? tw`rounded-xl` : { borderRadius: radius }
      ]}
      {...rest}>
      {isLoading ? <PulseLoader theme={variant === 'outline' ? 'light' : 'dark'} /> : children}
    </Comp>
  );
}

export default forwardRef<HTMLButtonElement, ButtonProps>(Button);
