import React from 'react';
import loadable from '@loadable/component';
import PulseLoader from '../../common/components/loaders/pulse';

export default loadable(() => import('./order-list-page'), {
  fallback: (
    <div tw="h-screen flex items-center justify-center">
      <PulseLoader theme="light" />
    </div>
  ),
  ssr: false,
});
