import * as RadioGroup from '@radix-ui/react-radio-group';
import React, {memo, useCallback, useState, useEffect, forwardRef} from 'react';
import {useMutation, useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import BottomSheet, {BottomSheetProps} from '../../../common/components/bottom-sheet/bottom-sheet';
import ChipCheckbox from '../../../common/components/chip/chip-checkbox';
import RadioIndicator from '../../../common/components/indicators/radio';
import {NOOP} from '../../../common/constants';
import {Brand} from '../../../common/entities/brand';
import MagnifierIcon from '../../../common/icons/magnifier.svg';
import NotFoundImage from '../../../common/icons/notfound.svg';
import {addBrand, fetchBrandList} from '../api';
import FloatingFooter from '../../../common/components/floating-footer/floating-footer';
import Button from '../../../common/components/button/button';
import PulseLoader from '../../../common/components/loaders/pulse';
import TextInput from '../../../common/components/text-input/text-input';

export type BrandPickerSheetProps = {
  value?: Brand;
  onValueChange?: (value?: Brand) => void;
} & BottomSheetProps;

function BrandPickerSheet(props: BrandPickerSheetProps, ref: any) {
  const {onValueChange = NOOP, onOpenChange} = props;

  const [selectedKey, setSelectedKey] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const brandList = useQuery(
    ['brand-list', selectedKey],
    async () => {
      const data = await fetchBrandList({
        categoryId: `${selectedKey}`,
      });
      if (!selectedKey) {
        setSelectedKey(data?.categories?.[0]?.id || '');
      }

      return data;
    },
    {
      retryOnMount: true,
      enabled: Boolean(props.isOpen),
    },
  );

  const newBrandForm = useForm<{name?: string}>({
    defaultValues: {
      name: '',
    },
  });

  const {categories, brands} = brandList.data || {};

  const addBrandQuery = useMutation({
    mutationFn: async (brand: Brand) => {
      const response = await addBrand(brand);
      return Brand.cast(response);
    },
  });

  const [shouldOpenCreateBrandSheet, setShouldOpenCreateBrandSheet] = useState(false);

  const renderBrandList = useCallback(
    (brands: Brand[] = []) => {
      const filteredBrands = brands.filter(brand => brand.name?.toLowerCase().includes(searchQuery.toLowerCase()));

      if (filteredBrands.length === 0 && !brandList.isLoading) {
        return (
          <div tw="my-8">
            <div tw="flex justify-center">
              <NotFoundImage />
            </div>
            <p tw="text-gray-400 text-lg mt-6 text-center">No result found</p>
            <div tw="px-4 mt-6">
              <p tw="text-gray-800 text-xs mb-1">Can{`'`}t find your brand?</p>
              <Button
                variant="outline"
                onClick={() => {
                  setShouldOpenCreateBrandSheet(true);
                }}>
                Add New Brand
              </Button>
            </div>
          </div>
        );
      }

      if (brandList.isLoading) {
        return (
          <div tw="h-full flex justify-center">
            <PulseLoader theme="light" />
          </div>
        );
      }

      return (
        <RadioGroup.Root tw="gap-3 flex flex-col" onValueChange={setSelectedBrandId} value={selectedBrandId}>
          {filteredBrands.map(
            brand =>
              brand.id && (
                <div key={brand.id} tw="cursor-pointer">
                  <RadioGroup.Item asChild value={brand.id} id={brand.id}>
                    <div tw="h-12 px-4 flex items-center">
                      <div tw="h-12 w-12 rounded-full overflow-hidden mr-3 flex-shrink-0">
                        <img src={brand.imageUrl} alt={brand.name} tw="h-full w-full object-cover" />
                      </div>
                      <div key={brand.id} tw="flex-1 text-sm font-semibold">
                        {brand.name}
                      </div>
                      <RadioGroup.Indicator asChild forceMount>
                        <RadioIndicator />
                      </RadioGroup.Indicator>
                    </div>
                  </RadioGroup.Item>
                </div>
              ),
          )}
        </RadioGroup.Root>
      );
    },
    [selectedBrandId, searchQuery, brandList.isLoading],
  );

  return (
    <BottomSheet ref={ref} {...props}>
      <FloatingFooter>
        <Button
          onClick={() => {
            const selectedBrand = brands?.find(brand => brand.id === selectedBrandId);
            onValueChange(selectedBrand);
            onOpenChange?.(false);
          }}>
          Submit
        </Button>
      </FloatingFooter>
      <BottomSheet
        title="Add Brand"
        onOpenChange={setShouldOpenCreateBrandSheet}
        isOpen={shouldOpenCreateBrandSheet && props.isOpen}>
        <FloatingFooter>
          <Button
            isDisabled={!newBrandForm.formState.isValid}
            isLoading={addBrandQuery.isLoading}
            onClick={() => {
              newBrandForm.handleSubmit(props => {
                addBrandQuery.mutate(new Brand({name: props.name}), {
                  onSuccess: (brand: Brand) => {
                    onValueChange(brand);
                    setShouldOpenCreateBrandSheet(false);
                    onOpenChange?.(false);
                  },
                });
              })();
            }}>
            Submit
          </Button>
        </FloatingFooter>
        <div tw="px-6 py-3 pb-24">
          <Controller
            control={newBrandForm.control}
            name="name"
            rules={{
              required: true,
            }}
            render={({field: {value, onChange}, fieldState: {error}}) => (
              <TextInput label="Brand Name" value={value} onChange={onChange} error={error?.message} />
            )}
          />
        </div>
      </BottomSheet>
      <div tw="pb-20 pt-24">
        <div tw="fixed top-12 left-0 right-0 bg-white">
          <div tw="flex gap-2 px-4 pb-2">
            {!brandList.isLoading && categories
              ? categories.map(
                  category =>
                    category.id && (
                      <ChipCheckbox
                        tw="h-8"
                        value={`${category.id}`}
                        isChecked={category.id === selectedKey}
                        onClick={() => {
                          setSelectedKey(category.id || '');
                        }}
                        key={category.id}>
                        {category.label}
                      </ChipCheckbox>
                    ),
                )
              : Array.from({length: 5}).map((_, index) => (
                  <div tw="w-14 h-8 bg-gray-200 animate-pulse rounded-full" key={index} />
                ))}
          </div>
          <div tw="px-4 py-2">
            <div tw="h-9 w-full border border-gray-200 rounded-lg px-3 py-2 flex items-center">
              <MagnifierIcon tw="w-4 h-4 flex-shrink-0" />
              <div tw="flex-1 flex items-center ml-2">
                <input
                  placeholder="Search brands"
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  tw="w-full focus:outline-none text-sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div>{renderBrandList(brands)}</div>
      </div>
    </BottomSheet>
  );
}

export default memo(forwardRef(BrandPickerSheet));
