import React, {useRef} from 'react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import PlusIcon from '../../icons/plus.svg';
import XIcon from '../../icons/x.svg';
import {NOOP} from '../../constants';
import PulseLoader from '../loaders/pulse';
import FormSection, {FormSectionProps} from './section';

export type ImagePickerImage = {
  url?: string;
  state: 'idle' | 'uploading';
  id?: string;
  onDeleted?: (id?: string) => void;
};

export type ImagePickerProps = {
  images?: ImagePickerImage[];
  error?: string;
  onImageChange?: (image: ImagePickerImage) => void;
  onFileAdded?: (file?: File) => void;
} & FormSectionProps;

function ImagePicker(props: ImagePickerProps) {
  const {images = [], onImageChange = NOOP, error, onFileAdded = NOOP, ...rest} = props;

  const ref = useRef(null as any);

  return (
    <FormSection id={props.id} required={props.required} label={props.label} {...rest}>
      <div tw="flex overflow-x-auto gap-2">
        {images.map((image, index) => (
          <div key={image.id || index} tw="relative w-24 h-32 rounded-lg overflow-hidden flex-shrink-0">
            <button
              onClick={() => void image.onDeleted?.(image.id)}
              tw="top-1.5 right-1.5 flex items-center justify-center absolute rounded-full h-6 w-6 bg-textPrimary bg-opacity-70">
              <XIcon />
            </button>
            {image.state === 'uploading' && (
              <div tw="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                <PulseLoader theme="dark" />
              </div>
            )}
            <img crossOrigin="anonymous" src={image.url} tw="w-full h-full object-cover" alt={`product image id ${image.id}`} />
          </div>
        ))}
        <div
          role="button"
          onClick={() => {
            ref.current?.click?.();
          }}
          tw="flex flex-shrink-0 flex-col items-center justify-center border border-dashed w-24 h-32 rounded-lg border-secondary">
          <div tw="flex justify-center mb-2">
            <PlusIcon />
          </div>
          <VisuallyHidden.Root>
            <input
              onChange={e => {
                onFileAdded(e.target.files?.[0]);
              }}
              type="file"
              accept="image/png, image/jpeg"
              id={props.id}
              ref={ref}
            />
          </VisuallyHidden.Root>
          <p tw="text-secondary font-bold text-sm ">add photo</p>
        </div>
      </div>
      <p tw="text-red-700 text-xs mt-1">{error}</p>
    </FormSection>
  );
}

export default ImagePicker;
