import React, { useEffect, useState } from 'react';
import Button from '../../../../common/components/button/button';
import IconView from '../../../../common/icons/view.svg';
import IconHeart from '../../../../common/icons/heart.svg';
import IconPurchase from '../../../../common/icons/purchase.svg';
import IconComisson from '../../../../common/icons/commission.svg';
import mcAxios from '../../../../common/axios';

export type ProductCardProps = {
  key?: string;
  productId?: string;
  productIdToUpdate?: string;
  imageUrl?: string;
  name?: string;
  brand?: string;
  badgeUrl?: string;
  view?: number;
  like?: number;
  purchase?: number;
  commision?: number;
  onEditStock?: () => void;
  onEditDetail?: () => void;
  onMoreOptions?: () => void;
};

function ProductCard(props: ProductCardProps) {

  const [stock, setStock] = useState(0)

  useEffect(()=>{
    refreshStock()
  },[])

  useEffect(()=>{
    // console.log("this is fire",props.productIdToUpdate)
    if(props.productIdToUpdate === props.productId){
      refreshStock()
    }
  },[props.productIdToUpdate])

  function refreshStock(){
    mcAxios.post("/merchant/Product/Stock/List", {productId: props.productId, startIndex: 0, limit: 9999}).then((res)=>{
      let data = res.data.data
      let total = 0
      for(let variant of data.variants){
        total += variant.stock
      }
      setStock(total)
    }).catch((e)=>{
      console.error(e)
    })
  }

  return (
    <div tw="w-full px-4">
      <div tw="p-4 bg-white rounded-xl shadow items-center space-x-1">
        <div tw="flex">
          <div tw="shrink-0 rounded-xl overflow-hidden">
            <img
              tw="h-24 w-24 object-contain"
              src={props.imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src="https://images.app.thefepi.com/app/contents/945a520396bd5d48aac1c2608ad3b98b.jpeg.webp";
              }}
            />
          </div>
          <div tw="shrink mx-3 overflow-hidden">
            <p tw="text-black w-full whitespace-nowrap overflow-hidden text-ellipsis">{props.name}</p>
            <p>
              <span tw="font-semibold text-black">Brand: </span>
              {props.brand}
            </p>
            <p tw="text-black mb-1">Stock: {stock}</p>
            <div tw="flex">
              <img 
                tw="h-5"
                src={props.badgeUrl} 
              />
            </div>
          </div>
        </div>

        <div tw="flex mt-3">
          <div tw="flex w-20 items-center">
            <IconView />
            <p tw="text-slate-500 pl-2">{props.view}</p>
          </div>
          <div tw="flex w-20 items-center">
            <IconHeart />
            <p tw="text-slate-500 pl-2">{props.like}</p>
          </div>
          <div tw="flex w-20 items-center">
            <IconPurchase />
            <p tw="text-slate-500 pl-2">{props.purchase}</p>
          </div>
          <div tw="flex w-20 items-center">
            <IconComisson />
            <p tw="text-slate-500 pl-2">{props.commision}%</p>
          </div>
        </div>

        <div tw="flex mt-2">
          <Button onClick={() => props.onEditStock?.()} variant="outline" tw="w-80 h-12 p-1">
            Edit Stock
          </Button>
          <Button onClick={() => props.onEditDetail?.()} variant="outline" tw="w-80 h-full ml-2 p-2">
            Edit Detail
          </Button>
          <Button onClick={() => props.onMoreOptions?.()} variant="outline" tw="w-32 h-full ml-2 p-2">
            ⋮
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
