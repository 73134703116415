import React, {forwardRef} from 'react';
import * as Label from '@radix-ui/react-label';
import tw from 'twin.macro';
import {NOOP} from '../../constants';

export type NumberInputProps = Omit<React.InputHTMLAttributes<HTMLFieldSetElement>, 'onChange'> & {
  label?: string;
  value?: string | number;
  required?: boolean;
  isDisabled?: boolean;
  onChange?: (value?: string) => void;
  error?: string;
};

function NumberInput(props: NumberInputProps, ref: any) {
  const {label, value = '', onChange = NOOP, isDisabled, required, error, ...rest} = props;

  return (
    <fieldset tw="w-full" {...rest}>
      {label && (
        <Label.Root htmlFor={rest.id} tw="font-semibold text-sm">
          {label}
          {required && ' *'}
        </Label.Root>
      )}

      <input
        type="number"
        ref={ref}
        aria-disabled={isDisabled}
        disabled={isDisabled}
        tw="h-[53px] px-3 w-full border-textTertiary border rounded-lg text-sm focus:outline-2 outline-primary text-center"
        css={[error && tw`border-red-600 focus:outline-red-600`, isDisabled && tw`bg-gray-200`]}
        value={value}
        onChange={e => onChange(e.target.value)}></input>

      <p tw="text-red-600 text-xs mt-1">{error}</p>
    </fieldset>
  );
}

export default forwardRef(NumberInput);
