import React from 'react';
import GlobalStyles from './common/components/global-styles';
import Routes from './routes';
import "./styles/tailwindcss/tailwind.css";

const App = () => (
  <>
    <GlobalStyles />
    <Routes />
  </>
);

export default App;
