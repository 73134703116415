import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import ProgressBar from "./components/progress-bar"
import SizeField from "./components/size-field"
import { AddProductLocalStorageAddress } from "./constants"
import mcAxios from "../../common/axios"
import { Measurement, MeasurementItem } from "./components/product-upload/product-upload-util"
import LoadingWidget from "../../common/components/LoadingWidget"
import { normalizeAddProductParameters } from "./components/product-upload/api"

// const productFormData = getLocalStorageObject(AddProductLocalStorageAddress)

function UploadProductPage5() {

    const navigate = useNavigate()

    const [product, setProduct] = useState(getLocalStorageObject(AddProductLocalStorageAddress))
    const [measurementItems, setMeasurementItems] = useState<MeasurementItem[]>([])
    const [measurements, setMeasurements] = useState<Measurement[]>([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let newProduct = {...product}
        // console.log("productFormData")
        console.log("newProduct",newProduct)
        mcAxios.post("/merchant/Product/Measurement/List", {categoryId: newProduct.categoryId}).then((res)=>{
            let fetchedMeasurementItems = res.data.data.measurementItems
            // let fetchedMeasurementItems = [
            //     {
            //         id: "1",
            //         label: "Sleeve",
            //         unit: "cm"
            //     },
            //     {
            //         id: "2",
            //         label: "Hem",
            //         unit: "m"
            //     },
            //     {
            //         id: "3",
            //         label: "Shoulder",
            //         unit: "km"
            //     },
            // ]
            // console.log("measurementItems",fetchedMeasurementItems)
            setMeasurementItems(fetchedMeasurementItems)
            let newMeasurements = []
            if (!!newProduct.sizes) {
                for (let size of newProduct.sizes) {
                    // loop for matching size
                    let existingMeasurement = undefined
                    for (let measurement of (newProduct?.measurements || [])) {
                        if (measurement.size === size) {
                            existingMeasurement = measurement
                            break
                        }
                    }
                    // populate measurement items
                    let measurementItems = []
                    for (let fetchedMeasurementItem of fetchedMeasurementItems) {
                        // loop for matching item
                        let existingMeasurementItem = undefined
                        for (let candidateMeasurementItem of (existingMeasurement?.items || [])) {
                            if (candidateMeasurementItem.label === fetchedMeasurementItem.label) {
                                existingMeasurementItem = candidateMeasurementItem
                                break
                            }
                        }
                        let measurementItem = {
                            ...fetchedMeasurementItem,
                            value: existingMeasurementItem?.value || ""
                        }
                        measurementItems.push(measurementItem)
                    }
                    let measurement = {
                        size: size,
                        items: measurementItems
                    }
                    newMeasurements.push(measurement)
                }
            }
            setMeasurements(newMeasurements)
            setIsLoading(false)
        }).catch(e => {
            setIsLoading(false)
            // please comment. this is a temporary solution on server error
            // let fetchedMeasurementItems = [
            //     {
            //         "label": "topLength",
            //         "id": "TOPLENGTH",
            //         "unit": "cm"
            //     },
            //     {
            //         "label": "topShoulder",
            //         "id": "TOPSHOULDER",
            //         "unit": "cm"
            //     },
            //     {
            //         "label": "topBust",
            //         "id": "TOPBUST",
            //         "unit": "cm"
            //     },
            //     {
            //         "label": "topSleeve",
            //         "id": "TOPSLEEVE",
            //         "unit": "cm"
            //     },
            //     {
            //         "label": "topHem",
            //         "id": "TOPHEM",
            //         "unit": "cm"
            //     }
            // ]
            // setMeasurementItems(fetchedMeasurementItems)
            // let newMeasurements = []
            // if (!!newProduct.sizes) {
            //     for (let size of newProduct.sizes) {
            //         // loop for matching size
            //         let existingMeasurement = undefined
            //         for (let measurement of (newProduct?.measurements || [])) {
            //             if (measurement.size === size) {
            //                 existingMeasurement = measurement
            //                 break
            //             }
            //         }
            //         // populate measurement items
            //         let measurementItems = []
            //         for (let fetchedMeasurementItem of fetchedMeasurementItems) {
            //             // loop for matching item
            //             let existingMeasurementItem = undefined
            //             for (let candidateMeasurementItem of (existingMeasurement?.items || [])) {
            //                 if (candidateMeasurementItem.label === fetchedMeasurementItem.label) {
            //                     existingMeasurementItem = candidateMeasurementItem
            //                     break
            //                 }
            //             }
            //             let measurementItem = {
            //                 ...fetchedMeasurementItem,
            //                 value: existingMeasurementItem?.value || ""
            //             }
            //             measurementItems.push(measurementItem)
            //         }
            //         let measurement = {
            //             size: size,
            //             items: measurementItems
            //         }
            //         newMeasurements.push(measurement)
            //     }
            // }
            // setMeasurements(newMeasurements)
            // until here
            console.error(e)
        })
        setProduct(newProduct)
    }, [])

    function handleSaveAsDraft() {
        mcAxios.post("/merchant/Product/Add", {
            type: "DRAFT",
            ...normalizeAddProductParameters(product)
        }).then((res)=>{
            removeLocalStorageObject(AddProductLocalStorageAddress)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', '\"DRAFT\"')
            navigate(`/products`)
        })
    }

    function handleSubmit() {
        let newProduct = {...product, measurements: measurements, measurementItems: measurementItems}
        setLocalStorageObject(AddProductLocalStorageAddress, newProduct)
        navigate("/product/upload/6")
    }

    return (
        <>
            <Page tw="px-6 py-14 pb-20">
                <Header onGoBack={() => navigate("/product/upload/4")} title="Product Detail"></Header>
                <ProgressBar tw="mb-6" title="Measurement" current={5} total={6} />
                <div tw="h-5"></div>

                {isLoading ? <LoadingWidget /> :
                    <>{measurementItems.length > 0 ?
                        product.sizes?.map((size: string) => (
                            <SizeField
                                label={`Size ${size}`}
                                size={size}
                                fields={measurementItems || {}}
                                values={measurements.find(measurement=>measurement.size===size)?.items}
                                onChange={(val: MeasurementItem[]) => {
                                    let newMeasurements = [...measurements]
                                    for (let newMeasurement of newMeasurements) {
                                        if (newMeasurement.size === size) {
                                            newMeasurement.items = val
                                            break
                                        }
                                    }
                                    setProduct({ ...product, measurement: newMeasurements })
                                }}
                            />
                        ))
                        :
                        <>
                            <p>{`There's no sizing for ${product.group} - ${product.category}.`}</p>
                            <p>You can continue.</p>
                        </>
                    }</>
                }

                <FloatingFooter tw="flex gap-3">
                    <Button onClick={handleSaveAsDraft} variant="outline">Save as draft</Button>
                    <Button onClick={handleSubmit}>Next</Button>
                </FloatingFooter>
            </Page >
        </>
    )
}

export default UploadProductPage5