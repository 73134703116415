import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Document, pdfjs, Page as PDFPAge} from 'react-pdf';
import Page from '../../common/components/page/page';
import Header from '../../common/components/header/header';
import FloatingFooter from '../../common/components/floating-footer/floating-footer';
import Button from '../../common/components/button/button';
import {Order} from '../../common/entities/order';
import PulseLoader from '../../common/components/loaders/pulse';
import useShippingLabel from './hooks/use-shipping-label';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ShippingLabelPreviewPage() {
  const {id: orderId} = useParams();

  const [numPages, setNumPages] = useState<number>();

  const shippingLabel = useShippingLabel(
    new Order({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: orderId!,
    }),
    {
      enabled: Boolean(orderId),
    },
  );

  const [printUrl, setPrintUrl] = useState<string>();

  async function onDocumentLoadSuccess(result: any) {
    const data = await result.getData();
    const blob = new Blob([data], {type: 'application/pdf'});
    setPrintUrl(URL.createObjectURL(blob));
    setNumPages(result.numPages);
  }

  return (
    <Page tw="bg-white">
      <Header title="Print Label" />
      <div tw="pt-12 pb-[5.25rem] h-screen flex justify-center items-center py-3 overflow-auto">
        {shippingLabel.isLoading ? (
          <PulseLoader theme="light" />
        ) : (
          <Document onLoadSuccess={onDocumentLoadSuccess} file={shippingLabel.data?.pdfUrl}>
            {Array.from(new Array(numPages), (el, index) => (
              <PDFPAge key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </div>

      {!shippingLabel.isLoading && shippingLabel.data && (
        <FloatingFooter tw="flex gap-3 p-4">
          <Button as="a" download href={shippingLabel.data?.pdfUrl} variant="outline">
            Download
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              if (printUrl) {
                const alreadyExisitingIframe = document.getElementById('print-pdf-iframe');
                const iframe = alreadyExisitingIframe || document.createElement('iframe');

                const _iframe = iframe as HTMLIFrameElement;

                document.body.appendChild(iframe);

                _iframe.style.display = 'none';
                _iframe.id = 'print-pdf-iframe';
                _iframe.src = printUrl;
                iframe.onload = function () {
                  setTimeout(function () {
                    _iframe.focus();
                    _iframe.contentWindow?.print();
                  }, 1);
                };
              }
            }}>
            Print
          </Button>
        </FloatingFooter>
      )}
    </Page>
  );
}

export default ShippingLabelPreviewPage;
