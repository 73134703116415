export const NOOP = () => void 0;

export const CommissionType = Object.freeze({
  BULK: 'BULK',
  SINGLE: 'SINGLE',
});

export const COMMON_REQUIRED_FIELD_ERROR_MESSAGE = 'Required field';

export type CommissionType = keyof typeof CommissionType;

// export CommissionType
