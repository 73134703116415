import React from "react";

function CashoutBankAccount() {
  return (
    <div tw="w-full p-4 rounded-xl bg-[#E8EBF5] flex border-[#9598FF] border">
      <div tw="flex flex-col w-full">
        <div tw="flex flex-row justify-between w-full">
          <p tw="text-sm font-medium">Jane Smith</p>
          <p tw="text-[0.6875rem] font-normal text-[#999]">Modify</p>
        </div>
        <p tw="text-xs font-normal">BRI-0123446578</p>
      </div>
    </div>
  );
}

export default CashoutBankAccount
