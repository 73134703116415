import {UseQueryOptions, useQuery} from 'react-query';
import {Order} from '../../../common/entities/order';
import {getOrderDetails} from '../api';
import {Billing} from '../../../common/entities/billing';

const useOrderDetails = (order: Order, options?: UseQueryOptions<Order, unknown, Order, string>) =>
  useQuery({
    queryKey: ['order', order.id],
    queryFn: async () => {
      const response = await getOrderDetails(order);
      const _order = Order.cast(response);
      _order.id = order.id;
      _order.billing = Billing.cast(response?.billing);
      return _order;
    },
    ...options,
  });

export default useOrderDetails;
