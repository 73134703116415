import React, {
  createContext,
  useContext,
  useRef,
  useImperativeHandle,
  useCallback,
  useState,
  useMemo,
  forwardRef,
  useEffect,
} from 'react';
import * as Portal from '@radix-ui/react-portal';
import { useSpring, animated } from '@react-spring/web';
import { Snackbar } from '../../entities/snackbar';
import tw from 'twin.macro';

export type SnackbarContextType = {
  queue: {
    id?: string;
  }[];
  create: (snackbar: Snackbar) => void;
};

const SnackbarContext = createContext<SnackbarContextType>(null as any);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  return context;
};

export const withSnackbar = (WrappedComponent: React.FC<any>) => {
  class Container extends React.Component {
    render() {
      return (
        <SnackbarRoot>
          <WrappedComponent />
        </SnackbarRoot>
      );
    }
  }

  return Container;
};

export type SnackbarComponentProps = {
  message?: string;
  id: string;
  onEndOfLife?: () => void;
  onStartOfLife?: () => void;
  type?: string;
};

function _SnackbarComponent(props: SnackbarComponentProps, ref: any) {
  const { id, message, onEndOfLife, onStartOfLife, type } = props;

  const [isVisible, setIsVisible] = useState(true);

  const [springProps, springApi] = useSpring(
    () => ({
      config: { tension: 340, friction: 20, precision: 0.1 },
      from: { opacity: 0, x: 0, y: 0 },
      to: { opacity: 1, x: 0, y: -16 },
    }),
    [],
  );

  useEffect(() => {
    setTimeout(() => {
      springApi({
        opacity: 0,
        y: 16,
        onRest: () => {
          onEndOfLife?.();
        },
      });
    }, 4000);
  }, []);

  useImperativeHandle(ref, () => ({
    kill: ({ next }) => {
      springApi({
        opacity: 0,
        y: 16,
        onRest: () => {
          onEndOfLife?.();
          next();
        },
      });
    },
  }));

  return (
    <animated.div
      style={springProps}
      tw="p-4 absolute bottom-0 left-4 right-4 rounded-lg text-white text-sm"
      css={type == "danger" ? tw`bg-[#f14100]` : type == "warning" ? tw`bg-yellow-500` : tw`bg-blue-500`}>
      {message}
    </animated.div>
  );
}

const SnackbarComponent = forwardRef(_SnackbarComponent);

export type SnackbarRootProps = {
  children?: React.ReactNode;
};

function SnackbarRoot(props: SnackbarRootProps) {
  const { children } = props;

  const id = useRef(0);

  const [queue, setQueue] = useState<Snackbar[]>([]);

  const refMap = useMemo(() => new WeakMap(), []);
  const cancelMap = useMemo(() => new WeakMap(), []);

  const addSnackbar = useCallback(
    (snackbar: Snackbar) => {
      setQueue(prev => [...prev, snackbar]);
    },
    [setQueue],
  );

  const removeSnackbar = useCallback(
    (snackbar: Snackbar) => {
      setQueue(prev => prev.filter(x => x.id !== snackbar.id));
    },
    [setQueue],
  );

  const create = useCallback(
    (snackbar: Snackbar) => {
      snackbar.id = id.current++;
      if (queue.length > 0) {
        const x = refMap.get(queue[0]);
        x.kill({
          next: () => addSnackbar(snackbar),
        });
      } else {
        addSnackbar(snackbar);
      }
    },
    [addSnackbar, queue, refMap],
  );

  return (
    <SnackbarContext.Provider value={{ queue, create }}>
      {children}

      <Portal.Root asChild>
        <div
          tw="fixed bottom-0 top-0 pointer-events-none w-full max-w-mvw left-1/2 -translate-x-1/2"
          className="snackbar-container">
          {queue.map(snackbar => (
            <SnackbarComponent
              key={snackbar.id}
              message={snackbar.message}
              id={snackbar.id || ''}
              ref={(ref: any) => refMap.set(snackbar, ref)}
              onEndOfLife={() => {
                removeSnackbar(snackbar);
                // console.log('killing ' + snackbar.id);
              }}
              type={snackbar.type}
            />
          ))}
        </div>
      </Portal.Root>
    </SnackbarContext.Provider>
  );
}
