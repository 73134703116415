import React from 'react';
import { Route, Routes as BrowserRoutes } from 'react-router-dom';
import ErrorPage from './features/errors/error-page.lazy';
import AddProductPage from './features/product/add-product-page.lazy';
import OrderListPage from './features/order/order-list-page.lazy';
import EditProductPage from './features/product/edit-product-page.lazy';
import WalletSummary from './features/wallet/wallet-page';
import CashoutSummary from './features/cashout/cashout-summary-page';
import FormBankAccount from './features/cashout/form-bank-account/form-bank-account-page';
import ProducListPage from './features/product/product-list-page';
import OrderDetailsPage from './features/order/order-details-page';
import ShippingLabelPreviewPage from './features/order/shipping-label-preview-page';
import LoginPage from './features/user/login/login-page';
import LoginSsoPage from './features/user/login/login-sso-page';
import ForgotPasswordPage from './features/user/login/forgot-password-page';
import RegisterPage from './features/user/register/register-page';
import RegisterVerifyEmailPage from './features/user/register/register-verify-email-page';
import RegisterSetPasswordPage from './features/user/register/register-set-password-page';
import RegisterMerchantInfo from './features/user/register/register-merchant-info-page';
import RegisterSuccessPage from './features/user/register/register-success-page';
import RegisterWaitingPage from './features/user/register/register-waiting-page';
import UploadProductPage1 from './features/product/upload-product-page-1';
import UploadProductPage2 from './features/product/upload-product-page-2';
import UploadProductPage3 from './features/product/upload-product-page-3';
import UploadProductPage4 from './features/product/upload-product-page-4';
import UploadProductPage5 from './features/product/upload-product-page-5';
import UploadProductPage6 from './features/product/upload-product-page-6';
import { Navigate } from 'react-router-dom';
import LogoutPage from './features/user/logout/logout-page';
import AddVariantPage from './features/product/variant/add-variant-page';
import EditProductPageAlternative from './features/product/edit/edit-product-page-alternative';
import EditStockPage from './features/product/edit/pages/edit-stock-page';
import MeasurementPage from './features/product/edit/pages/measurement-page';
import HomePage from './features/home/home-page';
import AccountPage from './features/user/account/account-page';

export default function Routes() {
  return (
    <BrowserRoutes>
      <Route path="/*" element={
        <ErrorPage />
      } />
      <Route path="/" element={<HomePage />} />
      <Route path="/product/upload" element={<UploadProductPage1 />} />
      <Route path="/product/upload/2" element={<UploadProductPage2 />} />
      <Route path="/product/upload/3" element={<UploadProductPage3 />} />
      <Route path="/product/upload/4" element={<UploadProductPage4 />} />
      <Route path="/product/upload/5" element={<UploadProductPage5 />} />
      <Route path="/product/upload/6" element={<UploadProductPage6 />} />
      <Route path="/product/add" element={<AddProductPage />}></Route>
      {/* <Route path="/product/:id/edit" element={<EditProductPage />}></Route> */}
      <Route path="/product/:id/edit" element={<EditProductPageAlternative />}></Route>
      <Route path="/product/:id/edit/variant" element={<AddVariantPage />}></Route>
      <Route path="/product/:id/edit/stock" element={<EditStockPage />}></Route>
      <Route path="/product/:id/edit/measurement" element={<MeasurementPage />}></Route>
      <Route path="/orders" element={<OrderListPage />}></Route>
      <Route path="/products" element={<ProducListPage />}></Route>
      <Route path="/orders/:id" element={<OrderDetailsPage />}></Route>
      <Route path="/orders/:id/shipping-label" element={<ShippingLabelPreviewPage />}></Route>
      <Route path="/wallet/summary" element={<WalletSummary />}></Route>
      <Route path="/cashout/summary" element={<CashoutSummary />}></Route>
      <Route path="/form/bankAccount" element={<FormBankAccount />}></Route>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/loginSso" element={<LoginSsoPage />}></Route>
      <Route path="/forgotPassword" element={<ForgotPasswordPage />}></Route>
      <Route path="/register" element={<RegisterPage />}></Route>
      <Route path="/register/verifyEmail" element={<RegisterVerifyEmailPage />}></Route>
      <Route path="/register/setPassword" element={<RegisterSetPasswordPage />}></Route>
      <Route path="/register/merchantInfo" element={<RegisterMerchantInfo />}></Route>
      <Route path="/register/success" element={<RegisterSuccessPage />}></Route>
      <Route path="/register/waiting" element={<RegisterWaitingPage />}></Route>
      <Route path="/account" element={<AccountPage />} />
      <Route path="/logout" element={<LogoutPage />} />
    </BrowserRoutes>
  );
}
