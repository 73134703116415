import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import tw from "twin.macro"
import Button from "../../../common/components/button/button"
import FloatingFooter from "../../../common/components/floating-footer/floating-footer"
import Header from "../../../common/components/header/header"
import Page from "../../../common/components/page/page"
import TextInput from "../../../common/components/text-input/text-input"
import { CommissionType } from "../../../common/constants"
import ChevronRight from '../../../common/icons/chevron-right.svg'
import InformationIcon from '../../../common/icons/information.svg'
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../../framework/utils"
import FormSection from "../components/product-form/components/form-section"
import { EditProductLocalStorageAddress } from "../constants"
import { FabricMaterial, ProductTag, firstOrNothing, formatCurrency } from "../utils"
import BrandPickerSheet from "../../brand/components/brand-picker-sheet"
import { Brand } from "../../../common/entities"
import mcAxios from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"
import ImagePicker, { ImagePickerImage } from "../../../common/components/forms/image-picker"
import CategorySheet from "./components/category-sheet"
import MeasurementField from "./components/measurement-field"
import { updateMeasurements } from "./utils/edit-product-utils"
import { uploadThefepiCommonFile } from "../../../common/utils"
import MultiplePicker from "../../../common/components/multiple-picker/multiple-picker"
import CategoryRadioGroup from "../components/category-radio-group"

//const productFormData = getLocalStorageObject(EditProductLocalStorageAddress)
const measurementItems = ["Length", "Shoulder", "Bust", "Sleeve", "Hem"]

function EditProductPageAlternative() {
    const navigate = useNavigate()
    const snackbar = useSnackbar()
    const { id } = useParams()
    const productFormData = getLocalStorageObject(EditProductLocalStorageAddress, id)

    // const [url, setUrl] = useState<URL>()
    const [product, setProduct] = useState({
        name: "",
        productId: "",
        brand: new Brand(),
        price: "",
        discount: "",
        discountedPrice: "",
        bulkCommission: "",
        singleCommission: "",
        commissionType: 'BULK',
        description: "",
        weight: "",
        tags: [
            {
                label: "gender",
                selectedIds: []
            },
            {
                label: "style",
                selectedIds: []
            }
        ],
        group: "",
        category: "",
        subCategories: [],
        categorySummary: "",
        colors: [],
        sizes: [],
        ...productFormData,
    })
    const [images, setImages] = useState<ImagePickerImage[]>([])
    const [tags, setTags] = useState<ProductTag[]>([])
    const [fabricMaterial, setFabricMaterial] = useState<FabricMaterial>()
    const [errors, setErrors] = useState({
        name: "",
        brand: "",
        price: "",
        discount: "",
        category: "",
        variant: "",
        stock: ""
    })
    const [sheets, setSheets] = useState({
        category: false,
    })

    const [isBrandPickerSheetOpen, setIsBrandPickerSheetOpen] = useState(false)

    useEffect(() => {
        mcAxios.post('/merchant/Product/Detail', {productId: id})
        .then((response) => {
            let fetchedProduct = response.data.data
            let categorySummary = [
                product.group,
                product.category,
                product.subCategories.join(",")
            ].filter(summary => !!summary)
            let updatedProduct = {
                ...fetchedProduct,
                bulkCommission: fetchedProduct.bulkCommision,
                singleCommission: fetchedProduct.singleCommision,
                categorySummary: categorySummary.length > 0 && categorySummary.join("/"),
                variantSummary: [...product.colors, ...product.sizes].join(", "),
                measurements: updateMeasurements(product.measurements, product.sizes, measurementItems),
                categoryId: fetchedProduct.selectedCategoryId,
                groupId: fetchedProduct.selectedGroupId,
                // groupId: "FASHION",
                // selectedGroupId: "FASHION",
                subCategoryIds: fetchedProduct.selectedSubCategoryIds
            }
            setLocalStorageObject(EditProductLocalStorageAddress, updatedProduct, id)
            // Images
            // fetchedProduct.imageUrls = ['https://www.cityheadshots.com/uploads/5/1/2/1/5121840/editor/tyronelrobinson.jpg?1592228415']
            let newImages: ImagePickerImage[] = []
            for(let imageUrl of fetchedProduct.imageUrls){
                const imageId = Math.round(Math.random() * 1000).toString();
                newImages.push({
                    url: imageUrl,
                    state: 'idle',
                    id: imageId,
                    onDeleted: (id?: string) => {
                        setImages(prevImages => {
                            const newImages = prevImages.filter(image => image.id !== id);
                            // console.log("images", newImages);
                            return newImages;
                        });
                    }
                })
            }
            setImages(newImages)
            setLocalStorageObject("imageUpload",newImages)
            setProduct(updatedProduct)
            setLocalStorageObject(EditProductLocalStorageAddress, updatedProduct, id)
            setTags(fetchedProduct.tags)
            setFabricMaterial(fetchedProduct.fabricMaterial)
        }).finally(()=>{ // Stocks
            mcAxios.post("/merchant/Product/Stock/List", {productId: id, startIndex: 0, limit: 99999}).then(res=>{
                console.log("fetched stocks",res.data.data)
            }).finally(()=>{ // Measurements
                //mcAxios.post("/merchant/Product/Measurement/List", {})
            })
        })
        
    }, [])

    // useEffect(() => {
    //     console.log(product)
    // }, [product])

    useEffect(() => {
        if (product.price && product.discount) {
            setProduct({
                ...product,
                discountedPrice: Math.ceil(
                    parseInt(product.price) * (100 - parseInt(product.discount)) / 100
                ).toString()
            })
        }
    }, [product.price, product.discount])

    // useEffect(()=>{
    //     console.log("images",images)
    // },[images])

    function handleDeleteImage(id: number | string) {
        let newImages = []
        // console.log("newImages", images)
        // console.log("deleting image ", id)
        for(let image of images){
            if(image.id!=id){
                newImages.push(image)
            }
        }
        // console.log("newImages", newImages)
        // setImages(newImages)
    }

    function handlePickBrand() {
        setIsBrandPickerSheetOpen(true)
    }

    function handleDiscountChange(discount: number) {
        let newDiscount = discount
        if (isNaN(discount))
            newDiscount = 0
        if (newDiscount <= 100) {
            setProduct({ ...product, discount: newDiscount.toString() })
        }
    }

    function handleCommissionChange(val: string) {
        let newValue = parseInt(val?.replace("%", "") || "")
        if (isNaN(newValue))
            newValue = 0
        if (newValue <= 100) {
            setProduct({ ...product, singleCommission: newValue.toString() })
        }
    }

    function handleSubmit(type: "DRAFT" | "SUBMIT") {
        setLocalStorageObject(EditProductLocalStorageAddress, product, id)

        // Tags
        let selectedTags = []
        let productTags: ProductTag[] = tags || []
        for(let tag of productTags){
            let selectedIds = []
            for(let tagItem of tag.items){
                if(tagItem.isSelected){
                    selectedIds.push(tagItem.id)
                }
            }
            selectedTags.push({
                label: tag.label,
                selectedIds: selectedIds
            })
        }

        // Fabric Material
        let selectedDetails = []
        for(let detail of (fabricMaterial?.details || [])){
            for(let item of detail.items){
                if(item.isSelected){
                    selectedDetails.push({
                        label: detail.label,
                        selectedValue: item.label
                    })
                }
            }
        }

        // Stocks
        let existingStocks = { ...product.stocks }
        let stocks = []
        for (let [key, value] of Object.entries(existingStocks)) {
            let color = ""
            for (let productColor of product.colors) {
                if (key.includes(productColor)) {
                    color = productColor
                }
            }
            let size = ""
            for (let productSize of product.size) {
                if (key.includes(productSize)) {
                    size = productSize
                }
            }
            stocks.push({
                variants: {
                    color: color,
                    size: size
                },
                count: parseInt(value + "")
            })
        }

        const productData = {
            fpAuth: localStorage.getItem("fpAuth"),
            productId: id,
            type: type,
            name: product.name,
            brandId: product.brand.id,
            price: parseInt(product.price),
            discount: parseInt(product.discount),
            commision: {
                type: product.commissionType,
                value: parseInt(product.commissionType === CommissionType.BULK ? product.bulkCommission : product.singleCommission)
            },
            weight: parseInt(product.weight),
            description: product.description,
            imageUrls: images.map(image=>image.url),
            selectedGroupId: product.groupId,
            selectedCategoryId: product.categoryId,
            selectedSubCategoryIds: product.subCategoryIds,
            categoryId: product.selectedCategoryId,
            selectedTags,
            fabricMaterial: {
                name: fabricMaterial?.name,
                selectedDetails: selectedDetails
            },
            measurements: product.measurements,
            stocks: stocks
        }

        console.log("productData", productData)
        // return

        mcAxios.post("/merchant/Product/Update", productData)
            .then(() => {
                removeLocalStorageObject(EditProductLocalStorageAddress, id)
                window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', type === "DRAFT" ? "\"DRAFT\"" : "\"LIVE\"")
                navigate(`/products`)
            }).catch((error) => {
                snackbar.create(
                    new Snackbar({
                        message: error.response.data?.message || error.response?.data,
                        type: 'danger',
                    }),
                );
            })
    }

    return (
        <Page tw="px-4 py-14 pb-20">
            <Header onGoBack={() => navigate("/products")} title="Product Detail"></Header>

            {/* Product Photo */}
            <ImagePicker
                label="Product Photo"
                required
                tw="mb-3"
                images={images}
                onFileAdded={(file: any) => {
                    // console.log(file);
                    const imageId = Math.round(Math.random() * 1000).toString();
                    const newImage: ImagePickerImage = {
                        url: URL.createObjectURL(file as Blob),
                        state: "uploading",
                        id: imageId,
                        onDeleted: (id?: string) => {
                            setImages(prevImages => {
                                const newImages = prevImages.filter(image => image.id !== id);
                                // console.log("images", newImages);
                                return newImages;
                            });
                        }
                    };

                    // Add new image in uploading state
                    setImages(prevImages => [...prevImages, newImage]);

                    // Upload the file
                    uploadThefepiCommonFile(file).then(url => {
                        setImages(prevImages => {
                            const updatedImages: ImagePickerImage[] = prevImages.map(image =>
                                image.id === imageId ? { ...image, url, state: "idle" } : image
                            );
                            setLocalStorageObject("imageUpload", updatedImages);
                            return updatedImages;
                        });
                    });
                }}
            ></ImagePicker>
            <div tw="flex gap-1 py-1 px-2 rounded-lg text-xs text-neutral-600 mb-4 items-center w-fit">
                {/* <div tw="w-4"><InformationIcon /></div> */}
                <div>
                    <p><b>*best size: </b>395px <b>x</b> 535px</p>
                </div>
            </div>
            {/* <p>*best size: 395px by 535px</p> */}

            {/* Product Name */}
            <TextInput tw="mb-3" label="Product Name" required name="productName" value={product.name} onChange={(val) => setProduct({ ...product, name: val || "" })} />

            {/* Product ID */}
            <TextInput tw="mb-3" label="Product ID" isDisabled={true} value={id} />

            {/* Brand */}
            <FormSection tw="mb-4" label="Brand" required>
                <button
                    type="button"
                    onClick={handlePickBrand}
                    tw="h-12 p-3 w-full flex items-center rounded-lg border-textTertiary border bg-white"
                    css={[
                        // brandInput.isDisabled && tw`bg-gray-200`, 
                        errors.brand && tw`border border-red-600`
                    ]}
                >
                    <div tw="flex-1 items-start flex text-sm">{product.brand?.name || <p tw="text-sm text-gray-300">{"Select"}</p>}</div>
                    <div tw="flex-shrink-0 mr-2">
                        <ChevronRight tw="w-2" />
                    </div>
                </button>
            </FormSection>
            <BrandPickerSheet
                isOpen={isBrandPickerSheetOpen}
                onValueChange={(val) => setProduct({ ...product, brand: val || new Brand() })}
                onOpenChange={() => setIsBrandPickerSheetOpen(false)}
                onBackClick={() => setIsBrandPickerSheetOpen(false)}
            />

            {/* Price */}
            <TextInput
                label="Price (Rp)" required
                value={formatCurrency(parseInt(product.price))}
                error={errors.price}
                onChange={(val) => setProduct({ ...product, price: val?.replace(/\./g, "") || "" })}
                tw="mb-3"
            />

            {/* Discount */}
            <TextInput
                label="Discount (%)"
                type="number"
                value={product.discount}
                onChange={(val) => handleDiscountChange(parseInt(val || ""))}
                tw="mb-3"
            />

            {/* Discounted Price */}
            <TextInput
                label="Discounted Price (Rp)"
                isDisabled={true}
                value={formatCurrency(parseInt(product.discountedPrice))}
                tw="mb-3"
            />

            {/* Commission */}
            <FormSection label="Reseller Commission (%)" required>
                <div tw="flex gap-1 py-1 px-2 rounded-lg bg-neutral-100 text-xs text-neutral-600 mb-4">
                    <div tw="w-6"><InformationIcon /></div>
                    <div>
                        <p><b>Bulk commission: </b>Quickly set flat commission rate for all products.</p>
                        <p><b>Single commission: </b>Set commission only for particular product.</p>
                    </div>
                </div>
                <fieldset id="commissionTypeRadioGroup">
                    <div tw="flex items-center gap-3 mb-3 justify-between"
                        onClick={() => setProduct({ ...product, commissionType: CommissionType.BULK })}
                    >
                        <div tw="flex justify-start gap-3">
                            <input id="radiobulkCommission" name="commissionTypeRadioGroup" type="radio" value={CommissionType.BULK}
                                checked={product.commissionType === CommissionType.BULK}
                            />
                            <span onClick={() => {
                                $('#radiobulkCommission').trigger("click")
                            }}>Bulk commission</span>
                        </div>
                        <div tw="flex justify-end">
                            <TextInput value={`${product.bulkCommission ?? ""}%`} isDisabled={true} tw="w-24" />
                        </div>
                    </div>
                    <div tw="flex items-center gap-3 mb-3 justify-between"
                        onClick={() => setProduct({ ...product, commissionType: CommissionType.SINGLE })}
                    >
                        <div tw="flex justify-start gap-3">
                            <input id="radioSingleCommission" name="commissionTypeRadioGroup" type="radio" value={CommissionType.SINGLE}
                                checked={product.commissionType === CommissionType.SINGLE}
                            />
                            <span onClick={() => { $('#radioSingleCommission').trigger("click") }}>Single commission</span>
                        </div>
                        <div tw="flex justify-end">
                            <TextInput
                                value={`${product.singleCommission ?? ""}%`}
                                // onClick={() => { $('#radioSingleCommission').trigger("click") }}
                                onChange={(val) => handleCommissionChange(val || "")}
                                tw="w-24"
                            />
                        </div>
                    </div>
                </fieldset>
            </FormSection>

            {/* Category */}
            <FormSection tw="mb-4" label="Category" required>
                <button
                    type="button"
                    onClick={() => setSheets({ ...sheets, category: true })}
                    tw="h-12 p-3 w-full flex items-center rounded-lg border-textTertiary border bg-white"
                    css={[
                        // brandInput.isDisabled && tw`bg-gray-200`, 
                        errors.category && tw`border border-red-600`
                    ]}
                >
                    <div tw="flex-1 items-start flex text-sm">{product.categorySummary || <p tw="text-sm text-gray-300">{"Select"}</p>}</div>
                    <div tw="flex-shrink-0 mr-2">
                        <ChevronRight tw="w-2" />
                    </div>
                </button>
            </FormSection>
            <CategorySheet productId={id || ""} isOpen={sheets.category} onChange={(val) => {
                setProduct({ ...product, categorySummary: val })
                setSheets({ ...sheets, category: false })
                // console.log(product)
            }}
                groupId={product.selectedGroupId}
                categoryId={product.selectedCategoryId}
                subCategoryIds={product.selectedSubCategoryIds}
            />

            {/* Product Description */}
            <TextInput
                label="Product Description"
                placeholder="Describe your product here..."
                multiline
                value={product.description}
                onChange={(val) => setProduct({ ...product, description: val || "" })}
                tw="mb-3"
            />

            {/* Weight */}
            <TextInput
                label="Weight (gr)" required
                type="number"
                value={product.weight}
                onChange={(val) => setProduct({ ...product, weight: val || "" })}
                tw="mb-3"
            />

            {/* Tags */}
            {tags.map((tag: ProductTag) => (
                <MultiplePicker
                    label={tag.label}
                    required={!tag.isOptional}
                    options={tag.items.map(item => item.label)}
                    values={tag.items.filter(item => item.isSelected).map(item => item.label)}
                    onChange={(val) => {
                        let newItems = [...tag.items]
                        for (let item of newItems) {
                            item.isSelected = val.includes(item.label)
                        }
                        tag.items = newItems
                        // console.log("newTags",tags)
                        setTags([...tags])
                    }}
                    maxSelected={tag.maxSelection}
                    tw="mb-5"
                />
            ))}

            {/* Variant */}
            <FormSection tw="mb-4" label="Variant">
                <button
                    type="button"
                    onClick={() => {
                        setLocalStorageObject(EditProductLocalStorageAddress, product, id)
                        navigate(`/product/${id}/edit/variant`)
                    }}
                    tw="h-12 p-3 w-full flex items-center rounded-lg border-textTertiary border bg-white"
                    css={[
                        // brandInput.isDisabled && tw`bg-gray-200`, 
                        errors.variant && tw`border border-red-600`
                    ]}
                >
                    <div tw="flex-1 items-start flex text-sm">{product.variantSummary || <p tw="text-sm text-gray-300">{"Input variant"}</p>}</div>
                    <div tw="flex-shrink-0 mr-2">
                        <ChevronRight tw="w-2" />
                    </div>
                </button>
            </FormSection>

            {/* Stock */}
            <FormSection tw="mb-4" label="Stock" required>
                <button
                    type="button"
                    onClick={() => {
                        setLocalStorageObject(EditProductLocalStorageAddress, product, id)
                        navigate(`/product/${id}/edit/stock`)
                    }}
                    tw="h-12 p-3 w-full flex items-center rounded-lg border-textTertiary border bg-white"
                    css={[
                        errors.stock && tw`border border-red-600`
                    ]}
                >
                    <div tw="flex-1 items-start flex text-sm">{product.stockSummary || <p tw="text-sm text-gray-300">{"Set stock"}</p>}</div>
                    <div tw="flex-shrink-0 mr-2">
                        <ChevronRight tw="w-2" />
                    </div>
                </button>
            </FormSection>

            {/* Measurement */}
            <FormSection label='Measurement'></FormSection>
            <MeasurementField
                measurements={product.measurements}
                onEdit={() => {
                    navigate(`/product/${id}/edit/measurement`)
                }}
            />
            <div tw="h-4"></div>

            {/* Fabric Material */}
            {fabricMaterial &&
                <>
                    <TextInput
                        label="Fabric Material"
                        value={fabricMaterial.name}
                        onChange={(val) => setFabricMaterial({ ...fabricMaterial, name: val || "" })}
                        tw="mb-4"
                    />
                    {fabricMaterial.details.map((detail) => (
                        <>
                            <FormSection label={detail.label}>
                                <CategoryRadioGroup
                                    identifier={detail.label}
                                    value={firstOrNothing(detail.items?.filter(item => item.isSelected).map(item => item.label))}
                                    options={detail.items?.map(item => item.label)}
                                    onChange={(val) => {
                                        for (let item of detail.items) {
                                            item.isSelected = item.label === val
                                        }
                                        setFabricMaterial({ ...fabricMaterial })
                                    }}
                                    withDividerLine={false}
                                    columns={3}
                                    fontSize="small"
                                />
                            </FormSection>
                            <div tw="mb-4"></div>
                        </>
                    ))}
                </>
            }

            {/* Footer */}
            <FloatingFooter tw="flex gap-3">
                <Button onClick={() => handleSubmit("DRAFT")} variant="outline">Save as draft</Button>
                <Button onClick={() => handleSubmit("SUBMIT")}>Submit</Button>
            </FloatingFooter>
        </Page>
    )
}

export default withSnackbar(EditProductPageAlternative)