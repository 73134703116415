import React from "react";
import styled, { keyframes } from "styled-components";

interface LoadingWidgetProps {
  // Props interface definition
}

const LoadingWidget: React.FC<LoadingWidgetProps> = () => {
  const dotPulseBefore = keyframes`
   0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
  `;

  const dotePulseKeyframes = keyframes`
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
  `;

  const dotePulseAfter = keyframes`
   0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
  `;

  const DotPulse = styled.div`
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #a0a0a0;
    color: #a0a0a0;
    box-shadow: 9999px 0 0 -5px;
    animation: ${dotePulseKeyframes} 1.5s infinite linear;
    animation-delay: 0.25s;

    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #a0a0a0;
      color: #a0a0a0;
      box-shadow: 9984px 0 0 -5px;
      animation: ${dotPulseBefore} 1.5s infinite linear;
      animation-delay: 0s;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #a0a0a0;
      color: #a0a0a0;
      box-shadow: 10014px 0 0 -5px;
      animation: ${dotePulseAfter} 1.5s infinite linear;
      animation-delay: 0.5s;
    }
  `;

  return (
    <div tw="h-9 w-full p-2 flex flex-row flex-wrap align-middle content-center items-center justify-center bg-white">
      <DotPulse className="dotPulse"></DotPulse>
    </div>
  );
};

export default LoadingWidget;
