import React, { useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import { Link, useNavigate } from "react-router-dom"
import TextInput from "../../../common/components/text-input/text-input"
import Button from "../../../common/components/button/button"
import { ValidateEmailTelp } from "../../../framework/utils"
import { fepiAxios } from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"

function LoginSsoPage() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (email != "")
            setIsEmailValid(ValidateEmailTelp(email))
    }, [email])

    useEffect(() => {
        setIsSubmittable(isEmailValid && password !== "" && email !== "")
    }, [isEmailValid, password])

    function onSubmit() {
        console.log("submitting")
        if (!isSubmittable) {
            return
        }
        setIsSubmitting(true)
        fepiAxios.post("mobile/Login/Sso", {
            input_email_telp: email,
            pwd: password,
            svc_id: '121'
        }).then(response => {
            setIsSubmitting(false)
            localStorage.setItem("fpAuth", response.data.data?.fpAuth ?? '')
            navigate("/")
        }).catch(error => {
            setIsSubmitting(false)
            snackbar.create(
                new Snackbar({
                    message: error.response.data.message,
                    type: 'danger',
                }),
            );
        })
    }

    return (
        <Page tw="p-6 pt-16">
            <Header title="Sign In" onGoBack={() => navigate("/login")} />
            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput autocomplete="email" tw="mb-3" placeholder="Enter Email or Phone number" label="Email / Phone Number" value={email} onChange={(value: any) => setEmail(value)} error={!isEmailValid ? "Please input valid email or phone number" : ""} required asterisk="red"/>
                <TextInput tw="mb-4" placeholder="Enter your Password" label="Password" value={password} onChange={(value: any) => setPassword(value)} type="password" required asterisk="red"/>
                <Button onClick={() => onSubmit()} tw="mb-10" isDisabled={!isSubmittable} isLoading={isSubmitting}>Sign In</Button>
            </form>
            <p tw="underline text-center text-sm mb-10"><Link to={"/forgotPassword"} >Forgot password?</Link></p>
            <p tw="text-center text-sm">New here? <Link to={"/register"} tw="underline">Create an account</Link></p>
        </Page>
    )
}

export default withSnackbar(LoginSsoPage)
