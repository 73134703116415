import { FabricMaterial, ProductTag } from "../../utils"

export function normalizeAddProductParameters(product: any) {
    let selectedTags = []
    let productTags: ProductTag[] = product.tags || []
    for (let tag of productTags) {
        let selectedIds = []
        for (let tagItem of tag.items) {
            if (tagItem.isSelected) {
                selectedIds.push(tagItem.id)
            }
        }
        selectedTags.push({
            label: tag.label,
            selectedIds: selectedIds
        })
    }

    let fabricMaterial = undefined
    let existingFabricMaterial: FabricMaterial = product.fabricMaterial || undefined
    if (existingFabricMaterial) {
        let selectedDetails = []
        for (let detail of existingFabricMaterial.details) {
            let selectedValue = detail.items.find(item => item.isSelected)?.label
            if (selectedValue) {
                selectedDetails.push({
                    label: detail.label,
                    selectedValue: selectedValue
                })
            }
        }
        fabricMaterial = {
            name: existingFabricMaterial.name,
            selectedDetails: selectedDetails
        }
    }

    let existingStocks = { ...product.stocks }
    let stocks = []
    for (let [key, value] of Object.entries(existingStocks)) {
        let color = ""
        for (let productColor of product.colors) {
            if (key.includes(productColor)) {
                color = productColor
            }
        }
        let size = ""
        for (let productSize of product.sizes) {
            if (key.includes(productSize)) {
                size = productSize
            }
        }
        stocks.push({
            variants: {
                color: color,
                size: size
            },
            count: parseInt(value + "")
        })
    }

    let measurements = []

    let normalizedProduct = {
        name: product.name,
        brandId: product.brand.id,
        price: parseInt(product.price),
        discount: parseInt(product.discount),
        commision: {
            type: product.commissionType,
            value: parseInt(product.commissionType === "BULK" ? product.wholesaleCommission : product.singleCommission)
        },
        groupId: product.groupId,
        categoryId: product.categoryId,
        subCategoryIds: product.subCategoryIds,
        description: product.description,
        weight: parseInt(product.weight),
        selectedTags: selectedTags,
        fabricMaterial: fabricMaterial,
        variants: {
            colors: product.colors,
            sizes: product.sizes
        },
        measurements: product.measurements,
        stocks: stocks,
        imageUrls: product.imageUrls
    }

    // console.log("normalizedProduct", normalizedProduct)

    return normalizedProduct
}