import {UseQueryOptions, useQuery} from 'react-query';
import {Order} from '../../../common/entities/order';
import {requestPrintShippingLabel} from '../api';
import {ShippingLabel} from '../../../common/entities/shipping-label';

const useShippingLabel = (order: Order, options?: UseQueryOptions<ShippingLabel, unknown, ShippingLabel, string>) =>
  useQuery({
    queryKey: ['shipping-label', order.id],
    queryFn: async () => {
      const response = await requestPrintShippingLabel(order);
      return new ShippingLabel({
        pdfUrl: response?.downloadUrl,
      });
    },
    ...options,
  });

export default useShippingLabel;
