import axios from '../../common/axios';
import {Brand, Label} from '../../common/entities/';
import {CommonNetworkResponse} from '../../common/types';
import {delay} from '../../common/utils';

export type FetchBrandListResponse = {
  items: {
    imageUrl: string;
    name: string;
    id: string;
  }[];
  category: {
    id: string;
    label: string;
  }[];
};

export async function fetchBrandList(options?: {categoryId?: string}) {
  const {data} = await axios.post<CommonNetworkResponse<FetchBrandListResponse>>('/merchant/Product/Brand/List', {
    ...options,
    categoryId: options?.categoryId || null,
  });

  return {
    brands: data.data?.items.map(Brand.cast),
    categories: data.data?.category.map(Label.cast),
  };
}

export type AddBrandResponse = {
  id: string;
  name: string;
};

export async function addBrand(brand: Brand): Promise<AddBrandResponse | undefined> {
  const {data} = await axios.post<CommonNetworkResponse<AddBrandResponse>>('/merchant/Product/Brand/Add', {
    name: brand.name,
  });

  return data.data;
}
