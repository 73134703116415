import {BaseEntity} from './base';
import {Label} from './label';

export type ProductArgs = {
  imageUrls?: string[];
  id: string;
  name?: string;
  brand?: {
    id?: string;
    name?: string;
  };
  price?: number;
  discount?: number;
  variant?: string;
  singleCommissionValue?: number;
  bulkCommissionValue?: number;
  commisionType?: 'BULK' | 'SINGLE';
  categoryLabel?: string;
  categories?: Label[];
  subCategories?: Label[];
  groups?: Label[];
  weight?: number;
  description?: string;
  measurements?: {
    label?: string;
    items?: string[];
  }[];
  fabric?: {
    name?: string;
    details?: {
      label?: string;
      items?: {
        label?: string;
        isSelected?: boolean;
      }[];
    }[];
  };
  tags?: {
    isOptional?: boolean;
    maxSelection?: number;
    label?: string;
    items?: Label[];
  }[];
};

export class Product extends BaseEntity {
  imageUrls?: string[];
  name?: string;
  brand?: {
    id?: string;
    name?: string;
  };
  id: string;
  price?: number;
  discount?: number;
  singleCommissionValue?: number;
  bulkCommissionValue?: number;
  commisionType?: 'BULK' | 'SINGLE';
  categoryLabel?: string;
  categories?: Label[];
  subCategories?: Label[];
  groups?: Label[];
  weight?: number;
  variant?: string;
  description?: string;
  measurements?: {
    label?: string;
    items?: string[];
  }[];
  fabric?: {
    name?: string;
    details?: {
      label?: string;
      items?: {
        label?: string;
        isSelected?: boolean;
      }[];
    }[];
  };
  tags?: {
    isOptional?: boolean;
    maxSelection?: number;
    label?: string;
    items?: {
      id?: string;
      label?: string;
      isSelected?: boolean;
    }[];
  }[];

  constructor(args: ProductArgs) {
    super();
    Object.assign(this, args);
  }

  static cast(args: any) {
    const product = new Product({
      id: args.productId,
      groups: [],
      brand: {
        name: args.brand.name,
        id: args.brand.id,
      },
      subCategories: [],
      categories: [],
      weight: args.weight,
      price: args.price,
      tags: args.tags?.map((tag: any) => ({
        ...tag,
        items: tag.items?.map(Label.cast),
      })),
      imageUrls: [],
      name: args.name,
      variant: args.attribute,
    });

    if (args.imageUrl) {
      product.imageUrls = [args.imageUrl];
    } else if (args.imageUrls) {
      product.imageUrls = args.imageUrls;
    }

    return product;
  }
}
