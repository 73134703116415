import React, { Fragment, useEffect, useState } from "react"
import Page from "../../../common/components/page/page"
import Header from "../../../common/components/header/header"
import { Form, useNavigate } from "react-router-dom"
import TextInput from "../../../common/components/text-input/text-input"
import FloatingFooter from "../../../common/components/floating-footer/floating-footer"
import Button from "../../../common/components/button/button"
import { ValidateEmailTelp, getLocalStorageObject, setLocalStorageObject } from "../../../framework/utils"
import registrationConstants from "./constants"
import { fepiAxios } from "../../../common/axios"
import { useSnackbar, withSnackbar } from "../../../common/components/snackbar/snackbar"
import { Snackbar } from "../../../common/entities/snackbar"

function RegisterPage() {

    const navigate = useNavigate()
    const snackbar = useSnackbar()

    const [email, setEmail] = useState("")
    const [isEmailValid, setIsEmailValid] = useState(true)
    const [emailError, setEmailError] = useState("")
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        setIsEmailValid(ValidateEmailTelp(email))
        setEmailError(!isEmailValid ? "Please input valid email or phone number" : "")
    }, [email])

    useEffect(() => {
        setIsSubmittable(isEmailValid && !emailError)
    }, [isEmailValid, emailError])

    function onSubmit() {
        if (!isSubmittable)
            return

        // Do api call here
        setIsSubmitting(true)
        fepiAxios.post('mobile/Account/Register', {
            input_email_telp: email,
            svc_id: '121'
        }).then((response) => {
            const formData = getLocalStorageObject(registrationConstants.LOCAL_STORAGE_IDENTIFIER);
            formData.email = email
            setLocalStorageObject("merchantCenterRegistration", formData)
            navigate("/register/verifyEmail")
        }).catch((error) => {
            setIsSubmitting(false)
            snackbar.create(new Snackbar({
                message: error.response.data.message,
                type: "danger"
            }))
        })
    }

    return (
        <Page tw="px-6 py-16 pb-20 flex flex-col">
            <Header onGoBack={() => navigate("/login")} title="Sign Up"></Header>
            <form onSubmit={e => { e.preventDefault(); onSubmit() }}>
                <TextInput
                    label="Email / Phone number"
                    name="email"
                    autocomplete="email"
                    placeholder="Ketik di sini"
                    onChange={(val) => setEmail(val || "")}
                    value={email}
                    error={emailError}
                    required
                    asterisk="red"
                />
            </form>

            <div tw="grow flex flex-col justify-end text-center pb-2 text-xs">
                <p>By continuing, you agree to our Terms and Conditions.</p>
                <p>The Fepi Services are subject to our Privacy Policy.</p>
            </div>

            <FloatingFooter>
                <Button isLoading={isSubmitting} onClick={onSubmit} isDisabled={!isSubmittable}>Next</Button>
            </FloatingFooter>
        </Page>
    )
}

export default withSnackbar(RegisterPage)