import { getRuntimeConfig } from '../framework/config';

export function uploadThefepiCommonFile(file: File) {
  const formData = new FormData();
  formData.append('image', file, file.name);

  const fpAuth = window?.localStorage?.getItem?.('fpAuth');
  if (fpAuth) {
    formData.append('fpAuth', fpAuth);
  }

  return fetch(getRuntimeConfig().COMMON_UPLOAD_IMAGE_URL, {
    method: 'POST',
    body: formData,
    redirect: 'follow',
  })
    .then(response => response.json())
    .then(data => data.data.imageUrl as string);
}

export function delay<T = any>(data?: T, ms = 0): Promise<T | undefined> {
  return new Promise(resolve => setTimeout(() => void resolve(data), ms));
}

export function formatPrice(value = 0) {
  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
  });

  return formatter.format(value);
}

export function isLoggedIn() {
  try {
    return !!window.localStorage.getItem("fpAuth")
  } catch {
    return true
  }
}

export function getMinMaxIntFromArray(arr: any[]) {
  let min = Infinity
  let max = -Infinity

  for (let val of arr) {
    let num = 0
    if (val) {
      num = parseInt(val)
    }
    if (num > max)
      max = num
    if (num < min)
      min = num
  }

  return [min, max]
}