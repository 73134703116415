import $ from "jquery"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import tw from "twin.macro"
import Button from "../../common/components/button/button"
import FloatingFooter from "../../common/components/floating-footer/floating-footer"
import Header from "../../common/components/header/header"
import Page from "../../common/components/page/page"
import TextInput from "../../common/components/text-input/text-input"
import { CommissionType } from "../../common/constants"
import ChevronRight from '../../common/icons/chevron-right.svg'
import InformationIcon from '../../common/icons/information.svg'
import { getLocalStorageObject, removeLocalStorageObject, setLocalStorageObject } from "../../framework/utils"
import FormSection from "./components/product-form/components/form-section"
import ProgressBar from "./components/progress-bar"
import { AddProductLocalStorageAddress } from "./constants"
import { formatCurrency } from "./utils"
import BrandPickerSheet from "../brand/components/brand-picker-sheet"
import { Brand } from "../../common/entities"
import mcAxios from "../../common/axios"
import { normalizeAddProductParameters } from "./components/product-upload/api"
import ImagePicker, { ImagePickerImage } from "../../common/components/forms/image-picker"
import { uploadThefepiCommonFile } from "../../common/utils"

function UploadProductPage1() {

    const navigate = useNavigate()
    const [brandInput, setBrandInput] = useState({
        children: <></>
    })
    const [product, setProduct] = useState<any>({
        name: "",
        brand: new Brand(),
        price: "",
        discount: "",
        discountedPrice: "",
        wholesaleCommission: "",
        singleCommission: "",
        commissionType: 'BULKx',
        imageUrls: []
    })
    const [images, setImages] = useState<ImagePickerImage[]>([])
    const [errors, setErrors] = useState({
        name: "",
        brand: "",
        price: "",
        discount: "",
    })

    const [isBrandPickerSheetOpen, setIsBrandPickerSheetOpen] = useState(false)
    const [isSubmittable, setIsSubmittable] = useState(false)
    const [isCommissionValid, setIsCommissionValid] = useState(false)

    useEffect(() => {
        let existingProduct = getLocalStorageObject(AddProductLocalStorageAddress)
        if (!existingProduct.useExisting) existingProduct = {}
        let newProduct = {...product, ...existingProduct}
        mcAxios.post("/merchant/Setting/BulkCommission").then((res) => {
            let data = res.data.data
            newProduct.wholesaleCommission = data.bulkCommission
        }).finally(()=>{
            let newImages: ImagePickerImage[] = []
            for(let imageUrl of newProduct.imageUrls){
                const imageId = Math.round(Math.random() * 1000).toString();
                newImages.push({
                    url: imageUrl,
                    state: 'idle',
                    id: imageId,
                    onDeleted: (id?: string) => {
                        setImages(prevImages => {
                            const newImages = prevImages.filter(image => image.id !== id);
                            // console.log("images", newImages);
                            return newImages;
                        });
                    }
                })
            }
            setImages(newImages)
            setLocalStorageObject(AddProductLocalStorageAddress, newProduct)
            setProduct(newProduct)
        })
    }, [])

    useEffect(() => {
        if (product.commissionType === "BULK") {
            setIsCommissionValid(true)
            return
        } else {
            setIsCommissionValid(false)
        }
        if (!isNaN(parseInt(product.singleCommission)) && parseInt(product.singleCommission) >= 0) {
            setIsCommissionValid(true)
        }
    }, [product.commissionType, product.singleCommission])

    useEffect(() => {
        if (product.price && product.discount) {
            setProduct({
                ...product,
                discountedPrice: Math.ceil(
                    parseInt(product.price) * (100 - parseInt(product.discount)) / 100
                ).toString()
            })
        }
    }, [product.price, product.discount])

    useEffect(() => {
        if(
            !!product.name &&
            !!product.brand &&
            !!product.price &&
            isCommissionValid &&
            images.length > 0
        ){
            setIsSubmittable(true)
        } else {
            setIsSubmittable(false)
        }
    }, [product, isCommissionValid, images])

    // useEffect(()=>{
    //     console.log("images",images)
    // },[images])

    function handleSaveAsDraft() {
        let newProduct = {...product}
        newProduct.imageUrls = images.map(image => image.url)
        mcAxios.post("/merchant/Product/Add", {
            type: "DRAFT",
            ...normalizeAddProductParameters(newProduct)
        }).then((res)=>{
            removeLocalStorageObject(AddProductLocalStorageAddress)
            window.localStorage.setItem('__USE_REMEMBER__--product-list-category-key', '\"DRAFT\"')
            navigate(`/products`)
        })
    }

    function handlePickBrand() {
        setIsBrandPickerSheetOpen(true)
    }

    function handleDiscountChange(discount: number) {
        let newDiscount = discount
        if (isNaN(discount))
            newDiscount = 0
        if (newDiscount <= 100 && newDiscount >= 0) {
            setProduct({ ...product, discount: newDiscount.toString() })
        }
    }

    function handleCommissionChange(val: string) {
        let newValue = parseInt(val?.replace("%", "") || "")
        if (isNaN(newValue))
            newValue = 0
        if (newValue <= 100) {
            setProduct({ ...product, singleCommission: newValue.toString() })
        }
    }

    function handleSubmit() {
        let newProduct = {...product}
        newProduct.imageUrls = images.map(image => image.url)
        setLocalStorageObject(AddProductLocalStorageAddress, newProduct)

        navigate("/product/upload/2")
    }

    return (
        <Page tw="px-6 py-14 pb-20">
            <Header onGoBack={() => {
                removeLocalStorageObject(AddProductLocalStorageAddress)
                navigate("/")
            }} title="Product Detail"></Header>
            <ProgressBar tw="mb-6" title="Basic Information" current={1} total={6} />
            <div tw="h-5"></div>
            {/* Product Photo */}
            <ImagePicker
                label="Product Photo"
                required
                tw="mb-3"
                images={images}
                onFileAdded={(file: any) => {
                    // console.log(file);
                    const imageId = Math.round(Math.random() * 1000).toString();
                    const newImage: ImagePickerImage = {
                        url: URL.createObjectURL(file as Blob),
                        state: "uploading",
                        id: imageId,
                        onDeleted: (id?: string) => {
                            setImages(prevImages => {
                                const newImages = prevImages.filter(image => image.id !== id);
                                // console.log("images", newImages);
                                return newImages;
                            });
                        }
                    };

                    // Add new image in uploading state
                    setImages(prevImages => [...prevImages, newImage]);

                    // Upload the file
                    uploadThefepiCommonFile(file).then(url => {
                        setImages(prevImages => {
                            const updatedImages: ImagePickerImage[] = prevImages.map(image =>
                                image.id === imageId ? { ...image, url, state: "idle" } : image
                            );
                            setLocalStorageObject("imageUpload", updatedImages);
                            return updatedImages;
                        });
                    });
                }}
            ></ImagePicker>
            <div tw="flex gap-1 py-1 px-2 rounded-lg text-xs text-neutral-600 mb-4 items-center w-fit">
                {/* <div tw="w-4"><InformationIcon /></div> */}
                <div>
                    <p><b>*best size: </b>395px <b>x</b> 535px</p>
                </div>
            </div>
            <TextInput tw="mb-3" label="Product Name" required name="productName" value={product.name} onChange={(val) => setProduct({ ...product, name: val || "" })} />
            <FormSection tw="mb-4" label="Brand" required>
                <button
                    type="button"
                    onClick={handlePickBrand}
                    tw="h-12 p-3 w-full flex items-center rounded-lg border-textTertiary border bg-white"
                    css={[
                        // brandInput.isDisabled && tw`bg-gray-200`, 
                        errors.brand && tw`border border-red-600`
                    ]}
                >
                    <div tw="flex-1 items-start flex text-sm">{product.brand?.name || <p tw="text-sm text-gray-300">{"Select"}</p>}</div>
                    <div tw="flex-shrink-0 mr-2">
                        <ChevronRight tw="w-2" />
                    </div>
                </button>
            </FormSection>
            <BrandPickerSheet
                isOpen={isBrandPickerSheetOpen}
                onValueChange={(val) => setProduct({ ...product, brand: val || new Brand() })}
                onOpenChange={() => setIsBrandPickerSheetOpen(false)}
                onBackClick={() => setIsBrandPickerSheetOpen(false)}
            />
            <TextInput
                label="Price (Rp)" required
                value={formatCurrency(parseInt(product.price))}
                error={errors.price}
                onChange={(val) => setProduct({ ...product, price: val?.replace(/\./g, "") || "" })}
                tw="mb-3"
            />
            <TextInput
                label="Discount (%)"
                type="number"
                value={product.discount}
                onChange={(val) => handleDiscountChange(parseInt(val || ""))}
                tw="mb-3"
            />
            <TextInput
                label="Discounted Price (Rp)"
                isDisabled={true}
                value={formatCurrency(parseInt(product.discountedPrice))}
                tw="mb-3"
            />
            <FormSection label="Reseller Commission (%)" required>
                <div tw="flex gap-1 py-1 px-2 rounded-lg bg-neutral-100 text-xs text-neutral-600 mb-4">
                    <div tw="w-6"><InformationIcon /></div>
                    <div>
                        <p><b>Bulk commission: </b>Quickly set flat commission rate for all products.</p>
                        <p><b>Single commission: </b>Set commission only for particular product.</p>
                    </div>
                </div>
                <fieldset id="commissionTypeRadioGroup">
                    <div tw="flex items-center gap-3 mb-3 justify-between"
                        onClick={() => setProduct({ ...product, commissionType: CommissionType.BULK })}
                    >
                        <div tw="flex justify-start gap-3">
                            <input id="radioWholesaleCommission" name="commissionTypeRadioGroup" type="radio" value={CommissionType.BULK}
                                checked={product.commissionType === CommissionType.BULK}
                            />
                            <span onClick={() => {
                                $('#radioWholesaleCommission').trigger("click")
                            }}>Bulk commission</span>
                        </div>
                        <div tw="flex justify-end">
                            <TextInput value={`${product.wholesaleCommission ?? ""}%`} isDisabled={true} tw="w-24" />
                        </div>
                    </div>
                    <div tw="flex items-center gap-3 mb-3 justify-between"
                        onClick={() => setProduct({ ...product, commissionType: CommissionType.SINGLE })}
                    >
                        <div tw="flex justify-start gap-3">
                            <input id="radioSingleCommission" name="commissionTypeRadioGroup" type="radio" value={CommissionType.SINGLE}
                                checked={product.commissionType === CommissionType.SINGLE}
                            />
                            <span onClick={() => { $('#radioSingleCommission').trigger("click") }}>Single commission</span>
                        </div>
                        <div tw="flex justify-end">
                            <TextInput
                                value={`${product.singleCommission ?? ""}%`}
                                // onClick={() => { $('#radioSingleCommission').trigger("click") }}
                                onChange={(val) => handleCommissionChange(val || "")}
                                tw="w-24"
                            />
                        </div>
                    </div>
                </fieldset>
            </FormSection>
            <FloatingFooter tw="flex gap-3">
                <Button onClick={handleSaveAsDraft} variant="outline">Save as draft</Button>
                <Button isDisabled={!isSubmittable} onClick={handleSubmit}>Next</Button>
            </FloatingFooter>
        </Page>
    )
}

export default UploadProductPage1