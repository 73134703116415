import {BaseEntity} from './base';

export type BrandArgs = {
  id?: string;
  name?: string;
  imageUrl?: string;
};

export class Brand extends BaseEntity {
  id?: string;
  name?: string;
  imageUrl?: string;

  constructor(args?: BrandArgs) {
    super();
    Object.assign(this, args);
  }

  // cast given object to brand entity
  static cast(args: any) {
    return new Brand({
      id: args.id,
      name: args.name,
      imageUrl: args.imageUrl,
    });
  }
}
