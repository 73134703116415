import React, { HTMLInputTypeAttribute, forwardRef } from 'react';
import * as Label from '@radix-ui/react-label';
import tw from 'twin.macro';
import { NOOP } from '../../constants';

export type TextInputProps = Omit<React.InputHTMLAttributes<HTMLFieldSetElement>, 'onChange'> & {
  label?: string;
  value?: string | number;
  required?: boolean;
  isDisabled?: boolean;
  multiline?: boolean;
  onChange?: (value?: string) => void;
  error?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  autocomplete?: "on" | "off" | HTMLInputTypeAttribute;
  asterisk?: "red" | "default";
  noMargin?: boolean;
  align?: "left" | "center" | "right"
};

function TextInput(props: TextInputProps, ref: any) {
  const { label, value = '', onChange = NOOP, isDisabled, required, multiline, error, asterisk = "default", autocomplete = "on", ...rest } = props;

  return (
    <fieldset tw="w-full" {...rest}>
      {label && (
        <Label.Root htmlFor={rest.id} tw="font-semibold text-sm">
          {label}
          <span css={asterisk === "red" && tw`text-[#F14100]`}>{required && '*'}</span>
        </Label.Root>
      )}
      {multiline ? (
        <textarea
          ref={ref}
          aria-disabled={isDisabled}
          disabled={isDisabled}
          tw="px-3 w-full mt-2 py-3 h-80 border-textTertiary border rounded-lg text-sm focus:outline-2 outline-primary"
          value={value}
          css={[error && tw`border-red-700 focus:outline-red-700`, isDisabled && tw`bg-gray-200`]}
          onChange={e => onChange?.(e.target.value)}
          placeholder={rest.placeholder}
          id={rest.id}></textarea>
      ) : (
        <input
          ref={ref}
          aria-disabled={isDisabled}
          disabled={isDisabled}
          tw="h-12 px-3 w-full border-textTertiary border rounded-lg text-sm focus:outline-2 outline-primary"
          css={[error && tw`border-red-700 focus:outline-red-700`, isDisabled && tw`bg-gray-200`, !props.noMargin && tw`mt-2`, props.align && {textAlign: props.align}]}
          value={value}
          onChange={e => onChange(e.target.value)}
          placeholder={rest.placeholder}
          type={rest.type}
          name={props.name}
          autoComplete={autocomplete}
          autoFocus={rest.autoFocus}
        ></input>
      )}
      <p tw="text-red-700 text-xs mt-1">{error}</p>
    </fieldset>
  );
}

export default forwardRef(TextInput);
