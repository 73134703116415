import React from "react"
import FormSection from "./product-form/components/form-section"
import TextInput from "../../../common/components/text-input/text-input";
import { MeasurementItem } from "./product-upload/product-upload-util";

type SizeFieldProps = {
    label?: string;
    size: string;
    values?: MeasurementItem[];
    fields: MeasurementItem[];
    onChange?: (value: {}) => void;
}

function SizeField(props: SizeFieldProps) {
    const { label, size, fields, values = [], onChange = () => { } } = props

    function handleChange(label: string, value: string) {
        let newItems = [...values]
        for(let newItem of newItems){
            if(newItem.label === label){
                newItem.value = parseFloat(value)
            }
        }
        onChange(newItems)
    }

    return (
        <FormSection label={label} labelSize="normal" tw="mb-6" labelFontWeight="bold">
            {fields.map(field => (
                <div tw="grid grid-cols-2 items-center w-full gap-2">
                    <span tw="flex-grow text-sm font-semibold">{`${field.label}${field.unit && ` (${field.unit})`}`}</span>
                    <TextInput
                        type="number"
                        tw="flex-grow"
                        value={values.find(value=>value.label===field.label)?.value}
                        // name={`${size}_${label}`}
                        onChange={(val) => handleChange(field.label, val || "")}
                    />
                </div>
            ))}
        </FormSection>
    )
}

export default SizeField