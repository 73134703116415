import React, { useEffect, useState } from 'react'
import Page from '../../../../common/components/page/page'
import Header from '../../../../common/components/header/header'
import { useNavigate, useParams } from 'react-router-dom'
import { getLocalStorageObject, setLocalStorageObject } from '../../../../framework/utils'
import { EditProductLocalStorageAddress } from '../../constants'
import { getMeasurementValues, splitMeasurementKeys, updateMeasurementValues } from '../utils/edit-product-utils'
import TextInput from '../../../../common/components/text-input/text-input'
import FloatingFooter from '../../../../common/components/floating-footer/floating-footer'
import Button from '../../../../common/components/button/button'

type MeasurementPageProps = {

}

function MeasurementPage() {
    const navigate = useNavigate()
    const { id } = useParams()

    const [product, setProduct] = useState({})
    const [sizes, setSizes] = useState<string[]>([])
    const [measurementItems, setMeasurementItems] = useState<string[]>([])
    const [values, setValues] = useState<string[][]>([])

    useEffect(() => {
        const productFormData = getLocalStorageObject(EditProductLocalStorageAddress, id)
        const measurements = productFormData.measurements
        const [sizes, measurementItems] = splitMeasurementKeys(measurements)
        const values = getMeasurementValues(measurements, sizes, measurementItems)
        setProduct(productFormData)
        setSizes(sizes)
        setMeasurementItems(measurementItems)
        setValues(values)
        window.scrollTo(0, 0)
    }, [])

    function handleSave() {
        let newMeasurements = updateMeasurementValues(sizes, measurementItems, values)
        let newProduct = { ...product, measurements: newMeasurements }
        setProduct(newProduct)
        setLocalStorageObject(EditProductLocalStorageAddress, newProduct, id)
        navigate(-1)
    }

    return (
        <Page tw="pt-[3.75rem] pb-20 bg-[#F2F2F2]">
            <Header title='Measurement' onGoBack={() => navigate(-1)}></Header>
            {sizes.map((size, i) => (
                <div tw="grid grid-cols-2 items-center mb-4 p-4 bg-white">
                    <b>Size {size}</b>
                    <b></b>
                    {measurementItems.map((measurementItem, j) => (
                        <>
                            <span tw="text-sm font-semibold">{measurementItem}</span>
                            <TextInput
                                type="number"
                                min={0}
                                value={values[i][j]}
                                onChange={(val) => {
                                    let newValues = [...values]
                                    let parsedValue = parseInt(val || "")
                                    if (isNaN(parsedValue) || parsedValue < 0) {
                                        newValues[i][j] = ""
                                    } else {
                                        newValues[i][j] = val || ""
                                    }
                                    setValues(newValues)
                                }}
                            />
                        </>
                    ))}
                </div>
            ))}
            <FloatingFooter>
                <Button onClick={() => handleSave()}>Save</Button>
            </FloatingFooter>
        </Page>
    )
}

export default MeasurementPage