export function updateMeasurements(existingMeasurements: {}, sizes: string[], measurementItems: string[]) {
    if (!existingMeasurements) return {}

    let updatedMeasurements = { ...existingMeasurements }
    let existingKeys = []
    for (let [key, value] of Object.entries(existingMeasurements)) {
        existingKeys.push(key)
    }
    for (let size of sizes) {
        for (let measurementItem of measurementItems) {
            let keyname = `${size}_${measurementItem}`
            if (!existingKeys.includes(keyname)) {
                updatedMeasurements[keyname] = "";
            }
        }
    }
    return updatedMeasurements
}

export function splitMeasurementKeys(measurements: {}) {
    if (!measurements) return [[], []]

    let sizes: string[] = []
    let measurementItems: string[] = []

    for (let [key, value] of Object.entries(measurements)) {
        let keys = key.split("_")
        let size = keys[0]
        let measurementItem = keys[1]
        sizes.push(size)
        measurementItems.push(measurementItem)
    }

    sizes = [...new Set(sizes)]
    measurementItems = [...new Set(measurementItems)]

    return [sizes, measurementItems]
    // return [sizes, measurementItems]
}

export function getMeasurementValues(measurements: {}, sizes: string[], measurementItems: string[]) {
    if (sizes.length <= 0) return []

    let values = []

    for (let i = 0; i < sizes.length; i++) {
        let row = []
        for (let j = 0; j < measurementItems.length; j++) {
            let key = `${sizes[i]}_${measurementItems[j]}`
            row.push(measurements[key])
        }
        values.push(row)
    }

    return values
}

export function updateMeasurementValues(sizes: string[], measurementItems: string[], values: string[][]) {
    let measurements = {}
    for (let [i, size] of sizes.entries()) {
        for (let [j, measurementItem] of measurementItems.entries()) {
            let key = `${size}_${measurementItem}`
            measurements[key] = values[i][j]
        }
    }
    return measurements
}