import React, {useEffect, useMemo, useState} from 'react';
import tw from 'twin.macro';
import {useInView} from 'react-intersection-observer';
import IconArrowLeft from '../../../../common/icons/arrow-left.svg';
import {ProductV2} from '../../../../common/entities/productv2';
import NumberInput from '../../../../common/components/number-input/number-input';
import useProductStockList from '../../hooks/use-product-stock-list';
import PulseLoader from '../../../../common/components/loaders/pulse';
import {Stock} from '../../../../common/entities/stock';
import Button from '../../../../common/components/button/button';
import {updateProductStocks} from '../../api';

export type Variant = {
  id: string;
  label: string;
  stock: number;
};

export type ProductStockSheetProps = {
  isShown?: boolean;
  product: ProductV2;
  totalItems?: number;
  variants?: Stock[];
  onBackClick?: () => void;
  onBackgroundClick?: () => void;
  onCancelClick?: () => void;
  onSuccess?: () => void;
};

function ProductStockSheet(props: ProductStockSheetProps) {
  const [isFreeVariant, setIsFreeVariant] = useState(false);
  const [stockInputs, setStockInputs] = useState<Stock[]>([]);
  const [isSubmittingStocks, setIsSubmittingStocks] = useState(false);

  const {
    data: stockCollection,
    isFetching: isFetchingStockData,
    isLoading: isLoadingStockData,
    fetchNextPage: fetchNextStocks,
    isFetchingNextPage: isFetchingNextStocks,
  } = useProductStockList({
    limit: 5,
    startIndex: 0,
    productId: props.product?.id,
  });

  const {ref} = useInView({
    threshold: [0],
    onChange: inView => {
      if (inView) {
        fetchNextStocks();
      }
    },
  });

  const variants = useMemo(() => stockCollection?.pages[0]?.variants || [], [stockCollection]);

  useEffect(() => {
    // console.log('variants', variants);
    if (variants.length === 1 && variants[0].label?.substr(0, 4) === 'Free') {
      setIsFreeVariant(true);
    }
    setStockInputs(variants);
  }, [variants]);

  const handleStockChange = (index: number, value: number) => {
    if (value < 0) return;
    const newStocks = [...stockInputs];
    newStocks[index].stock = value;
    setStockInputs(newStocks);
  };

  const handleSubmitStocks = () => {
    setIsSubmittingStocks(true);
    updateProductStocks({
      productId: props.product.id,
      updatedVariants: stockInputs,
    })
      .then(res => {
        setIsSubmittingStocks(false);
        // console.log('stocks saved');
        props.onSuccess?.();
        props.onBackClick?.();
      })
      .catch(err => {
        setIsSubmittingStocks(false);
        // console.log('stock update error');
      });
  };

  return (
    <>
      <div
        id="ProductStockSheetBackground"
        onClick={() => props.onBackgroundClick?.()}
        tw="top-0 bottom-0 left-0 right-0 absolute bg-[#0000004d]"
        css={props.isShown ? tw`z-[100]` : tw`hidden`}
      />
      <div
        id="StockSheetContainer"
        tw="fixed bg-white rounded-t-xl max-w-[500px] w-full max-h-[100vh]"
        style={{transition: 'bottom 0.5s, height 0.5s'}}
        css={props.isShown ? tw`z-[100] bottom-0` : tw`bottom-[-100vh]`}>
        <div id="StockSheetTitleContainer" tw="h-16 pl-6 pr-6 flex items-center">
          <button onClick={() => props.onBackClick?.()}>
            <IconArrowLeft />
          </button>
          <div tw="w-4" />
          <p tw="text-lg font-bold">Edit Stock</p>
          <div tw="flex-grow" />
          <button onClick={() => props.onCancelClick?.()} tw="text-sm">
            Cancel
          </button>
        </div>

        <div id="StockSheetProductInfoContainer" tw="w-full mt-2 mb-4 px-6 flex flex-row overflow-hidden">
          <div id="StockSheetProductImage" tw="shrink-0 rounded-md">
            <img
              tw="h-14 w-14 object-contain"
              src={props.product.imageUrl}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src="https://images.app.thefepi.com/app/contents/945a520396bd5d48aac1c2608ad3b98b.jpeg.webp";
              }}
              alt=""
            />
          </div>
          <div id="StockSheetProductDescription" tw="ml-4 overflow-hidden text-sm">
            <p tw="text-ellipsis overflow-hidden whitespace-nowrap">{props.product?.name}</p>
            <p>
              <span tw="font-bold">Brand: </span>
              {props.product?.brand}
            </p>
          </div>
        </div>
        <div id="StockSheetStockListContainer" tw="w-full px-6">
          {!isFetchingStockData && !isFreeVariant && (
            <div id="StockSheetStockListTitle" tw="flex flex-row w-full mb-4">
              <p tw="font-bold flex-1">Variant</p>
              <p tw="font-bold flex-1">Stock</p>
              {/* <div tw="flex-[0.5]"></div> */}
            </div>
          )}
          <div id="ScrollableStockContent" tw="overflow-y-scroll">
            {!isFetchingStockData ? (
              variants.map((variant, index) => {
                return (
                  <div key={variant.id} id="StockSheetStockListContent" tw="flex flex-row justify-between mb-2">
                    {!isFreeVariant && (
                      <div tw="flex-auto">
                        <p>{variant.label}</p>
                      </div>
                    )}
                    <div
                      tw="flex-auto flex flex-row content-center flex-wrap w-full"
                      css={isFreeVariant ? [tw`justify-center`] : [tw`justify-end`]}>
                      <div tw="h-full flex items-center">
                        <button
                          onClick={() => handleStockChange(index, stockInputs[index].stock - 1)}
                          tw="w-12 h-10 p-1 bg-white text-primary px-6 rounded-xl border border-primary flex items-center justify-center py-3">
                          -
                        </button>
                      </div>
                      <div
                        css={isFreeVariant ? [tw``] : [tw`w-[90px]`]}
                        tw="text-lg mx-2 border-coolGray-400 rounded-sm text-center">
                        <NumberInput
                          onChange={e => handleStockChange(index, parseInt(e || ""))}
                          type="number"
                          min="0"
                          value={stockInputs[index]?.stock}
                        />
                      </div>
                      <div tw="h-full flex items-center">
                        <button
                          onClick={() => handleStockChange(index, stockInputs[index].stock + 1)}
                          tw="text-lg w-12 h-10 p-1 bg-white text-primary px-6 rounded-xl border border-primary flex items-center justify-center py-3">
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div tw="flex w-full h-10 p-1 items-center justify-center">
                <PulseLoader theme="light" />
              </div>
            )}
          </div>
          <div ref={ref}></div>
          {isFetchingNextStocks && (
            <div tw="flex items-center justify-center py-3">
              <PulseLoader theme="light" />
            </div>
          )}
        </div>
        <div
          id="StockSheetButton"
          tw="px-6 py-3 drop-shadow z-[100]"
          style={{boxShadow: '0 -10px 25px -5px rgb(0 0 0 / 0.1)'}}>
          <Button isDisabled={isSubmittingStocks} onClick={() => handleSubmitStocks()}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default ProductStockSheet;
