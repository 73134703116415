import React from "react"
import PlusIcon from "../../../common/icons/plus-white.svg"
import CloseIcon from "../../../common/icons/close-black-8.svg"
import Button from "../../../common/components/button/button";

type VariantFieldProps = {
    identifier?: string;
    label: string;
    values?: string[];
    onChange?: (val: string[]) => void;
    onPlusButtonClicked?: () => void;
}

function VariantField(props: VariantFieldProps) {
    const { identifier, values = [],
        onChange = () => { },
        onPlusButtonClicked = () => { },
        ...rest
    } = props

    function handleChange(toBeRemoved: string) {
        onChange(values.filter(value => value !== toBeRemoved))
    }

    return (
        <div tw="flex flex-col mb-6">
            <div tw="flex-grow flex items-center justify-between">
                <b>{rest.label}</b>
                <Button
                    size="small"
                    onClick={() => onPlusButtonClicked()}
                    tw="flex-shrink w-14"><PlusIcon />
                </Button>
            </div>
            {values.length > 0 &&
                <div tw="flex flex-wrap overflow-hidden gap-2 mt-2">
                    {values.map(value => (
                        <div tw="flex items-center rounded-[20px] bg-neutral-100">
                            <span tw="py-2 pl-3 text-sm">{value.replace(/_/g, " ")}</span>
                            <span
                                onClick={() => handleChange(value)}
                                tw="py-2 pl-2 pr-3"><CloseIcon />
                            </span>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

export default VariantField