import React, { useEffect, useState } from "react";
import CategoryRadioGroup from "../../components/category-radio-group";
import FloatingFooter from "../../../../common/components/floating-footer/floating-footer";
import Button from "../../../../common/components/button/button";
import SubcategoryPicker from "../../components/subcategory-picker";
import FormSection from "../../components/product-form/components/form-section";
import { ProductCategory, fetchProductCategories, fetchProductCategoriesAll } from "../../components/product-upload/product-upload-api";
import { getLocalStorageObject, setLocalStorageObject } from "../../../../framework/utils";
import { EditProductLocalStorageAddress } from "../../constants";
import BottomSheet from "../../../../common/components/bottom-sheet/bottom-sheet";

type CategorySheetProps = {
    isOpen: boolean;
    productId: string;
    onChange?: (val: string) => void;
    groupId?: string;
    categoryId?: string;
    subCategoryIds?: string[];
}

function CategorySheet(props: CategorySheetProps) {
    const { productId, onChange = () => { } } = props

    const [product, setProduct] = useState<any>({
        summary: "",
        group: "",
        category: "",
        subCategories: [""],
        groupId: "",
        categoryId: "",
        subCategoryIds: [""],
        categorySummary: "",
    })

    const [productGroups, setProductGroups] = useState<ProductCategory[]>([])
    const [productCategories, setProductCategories] = useState<ProductCategory[]>([])
    const [productSubcategories, setProductSubcategories] = useState<ProductCategory[]>([])

    const [loadings, setLoadings] = useState({
        groupPick: false,
        categoryPick: false,
        subcategoryPick: false,
    })

    useEffect(() => {
        setLoadings({ ...loadings, groupPick: true })
        let newProduct = { ...product, ...getLocalStorageObject(EditProductLocalStorageAddress, props.productId),
            groupId: props.groupId,
            categoryId: props.categoryId,
            subCategoryIds: props.subCategoryIds
         }
        setProduct(newProduct)
        //fetchProductCategories({ key: "groupItems" }, (val) => { setProductGroups(val); setLoadings({ ...loadings, groupPick: false }) })
        // console.log("product in category sheet", newProduct)
        // console.log("whatever",props.groupId,props.categoryId,props.subCategoryIds)
    }, [])

    useEffect(() => {
        if (productGroups.length == 0)
            fetchProductCategoriesAll({ groupId: product.groupId, categoryId: product.categoryId }, (val) => {
                setProductGroups(val.groupItems)
                setProductCategories(val.categoryItems)
                setProductSubcategories(val.subCategoryItems)
                setLoadings({ ...loadings, groupPick: false })
            })
    }, [product])

    // useEffect(()=>{
    //     console.log("cats",productGroups,productCategories,productSubcategories)
    // },[productGroups,productCategories,productSubcategories])

    function handleChangeGroup(group: string) {
        setLoadings({ ...loadings, categoryPick: true })
        fetchProductCategories({ groupId: productGroups?.filter(pg => pg.label === group)?.[0]?.id, key: "categoryItems" }, (val) => { setProductCategories(val); setLoadings({ ...loadings, categoryPick: false }) })
        setProductSubcategories([])
    }

    function handleChangeCategory(category: string) {
        setLoadings({ ...loadings, subcategoryPick: true })
        fetchProductCategories({
            groupId: productGroups?.filter(pg => pg.label === product.group)?.[0]?.id,
            categoryId: productCategories?.filter(pc => pc.label === category)?.[0]?.id,
            key: "subCategoryItems"
        }, (val) => { setProductSubcategories(val); setLoadings({ ...loadings, subcategoryPick: false }) })
    }

    function handleSubmit() {
        const summary = `${product.group} / ${product.category} / ${product.subCategories.join(",")}`
        const newProduct = { ...product, categorySummary: summary }
        setProduct(newProduct)
        setLocalStorageObject(EditProductLocalStorageAddress, newProduct, productId)
        onChange(summary)
    }

    return (
        <BottomSheet
            isOpen={props.isOpen}
            withHeader={true}
            title="Category"
            onBackClick={() => handleSubmit()}
            onOpenChange={() => handleSubmit()}
            mode="fullpage"
        >
            <div tw="p-4">
                <FormSection label="Group" labelSize="normal" tw="mb-4">
                    <CategoryRadioGroup
                        identifier="productGroup"
                        value={productGroups.find(productGroup=>productGroup.id==product.selectedGroupId)?.label}
                        options={productGroups?.map(pc => pc.label)}
                        onChange={(val) => { 
                            // console.log(`set new group to "${val}"`)
                            setProduct({ ...product, group: val, selectedGroupId: productGroups.filter(pg => pg.label === val)[0].id, category: "", categoryId: "", subCategories: [], subCategoryIds: [] }); handleChangeGroup(val) 
                        }}
                        isLoading={loadings.groupPick}
                    />
                </FormSection>

                <FormSection label="Category" labelSize="normal" tw="mb-4">
                    <CategoryRadioGroup
                        identifier="productCategory"
                        value={productCategories.find(productCategory=>productCategory.id==product.selectedCategoryId)?.label}
                        options={productCategories?.map(pc => pc.label)}
                        onChange={(val) => { 
                            // console.log(`set new category to "${val}"`)
                            setProduct({ ...product, category: val, selectedCategoryId: productCategories.filter(pc => pc.label === val)[0].id, subCategories: [], subCategoryIds: [] }); handleChangeCategory(val) 
                        }}
                        expand={!!product.selectedGroupId}
                        isLoading={loadings.categoryPick}
                    />
                </FormSection>

                <FormSection label="Subcategories" labelSize="normal" tw="mb-3">
                    <SubcategoryPicker
                        values={productSubcategories.filter(productSubCategory=>product.selectedSubCategoryIds?.includes(productSubCategory.id)).map(c=>c.label)}
                        options={productSubcategories?.map(pc => pc.label)}
                        onChange={(val) => {
                            // console.log(`set new subcategories to "${val}"`)
                            setProduct({ ...product, subCategories: val, selectedSubCategoryIds: productSubcategories.filter(ps => val.includes(ps.label)).map(ps => ps.id) })
                        }}
                        expand={!!product.selectedSubCategoryIds}
                        isLoading={loadings.subcategoryPick}
                    />
                </FormSection>

                <FloatingFooter tw="flex gap-3">
                    <Button onClick={handleSubmit} isDisabled={product.subCategories.length == 0}>Save</Button>
                </FloatingFooter>
            </div>
        </BottomSheet>
    )
}

export default CategorySheet