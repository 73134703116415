import React from 'react';
import { NOOP } from '../../constants';
import ChevronLeft from '../../icons/chevron-left.svg';
import BurgerMenu from '../../icons/burger-menu.svg'
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';

export type HeaderProps = {
  title?: string;
  onGoBack?: () => void;
  bgTransparent?: boolean;
  hasBurger?: boolean;
  onBurgerClicked?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

function Header(props: HeaderProps) {
  const navigate = useNavigate()

  const { title, onGoBack = () => navigate(-1), bgTransparent, ...rest } = props;

  return (
    <header
      tw="fixed top-0 z-10 max-w-mvw w-full left-1/2 -translate-x-1/2 text-xl h-12 flex items-center font-bold px-4"
      css={!bgTransparent && tw`bg-white`}
      {...rest}>
      {props.hasBurger ?
        <button onClick={props.onBurgerClicked}>
          <BurgerMenu tw="mr-3" />
        </button>
        :
        <button onClick={onGoBack}>
          <ChevronLeft tw="mr-4 w-2" />
        </button>
      }
      <p tw="flex">{title}</p>
      {props.children}
    </header>
  );
}

export default Header;
