import React, { Fragment, useEffect, useState } from 'react';
import Page from '../../../../common/components/page/page';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../common/components/header/header';
import FloatingFooter from '../../../../common/components/floating-footer/floating-footer';
import Button from '../../../../common/components/button/button';
import { getLocalStorageObject, setLocalStorageObject } from '../../../../framework/utils';
import { EditProductLocalStorageAddress } from '../../constants';
import { getMinMaxIntFromArray } from '../../../../common/utils';
import TextInput from '../../../../common/components/text-input/text-input';

function EditStockPage() {
    const navigate = useNavigate()
    const { id } = useParams()
    const productFormData = getLocalStorageObject(EditProductLocalStorageAddress, id)

    const [product, setProduct] = useState({
        stocks: {},
        ...productFormData
    })

    const [stocks, setStocks] = useState(product.stocks)

    useEffect(() => {
        // Provide blank stocks first from product.variants
        //@ts-ignore
        const variants: string[] = Object.entries(product.variants).map(([key, value]) => (value))
        //console.log("variants", variants)
        /* algorithm #1
        const depth = variants.length
        const lengths = variants.map((value: string[]) => value.length)
        const coordinates = variants.map(val => 0)
        //console.log("depth", depth, "lengths", lengths)
        let stocksTemplate = []
        let y = depth - 1
        while (arraysAreIdentical(lengths, coordinates)) {
            let distinctVariants = []
            for (let i = 0; i < depth; i++) {

            }
        }
        */
        // algorithm #2
        let stocksTemplate: string[] = []
        for (let i = 0; i < variants[0].length; i++) {
            stocksTemplate.push(variants[0][i])
        }
        for (let i = 1; i < variants.length; i++) { //dive through variant types
            let currLength = variants[i].length
            let tempStocksTemplate = stocksTemplate
            stocksTemplate = []
            for (let j = 0; j < currLength; j++) { //dive through variants
                for (let k = 0; k < tempStocksTemplate.length; k++) {
                    stocksTemplate.push(tempStocksTemplate[k] + "|" + variants[i][j])
                }
            }
        }
        //console.log("stocksTemplate", stocksTemplate)

        let stocksTemplateObj = {}
        for (const stockTemplate of stocksTemplate) {
            stocksTemplateObj[stockTemplate] = ""
        }

        // Then add existing
        setStocks({ ...stocksTemplateObj, ...stocks })
    }, [])

    // useEffect(() => {
    //     console.log("stocks", stocks)
    // }, [stocks])

    function handleSave() {
        let stockCounts = Object.entries(stocks).map(([key, value]) => value)
        let [min, max] = getMinMaxIntFromArray(stockCounts)
        let newProduct = { ...product, stocks: stocks, stockSummary: min !== Infinity ? (min === max ? min : `${min}-${max}`) : "" }
        setLocalStorageObject(EditProductLocalStorageAddress, newProduct, id)
        navigate(-1)
    }

    return (
        <Page tw="pt-[3.75rem] pb-20 bg-[#F2F2F2]">
            <Header title='Stock' onGoBack={() => navigate(-1)} />

            <div tw="grid grid-cols-2 items-center mb-6 p-4 bg-white">
                <b>Variant</b>
                <b>Stock</b>
                {Object.entries(stocks).map(([key, value]) => (
                    <Fragment key={key}>
                        <span tw="text-sm font-semibold">{key.replace(/\|/g, ", ").replace(/_/g, " ")}</span>
                        <TextInput
                            type="number"
                            value={stocks[key]}
                            onChange={(val) => {
                                //console.log("val", val)
                                let newStocks = { ...stocks }
                                newStocks[key] = val
                                //console.log("newStocks", newStocks)
                                setStocks(newStocks)
                            }}
                        />
                    </Fragment>
                ))}
            </div>

            <FloatingFooter>
                <Button onClick={() => handleSave()}>Save</Button>
            </FloatingFooter>
        </Page>
    )
}

export default EditStockPage