import { CommissionType } from '../../common/constants';
import { FetchProductDetailsResponse } from './api';
import { ProductFormFields } from './components/forms/product-form';

export function convertProductDetailsToProductFormDefaultFields(
  details: FetchProductDetailsResponse,
): ProductFormFields | void {
  return {
    brand: {
      id: details.brand.id,
      name: details.brand.name,
    },
    commission: {
      type: details.singleCommission ? 'SINGLE' : 'BULK',
      value: details.singleCommission || details.bulkCommission,
    },
    description: details.description,
    id: details.productId,
    images: details.imageUrls.map(url => ({
      url,
      state: 'idle',
    })),
    price: details.price,
    discount: details.discount,
    weight: details.weight,
    name: details.name,
    is21Plus: details.is21Plus,
    isHalal: details.isHalal,
    measurements: details.measurements,
    tags: details.tags.map(tag => ({
      maxSelection: tag.maxSelection,
      items: tag.items,
      isOptional: tag.isOptional,
      label: tag.label,
    })),
    fabric: {
      name: details.fabricMaterial?.name,
      details: details.fabricMaterial?.details.map(detail => ({
        label: detail.label,
        items: detail.items,
      })),
    },
  };
}

export function formatCurrency(x: number) {
  if (isNaN(x))
    return 0

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export type ProductTagItems = {
  id: string;
  label: string;
  isSelected?: boolean;
}

export type ProductTag = {
  isOptional: boolean;
  maxSelection: number;
  label: string;
  items: ProductTagItems[];
}

export type FabricMaterial = {
  name: string;
  details: {
    label: string;
    values?: string[];
    items: {
      label: string;
      isSelected: boolean;
    }[]
  }[];
}

export function firstOrNothing(arr: any[]) {
  let output = undefined
  try {
    output = arr[0]
  } catch (e) {}
  return output
}