import React, { useEffect, useState } from 'react'
import FormSection from '../../components/product-form/components/form-section'
import { Link, useNavigate } from 'react-router-dom'
import { getMeasurementValues, splitMeasurementKeys } from '../utils/edit-product-utils';

type MeasurementFieldProps = {
    measurements: {};
    onEdit: () => void;
}

function MeasurementField(props: MeasurementFieldProps) {

    const { measurements, onEdit = () => { } } = props

    const [sizes, setSizes] = useState<string[]>([])
    const [measurementItems, setMeasurementItems] = useState<string[]>([])
    const [values, setValues] = useState<string[][]>([])

    useEffect(() => {
        let [sizes, measurementItems] = splitMeasurementKeys(measurements)
        setSizes(sizes)
        setMeasurementItems(measurementItems)
        setValues(getMeasurementValues(measurements, sizes, measurementItems))
        console.log(getMeasurementValues(measurements, sizes, measurementItems))
    }, [])

    return (
        <div>
            {/* <div tw='grid gap-x-10 gap-y-0' style={{ gridTemplateColumns: `repeat(${measurementItems.length + 2}, minmax(0, 1fr));` }}> */}
            {
                sizes.length > 0 &&
                <div tw='overflow-scroll mr-[-1rem] mb-1'>
                    <table tw='text-left text-xs'>
                        <tr>
                            <th tw='mb-1 '>Size&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th tw='' style={{ borderLeft: "1px solid #E4E4E4" }}>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            {
                                measurementItems.map(measurementItem => (
                                    <th tw='mb-1 '>{measurementItem}&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                ))
                            }
                        </tr>
                        {
                            //content
                            sizes.map((size, i) => (
                                <tr>
                                    <td tw='mb-1 '>{size}&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td tw='' style={{ borderLeft: "1px solid #E4E4E4" }}>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    {
                                        values[i].map(col => (
                                            <td tw=' whitespace-nowrap'><span>{col || "-"}</span>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </table>
                </div>
            }
            <b onClick={() => onEdit()} tw='text-[#364DFF] text-sm'>Edit</b>
        </div>
    )
}

export default MeasurementField